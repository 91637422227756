.bkin{
    width: 93px;
height: 28px;
left: 100px;
top: 182px;
border: none;
background: #50B14A;
border-radius: 2px;
color: #FFFFFF;
margin-left: 100px;
margin-bottom: 20px;
}
.clcikmsg{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    
   
}
.clcikmsgleft{
    width: 70%;
  
   
}                            
.clcikmsgright{
    width: 30%;
  
   
}
.clcikmsgleft1{

    height: 106px;
    left: 100px;
    top: 230px;
display: flex;
    background: #F9F9F9;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
}
.clickrleft11{
    border-radius: 4px;
  
    width: 130px;
    height: 80px;
    left: 1003px;
    top: 246px;
    padding: 16px 10px 39px 16px;
  

}
.clickleft12{
   
    Font:"Roboto";
    font-weight:"500";
   font-style :"normal";
    Size :8px;
    font-size: 12px;
    line-height:  25px;
    padding: 16px 10px 39px 16px;

}

.clcikmsgleft2{
    height: 285px;
    left: 100px;
    top: 335px;
    display: flex;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
}
.clcikmsgleftpicture{
    width: 60px;
    height: 60px;
    left: 129px;
    top: 376px;
    border-radius: 40px;
    padding-top: 41px;
    padding-left: 29px;
}
.clcikmsglefttop{
    width: 77px;
height: 16px;
padding-top: 25px;
font-size: 10px;
color: #000000;

}
.clcikmsglefttext{
    width: 622px;
height: 235px;
margin-left: 80px;
margin-top: 41px;
font-family: Roboto;
font-style: normal;
font-size: 13px;
line-height: 18px;
color: #000000;
}
.clcikmsglefttext h6{
    color: #000000;
   font-weight: 700;
   font-size: 12px;
   padding-bottom: 20px;
    
}

.clcikmsgleft3{
height: 253px;
left: 100px;
top: 619px;
background: #FFFFFF;
border: 1px solid #C4C4C4;
box-sizing: border-box;
}
.clicktextarea{
    width: 740px;
    height: 145px;
    left: 129px;
    top: 638px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 19px;
    margin-left: 29px;

}
.left3bt{
 position: absolute;
width: 132px;
height: 31px;
margin-left: 30px;
top: 800px;
background: #50B14A;
border-radius: 4px;
margin-top: 19px;
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
color: #FFFFFF;
border: none;
cursor: pointer;
}
.clcikmsgright1{
    display: flex;
    height: 135px;
    left: 987px;
    top: 230px;
    background: #F9F9F9;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
   
}

.clickright11{
    width: 40%;
    width: 80px;
    height: 80px;
    left: 1003px;
    top: 246px;
    padding: 16px 10px 39px 16px;
  
}

.clickright12{
    width: 60%;
    font-weight:"500";
   font-style :"normal";
    Size :8px;
    font-size: 12px;
    line-height:  25px;
    padding: 16px 10px 39px 16px;


    
}


    .clcikmsgright2{
    height: 58px;
    left: 987px;
    top: 364px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
     box-sizing: border-box;

     /* height: 28px;
    left: 1124px;
     top: 379px; */

font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;
padding-top: 12px;
text-align: center;

color: #000000;
    }
    .clcikmsgright3{
    height: 106px;
    left: 987px;
    top: 421px;
    background: #F9F9F9;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;
    padding-top: 10px;
    padding-left: 20px;
    color: #2E2E2E;
    }
    .clickrightbtn{
        background-color: #50B14A;
        width: 100px;
        height: 28px;
        left: 1126px;
        top: 489px;
        border: 1px solid #50B14A;
       box-sizing: border-box;
       border-radius: 2px;
       margin-left: 100px;
       color:#FFFFFF;
       font-size: 10px;
       margin-top: 15px;
       cursor: pointer;
  
    }