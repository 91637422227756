@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import 'bootstrap/dist/css/bootstrap.css';
@import 'flag-icon-css/css/flag-icons.min.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
a{
    text-decoration: none;
}
body{
  padding: 0 !important;
}
.ulDecimal{
  list-style-type: decimal;
  padding-left:2rem !important;
}
:root{
  overflow-x: hidden;
  --main:#50B14A;
  --lighty:#73AF46;
  --lightMain: rgb(236, 252, 242);
  --lighter: #ffffff;
  --textColor: #444343;

  --lightestBg: #E0FCE7;
  --lighest: #E0FCE7;
  /* --lighest: rgb(236, 252, 242); */
  --msgBg: rgb(243, 252, 246);
  --msgBorderline:rgb(187, 216, 197);
  --test:rgb(211, 236, 219);
  --mline:rgb(169, 253, 199);
  
  --white: #ffffff;
  --darkest: #292929;
  --darker: #999999;
  --dark: #545454;

  --orange: #FFAF00;
  --heroBG: #FAFAFA;

  --brown: #B77E02;
  --blue: #004DC1;

  
  /* --dark: #81D1FF; */

  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
p{
  margin: 0;
  padding: 0;
}
div::-webkit-scrollbar{
  display: none;
}
div{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}

body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  }
.swal2-container{
  z-index: 2060!important;
}
.MuiInputBase-input{
  padding: 8.5px 14px !important;
}

@media (min-width:600px)
{
  .css-hyum1k-MuiToolbar-root{
    min-height:60px !important ;
  }
  .css-i6s8oy{
    min-height:60px !important ;
  }
}