.msg-trow-{
    border-top: 1px solid #E7E7E7; 
    transform: matrix(1, 0, 0, 1, 0, 0);
    border-bottom: 1px solid #E7E7E7;
    /* background-color: #50B14A; */
    position: relative;
    z-index: -1;
}

.msg-table > thead{
    background-color: #ffffff !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #484848;
    border-bottom: 2px solid #E7E7E7;
}

.tagcopy{
    cursor: pointer;
}