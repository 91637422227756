.transCard{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    box-shadow: 0 10px 6px -6px rgb(224, 224, 224);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 20px;

}
.trans{
    display: flex;
    width: 100%;
    padding: 10px 20px;
    justify-content: space-between;
}
.trans span{
    text-align: left;
    font-size: 13px;
    font-weight: 600;
}
.trans .spanItem{
    text-align: right;
    font-size: 14px;
    font-weight: 500;
}