.cont_items_status{
  padding: 20px 100px;
}
.items_status{
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 0.5px solid #dbdbdb;
  width: 100%;
  max-width: 800px;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  cursor: pointer;
  background-color: white;
}
.items_status:hover{
  background-color: rgb(251, 251, 251);
}
.items_status_image{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.items_status_imagev2{
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.each_status{
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  max-width: 500px;
}
.hi_avatar{
  position: absolute;
  right: 0;
}
.hi_image{
  width: 50px;
  border-radius: 15px;
  border-bottom-left-radius: 0;
}
.hi_image_container{
  cursor: pointer;
  user-select: none;
  position: relative;
  right: -15px;
  display: flex;
  flex-direction: column;
  /* gap: 4px; */
  color: #004DC1;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
}
.mesgeCard{
  position: absolute;
  top: 50px;
  right: 0;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 4px;
  padding: 15px;
  color: #484848;
  width: 200px;
  font-size: 12;
  z-index: 10;
}
.span_color{
  color: #004DC1;
  cursor: pointer;
  user-select: none;
}

.each_line{
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.each_circle, .each_circle_pending {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: linear-gradient(88.15deg, #32A071 -13.41%, #15CF74 67.33%, #2DA771 114.58%);
}
.each_circle_pending{
  background: #E7E7E7;
}

.each_line_thin_complete{
  width: 3px;
  height: 75px;
  background: linear-gradient(88.15deg, #32A071 -13.41%, #15CF74 67.33%, #2DA771 114.58%);
}

.each_line_thin{
  width: 3px;
  height: 75px;
  background: #E7E7E7;
}

.each_line_thin_pending{
  width: 3px;
  height: 40px;
  background: #E7E7E7;
}
.each_line_info{
  display: flex;
  flex-direction: column;
  gap: 7px;
  
}
.each_line_info h3{
  font-weight: 500;
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: #484848;
}
.each_line_info span{
  font-weight: 300;
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: #484848;
}
.each_line_info h5{
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: #484848;
}

.each_line_info_active{
  display: flex;
  flex-direction: column;
  gap: 7px;
  cursor: pointer;
  user-select: none;
  padding-right: 80px;
  
}
.each_line_info_active h3{
  font-weight: 500;
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: #004DC1;
}
.each_line_info_active span{
  font-weight: 300;
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: #004DC1;
}
.each_line_info_active h5{
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: #004DC1;
  
}
.cont_welc{
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 10px 12px 30px 12px;
}
.cont_welc span{
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: #484848;
  line-height: 28px;
  
}
.cont_welc button{
  outline: 0;
  border: 0;
  background-color: var(--main);
  color: white;
  padding: 7px 12px;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
}

@media screen and (max-width : 900px) {
  .cont_items_status{
    padding: 0;
  }
  .hi_image_container{
    font-size: 10px;
  }
}