.main-flex {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  /* gap: 40px; */
}

.main-flex-info {
  margin-bottom: 20px;
  padding: 10px 10px 20px 10px;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  border-bottom: 1px solid #c7e6c5;
}

.image-div {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: auto; */
  /* max-height: 200px; */
  height: 120px;
    /* width: 100%; */
    width: 120px;


  /* display: flex;
    justify-content: center;
    align-items: center;
    height: 120px; */
    /* width: 100%; */
    /* width: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
} 


.item-image {
  width: 50%;
  height: auto;
  max-height: 180px;
}

.track--{
  display: flex;
  justify-content: center;
}

.track-btn{
  border: 1px solid #50B14A;
    box-sizing: border-box;
    border-radius: 4px;
    background: #50B14A;
    color: #FFFFFF;
    padding: 5px 10px;
    cursor: pointer;
}


@media only screen and (max-width: 900px) {

.main-flex{
  position: relative;
  z-index: -2;
}

}