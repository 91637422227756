.apibody{
    background: #fff;
    font-family: "Roboto";
    z-index: 10;
    font-weight: 300px;
}
.mobilog{
    display: none;
}
.hdmob{
    display: none;
}
 .apibox{
    width: 100%;
    display:flex;
    padding: 0px 100px;
    font-family: "Roboto";
    margin-bottom: 0px;
    font-weight: 300px;
    position: relative;
}
.apisubbox1{
width: 25;
    padding: 15px;
    position: relative;
    margin-top: 20px;
}
.apisubbox2{
    flex: 75%;
    position: relative;
}
.subbox1{
    display: flex;
    height: 100px;
    position:fixed;
    margin-top: 10px;
}

.apilogo2{
    display: flex;
    text-align: right;
    width: 30;
}
.subbox2{
    position:sticky;
    top:100px;
    margin-top: 90px;
}
.subbox2 h4{
    margin-bottom: 30px;
    text-align: left;
   
}
.apiend{
   display: flex;
}
.apiend1{
   
}
.apiend2 {
margin-top: 10px;
padding-left:10px;
}
.apiend2 hr{
    width: 50px;
}


.mainsub1{
    display: flex;
    height: 100px;
    position:fixed;
    justify-content: space-between;
    z-index: 300;
    background-color: #F4FFF3;
    padding-left: 100px;

   
}
.insidemain1{
    display: flex;
    width: 700px;
    background-color: #F4FFF3;
}
.insidemain2{
    display: flex;
    width: 400px;
    justify-content: space-between;
    gap: 10px;
    background-color: #F4FFF3;
    margin-top: 20px;
    padding-left: 100px;
    
}

.limApiTblWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border: 1px solid #EAEAEA;

}
.limApiTblHead{
  background-color:#D9D9D9; 
    padding:7px 10px;
    border: 1px solid #EAEAEA;

}
.limApiTblBody{
    padding:7px 10px;
    border-top: none;
}
.limApiTblBod{
    padding:7px 10px;
    border: 1px solid #EAEAEA;
    border-top: none;
}

.mainsub2{
    display: flex;
    position: relative;
    top:50px;
    text-align: left;
    justify-content: space-between;
    margin-top: 60px;
    padding-left: 100px;
    font-weight: 300px;
}
.insidemainsub2{
   flex:2;
}
.insidemainsub21{
   flex:2;


}
.apiSection{
    text-align: justify;
    font-weight: 300px;
}
.apiSect{
    text-align: justify;
    font-weight: 300px;
    width: 100%;
}

.apiSection .MuiToolbar-root{
 display: none;
}
.apiSection h4{
    margin: 10px 0;
    font-size:25px
}
.apiSect h4{
    margin: 10px 0;
    font-size:25px
}
.apiSection p{
    margin: 5px 0;
    line-height: 20px;
}

.apiSect p{
    margin: 5px 0;
    line-height: 20px;
}

.apiSection2{
    text-align: justify;
}
.apiSection2 h4{
    margin: 20px 0;
}
.apiSection2 p{
    margin: 5px 0;
    line-height: 30px;
    
}
.apiSection3{
    text-align: justify;
}
.apiSection3 h4{
    margin: 20px 0;
}
.apiSection3 p{
    margin: 5px 0;
    line-height: 30px;
    
}
.apiSection4{
    text-align: justify;
}
.apiSection4 h4{
    margin: 20px 0;
}
.apiSection4 p{
    margin: 5px 0;
    line-height: 30px;
    
}
.apiSection5{
    text-align: justify;
}
.apiSection5 h4{
    margin: 20px 0;
}
.apiSection5 p{
    margin: 5px 0;
    line-height: 30px;
    
}
.test-class{
    margin: 20px 0;
    width:100%;
}
.insidemain2button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    text-align: center;
    font-size: 15px;
    width: 100px;
    border-radius: 50px;
    height: 30px;
    cursor: pointer;
    height: 30px;
  
   
}
.insidemain2button :hover{
    color: gray;

}
table {
    border-collapse: collapse;
    width: 100%;
    text-align: justify;
    font-family: "Roboto";

  }
  
  th, td {
    text-align: left;
    padding: 8px;
  }
  
  
  th {
    background-color: rgb(234, 241, 235);
    color: black;
  }
  .apisectionbox{
      display: flex;
      width: 100%;
      justify-content: space-between;
  }

  .apisectionbox2{
    display: none;
    flex-direction: column;
    align-items: flex-end;
   
  }

  .lightBlue {
      color: #8aaef8;
  }
  .practise{
    z-index: 1;
   height: 200px;
    padding:0;
    width: 400px;
    overflow: scroll;
    font-family:"Roboto";
    font-size: 15px;
    border-radius: 6px;
    position: relative;
    overflow-x: auto;
    box-sizing: border-box;
    box-shadow: rgb(0 0 0 / 3%) 0px 1px 1px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .practise p{
      padding: 10px 12px;
  }
  .scrollHead{
    width: 100%;
    padding: 10px;
    position: sticky;
    background-color: #282c34;
    z-index: 2;
    top: 0;
    color: rgba(246, 252, 255, 0.6);
}
.pob{
    border-radius: 8px;
    width: 50px;
    height: 20px;
    background-color: rgb(228, 246, 231);
    color: rgb(23, 117, 50);
    border: none;
}
.prostyle{
    background-color:#282c34;
    color: rgba(246, 252, 255, 0.6);
    border:none;
    cursor: pointer;
}

  .practiseinside{
     background-color: white;
 position:fixed;
 z-index: 3;
    overflow: hidden;
  }
  .practise p{
   color:#81c1bd;
  }
  .textcolor{
      color:#b0da76;
  }
  .numbercolor{
      color: #de627a;
  }
  .comancolor{
      color: #8aaef8 ;
      
  }
  .prachead{
    display:block;
    height: 50px;
     padding-left: 12px;
     padding-right: 12px;
     width: 400px;
     padding: 10px;
     border:ridge 5px gray;
     margin: 0;
     background:red;
  }
 .basic{
     background-color: rgb(234, 241, 235);
     text-align: justify;
 }

  .drop{
    min-width: 100px;
    padding: 0px 16px;
    z-index: 1;
    position: relative;
    display: inline-block;
    color: black;  
    text-decoration: none;  
    margin-bottom: 10px; 
    margin-top: -25px; 
    line-height: 25px;
    }   
      .pointer {cursor: pointer;}
      .pointer :hover{
        color:green;
      }

      .menuliss{
        padding:  110px 100px 0 100px;
        padding-right: 0;
        
      }
      @media screen and (max-width:900px) 
      
      { 
        .drop{
            min-width: 100px;
            padding-left: 70px;
            z-index: 1;
            position: relative;
            display: inline-block;
            color: black;  
            text-decoration: none;  
            margin-bottom: 10px; 
            margin-top: -10px; 
            line-height: 25px;
            }   
              .pointer {cursor: pointer;}
              .pointer :hover{
            color:green;
              }
        .menuliss{
            padding:  0
          }
          .apibody{
              width: 100%;
              padding: 0;
          }
          .apibox{
              display: flex;
              flex-direction: column;
              width: 100%;
              padding: 0px 2px;
          }
       
         .table {
            border-collapse: collapse;
            width:50%;
            text-align: justify;
            font-family: "Roboto";
         }
         .apisubbox1{
             width:20px;
             display:none;
         }
         .apisubbox{
             width: 50px;
         }
         .insidemain2button{
             width: 10px;
         }
   

      }

      .containbox{
      width: 100px;
      height: 100px;
          background-color: red;
      }
      .containbox1{
          background-color: blue;
       

      }
      .containbox2{
        background-color:yellow;
        height: 80px;
    }
      @media screen and (max-width: 900px) {
        .subbox2 {
        display: none;
        }
        .subbox1{
            display: block;
        }
        .insidemain2{
            display: none;
        }
        div.content {margin-left: 0;}
        
        .apisubbox2{
            flex-direction: column;
           
        }
        .mainsub1{
            flex-direction: column;
            height: 70px;
            background-color: red;
    margin-top: 0px;
    width: 800%;

        }
        .apisectionbox{
            width: 100%;
            flex-direction: column;

        }
        .apiSect{
            padding: 40px 20px;
            width:100%;
        }
        .apiSection{
            padding: 0 20px;
            width:100%;
          
           
           
          
        }
        .apiSection h4{
            width: 100%;
            margin: 0px ;
        }
        .apiSect h4{
            width: 100%;
            margin: 0px ;
        }
     
     
        .insidemain1{
         
            width: 10%;
     
        }
        .mainsub2{
            display: flex;
            /* border-style:solid; */
            position: relative;
            text-align: left;
            justify-content: space-between;
            margin-top: 0px;
            padding-left: 0px;

        }
        table {
            /* border-collapse: collapse; */
            width: 100%;
            
        
          }
          
          th, td {
            text-align: left;
            padding: 2px;
          }
          .practise{
            z-index: 1;
           height: 200px;
            padding: 10px 12px;
            /* padding-right: 12px; */
            width: 300px;
            /* padding: 10px; #1b3148*/
            /* border-left:solid 6px  #50b14a; */
            /* margin: 0; */
            overflow: scroll;
            background:#151616;
            font-family:"Roboto";
            font-size: 15px;
            border-radius: 6px;
            margin-top: 30px;
            margin-bottom: 30px;
          /* color:green; */
        
          position: relative;
          
          padding: 24px 16px;
         
          overflow-x: auto;
          box-sizing: border-box;
          box-shadow: rgb(0 0 0 / 3%) 0px 1px 1px;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
        
        
          }
          
          .apisubbox1{
         
                padding: opx;
                position: relative;
            }
            .scrollHead{
                width: 100%;
                padding: 0px;
            
            }
            .hdmob{
                flex:1;
                display:block;
                height: 50px;
                margin-bottom: -20px;
            
            }
            .mobilog{
                flex:1;
                text-align:center;
                display:block;
                padding-right: 150px;
                position:sticky;
                 top:0; 
                z-index:10; 
                background-color:"#F4FFF3";
                margin-bottom: -20px;
                
              
                
            }
            .sidelo{
                display: flex;
                padding-top: 20px;

                
                
            }

        
    }