.dayss{
  width: 350px;
  
}
.dayHead{
  background-color: #EAEAEA;
  padding:  5px 10px;
  display: flex;
  justify-content: space-between ;
}
.dayBody{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border: 1px solid #EAEAEA;
}
.colorBox{
  width: 20px;
  height: 20px;
}
.recharts-default-legend{
  display: none;
}
@media  screen and (max-width: 900px) {
  .dayss{
  width: 100%;}
}