*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
}
.content{
	flex: 1;
}
.sublogo{
	display: none;
	position: absolute;
}
.sublogo img{
	max-width: 150px;
	height: auto;
}

.wrapper{
  position: absolute;
  
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
}
.wrapper nav{
  position: relative;
  display: flex;
  z-index: 400;
  width: 100%;
  height: 80px;
  align-items: center;
}
nav .content{
  display: flex;
  padding: 0 100px;
}
nav .content .links{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content .links li{
  list-style: none;
  
}
.content .topLinks{
display: flex;
}
.content .links li a,
.content .links li label{
  color: #000;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.content .links li label{
  display: none;
}
.content .links li a:hover,
.content .links li label:hover{
  color: var(--main);
}
.content .links li .signup{
	background-color:var(--main);
	color: white;
	padding: 9px 15px;
  }
.content .links li .signup:hover{
	color: white;
	background: #73AF46;
  }

.wrapper .menu-icon{
  color: #000;
  font-size: 18px;
  cursor: pointer;
  line-height: 70px;
  width: 70px;
  text-align: center;
  padding: 0 30px;
}
.wrapper .menu-icon{
  display: none;
}
.content .logo{
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	
}
.links{
	flex: 6
	;
}
.toplinks{
	flex: 1;
	background-color: burlywood;
}
.content1{
  width: 100%;
  padding: 20px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content1 .logo img{
  width: 150px;
  height: auto;
 }
.logoutBtn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 7px 15px;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  font-weight: 500;
  background-color: var(--msgBorderline);
  color: var(--main);
}
.content .logo img{
 width: 150px;
 height: auto;
}
.search-box .go-icon{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 60px;
  width: 70px;
  background: #171c24;
  border: none;
  outline: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.wrapper input[type="checkbox"]{
  display: none;
}
 
.content .links ul{
  position: absolute;
  background: whitesmoke;
  top: 80px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.gapRight{
	margin-right: 200px;
}
.content .links li:hover > ul{
  top: 60px;
  opacity: 1;
  visibility: visible;
  transition: all 0.1s ease;
}
.content .links ul li a{
  display: block;
  width: 100%;
  line-height: 30px;
  border-radius: 0px!important;
}
.content .links ul ul{
  position: absolute;
  top: 0;
  right: calc(-100% + 8px);
}
.content .links ul li{
  position: relative;
}
.content .links ul li:hover ul{
  top: 0;
}
 
@media screen and (max-width: 1250px){
  .wrapper nav{
    max-width: 100%;
    padding: 0 20px;
  }
  nav .content .links{
    margin-left: 30px;
  }
  .content .links li a{
    padding: 8px 13px;
  }
}

@media screen and (max-width: 1320px){
  .wrapper .menu-icon{
    display: block;
  padding: 30px;
  }

  
  .content .logo{
    width: 100%;
}
  nav .content .links{
    display: block;
    position: fixed;
    background: var(--lighest);
    height: 100%;
    width: 100%;
    top: 0;
    left: -100%;
    margin-left: 0;
    max-width: 400px;
    overflow-y: auto;
    padding-bottom: 30px;
    transition: all 0.3s ease;
  }
  nav #show-menu:checked ~ .content .links{
    left: 0%;
  }
  nav #close-menu:checked ~ .content .links{
    left: 0%;
  }
  .content .links li{
    margin: 15px 20px;
  }
  .content .links li a,
  .content .links li label{
    line-height: 40px;
    font-size: 20px;
    display: block;
    cursor: pointer;
  }
  .content .links li a.desktop-link{
    display: none;
  }
 
  .content .links ul,
  .content .links ul ul{
    position: static;
    opacity: 1;
    visibility: visible;
    background: none;
    max-height: 0px;
    overflow: hidden;
  }
  .content .links #show-features:checked ~ ul,
  .content .links #show-services:checked ~ ul,
  .content .links #show-company:checked ~ ul,
  .content .links #show-items:checked ~ ul{
    max-height: 100vh;
  }
  .content .links ul li{
    margin: 7px 20px;
  }
  .content .links ul li a{
    font-size: 18px;
    line-height: 30px;
    border-radius: 5px!important;
  }
}

 
@media screen and (max-width: 1024px){
  .wrapper nav{
    padding: 0 10px;
  }
  
  .content .links li a.desktop-link{
    display: none;
  }
}
