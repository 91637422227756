.new_flex{
  display: none;
}
@media (min-width: 901px) {
  .mbile- {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .dsktop- {
    display: none;
  }
  .viewSearch .view_Container {
    padding: 20px;
    /* margin: 0 auto;
    max-width: 70%; */
  }
  .viewSearch .view_Top_mobile {
    display: block;
  }
  .viewSearch .fulfillerViewTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    background-color: none;
    box-shadow: 0 10px 10px -6px #777;
  }

  .topItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    /* flex-direction: column; */
  }

  .topItem-second-div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .topItem-first-div-btn {
    width: 40px;
    height: 25px;
    background: #50b14a;
    border-radius: 2px;
    border: #50b14a;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .topItem-first-div-btn > span {
    font-family: "Roboto", sans-serif;
    width: 40px;
    height: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }

  .topItem-first-div-category {
    width: 122px;
    height: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }

  .topItem-first-div-item-category {
    width: 122px;
    height: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }

  .topItem-second-div-Id {
    height: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-right: 10 px;
  }

  .topItem-second-div-item-category {
    height: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-right: 10px;
  }

  .viewfulfiler-btn {
    height: 28px !important;
    padding-top: 5px;
    margin-right: 5px;
  }



  .topItem-second-div-report {
    width: 40px;
    height: 28px;
    background: #50b14a;
    border-radius: 2px;
    border: #50b14a;
    cursor: pointer;
  }

  .topItem-second-div-report > span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }

  .main-fulfiler-page {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 40px;
    /* margin-bottom: 300px; */
    flex-direction: column;
  }

  .imgage-content {
    flex: 3;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 310px;
    border: 1px solid #eaeaea;
  }

  .carousel_image- {
    max-height: 280px;
  }
  .dillivry-info {
    flex: 4;
    width: 100%;
    margin-bottom: 250px;
  }

  .submit-quote-content {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 0px 0px 4px 4px;
    height: 142px;
  }

  .quote-div {
    background: #50b14a;

    border-radius: 8px 8px 0px 0px;
    padding: 10px 50px 10px 10px;
  }

  .quote-div > h1 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    height: 16px;
    color: #ffffff;
  }

  .current-quote-body {
    background: #ffffff;
    border: 0.5px solid #e0e0e0;
    box-sizing: border-box;
  }

  .itemDescs-desc {
    background: #ffffff;
    box-sizing: border-box;
  }

  .itemDescs- {
    display: flex;
    border-bottom: 0.5px solid #e0e0e0;
    box-sizing: border-box;
    padding: 5px;
  }

  .icn-two-,
  .icn-one- {
    max-width: 200px;
    margin-right: 20px;
  }

  .icon-desc {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #2e2e2e;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icn3-,
  .icn4-,
  .icn5-,
  .icn6- {
    max-width: 200px;
    margin-right: 14px;
  }

  .quote-info {
    flex: 3;

    width: 100%;
    /* margin-top: 40px; */
  }

  .submit-quote-content {
    background: #ffffff;
    border: 1 px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 0px 0px 4px 4px;

    height: 142px;
  }

  .quote-div {
    background: #50b14a;

    border-radius: 8px 8px 0px 0px;
    padding: 10px 50px 10px 10px;
  }

  .quote-div > h1 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    height: 16px;
    color: #ffffff;
  }

  .current-quote-body {
    background: #ffffff;
    border: 0.5px solid #e0e0e0;
    box-sizing: border-box;
  }

  .quote-info-- {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #e0e0e0;
    padding: 8px;
  }

  .quote-amount {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-weight: normal;
    font-size: 12px;
    color: #2e2e2e;
  }

  .ques- {
    margin-right: 30px;
  }

  .Quote-Title-message {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
  }

  .enquiry-mssg {
    background: #50b14a;
    border: 1px solid #50b14a;
    box-sizing: border-box;
    border-radius: 2px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    width: 93px;
    height: 30px;
    margin-top: 10px;
    margin-right: 8px;
    cursor: pointer;
  }

  .enquiry-mssg > p {
    margin-top: 8px;
    margin-left: 8px;
    cursor: pointer;
  }

  .parent-submit-quote-enquiry-msg {
    display: flex;
  }

  .btn-quote-form {
    background: #50b14a;
    border: 1px solid #50b14a;
    box-sizing: border-box;
    border-radius: 2px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    width: 93px;
    height: 30px;
    margin-top: 10px;
    margin-right: 8px;
  }

  .parent-submit-quote-enquiry-msg {
    display: flex;
  }

  .provider-history-div {
    width: 100%;
    /* margin-top: 185px; */
    /* padding: 30px; */
  }

  .provider-history {
    padding: 0 0 0 10px !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    /* margin-bottom: 20px; */
  }

  .table_ > tr:nth-child(odd) {
    background: #fff;
    height: 48px;
  }

  .table-tr-th > tr > th {
    background: #eaeaea;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000;
  }

  .table---mb {
    background: #eaeaea;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }

  .table---mb > tr > td > button {
    background: #50b14a;
    border-radius: 4px;
    border: #50b14a;
    /* margin-right: 70
px
; */
    cursor: pointer;
    border-radius: 2px;
    width: 89px;
    height: 30px;
  }

  .table_ > tr:nth-child(2n) {
    background: #fff;
    border: 1px solid #fff;
    background: #f7fff7;
    box-sizing: border-box;
    height: 58px;
  }

  .table_ > tr:nth-child(odd) {
    background: #fff;
    height: 48px;
  }

  .table-tr-th > tr > td > button {
    /* width: 89px; */
    height: 30px;
    background: #50b14a;
    border-radius: 4px;
    border: #50b14a;
    /* margin-right: 70
px
; */
    cursor: pointer;
    border-radius: 2px;
  }

  .profile-btn > span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #fff;
    /* width: 89px;
  height: 30px; */
  }

  .a {
    height: 46px;
    background: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 50px;
  }

  .a2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #000;
    height: 28px;
  }

  .a3 > button {
    width: 89px;
    height: 31px;
    background: #50b14a;
    border-radius: 4px;
    border: #50b14a;
    cursor: pointer;
    margin-right: 28px;
  }

  .a3 > button > span,
  .feedback-logo {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
  }

  .a3 > button > span {
    font-weight: 400;
    color: #fff;
  }

  .logicstics-content {
    font-size: 14px;
  }

  .enquiry-submit {
    display: flex;
    justify-content: right;
    padding: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
}

@media screen and (max-width: 281px) {
  .provider-history-div {
    /* margin-top: 305px; */
    margin-top: 30px;
    padding: 0;
  }

  .table-tr-th > tr > th {
    padding: 0;
  }

}
@media screen and (max-width: 400px) {
  .topItem-second-div-item-category {
    display: none;
  }
  .topItem-first-div-item-category{
    display: none;
  }
  .new_flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 0 0;
    margin-bottom: -20px;
    font-size: 13px;
  }
}


@media screen and (width: 360px) {
  .provider-history-div {
    margin-top: 30px;

    padding: 0;
  }

  .table-tr-th > tr > th {
    padding: 0;
  }

 

  .quote-div {
    padding: 10px 50px 15px 10px;
  }
}

@media screen and (width: 640px) {
  .quote-div > h1 {
    font-size: 12px;
  }
}

@media screen and (width: 320px) {
  .provider-history-div {
    margin-top: 30px;

    padding: 0;
  }
 

  .quote-div > h1 {
    font-size: 12px;
  }
  .quote-div {
    padding: 10px 70px 15px 10px;
  }

  @media screen and (width: 280px) {
  }
}
