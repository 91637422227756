.email_copy{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    position: relative;
}

.copied{
    position: absolute;
    top: -5px;
    right: -40px;
    font-size: 14px;
    font-weight: 300;
}

@media screen and (max-width : 900px) {
    .email_copy{
        gap: 5px;
    }

    .copied{
        position: absolute;
        top: -5px;
        right: -30px;
        font-size: 10px;
        font-weight: 400;
    }
}