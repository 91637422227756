.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 350;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-inner {
  position: relative;
  width: 30%;
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  margin-top: 50px;
}
.XXheadingg {
  background-color: var(--main);
  padding-left: 30px;
  height: 40px;
  line-height: 40px;
  color: var(--lighter);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
  /* justify-content: space-between; */
  /*position: relative;*/

  /* margin-bottom: 15px; */
}
.XXacctTyp {
  font-style: normal;
  font-weight: 600;
  /*font-size: 16px;*/
  color: #ffffff;
  letter-spacing: 0.5px;
}
.clsBtn {
  margin-right: 20px;
  padding-top: 5px;
}
.headlings{
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
.headsss{
  margin-top: 20px;
}
@media screen and (max-width: 1024px) {
  .popup-inner {
    position: relative;
    width: 80%;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
  }
}
