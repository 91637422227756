.quoted_view_con {
  width: 100%;
  padding: 20px;
}
.qoute-nav {
  border-bottom: 1px solid #c7e5c5;
}
.delivery-wrapper {
  /* background-color: blue; */
  padding: 0 20px;
}
.topper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 20px 0;
}

.view_Mapp {
  width: 100%;
  height: 250px;
  background-color: #eaf4ea;
  border-radius: 10px;
  margin-bottom: 20px;
}

.item_detail {
  width: 100%;
  padding: 15px 0;
}

.item_imges_con {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.item_imges {
  width: 100%;
  height: 125px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #e5e5e5;
}

.item_hist {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 10px;
  padding-bottom: 30px;
}
.aaagent{
  font-weight: 500;
font-size: 13px;
}
.agentCard {
  width: 100%;
  display: flex;
  border: 1px solid #e5e5e5;
  padding: 10px;
  position: relative;
  border-radius: 5px;
  gap: 5px;
}
.agentCardV2_track{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.agentCardV2 {
  /* width: 100%; */
  display: flex;
  border: 1px solid #e5e5e5;
  padding: 10px;
  position: relative;
  border-radius: 5px;
  gap: 5px;
}
.agent_ima {
  width: 100px;
  height: 70px;
  border-radius: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.agent_info {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.agent_info span {
  font-size: 12px;
  font-weight: 600;
}

.item_inform {
  width: 100%;
}

.item_informm {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: space-between;
}
.viewBut {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
}
.topTitlee {
  width: 100%;
  background-color: #E0FCE7;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid #e5e5e5;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 600;
}
.itemDescs {
  width: 100%;
}
.itemDesc {
  padding: 7px;
  border: 1px solid #e5e5e5;
  /* border-top: 0; */
  display: flex;
  border-radius: 4px;
  gap: 15px;
  font-size: 12px;
}
.itemDesc2 {
  color: #444343;
  padding: 7px;
  padding-right: 0;
  display: flex;
  gap: 10px;
  font-size: 14px;
  justify-content: space-between;
  font-weight: 500;
}

.itemDesc_2 {
  width: 100%;
  color: #444343;
  border: 1px solid #e5e5e5;
  border-top: none;
  padding: 7px;
  display: flex;
  gap: 10px;
  font-size: 14px;
  justify-content: space-between;
  font-weight: 500;
}
.item-value {
  margin-left: 7px;
  color: blue;
}
.quote_data_con {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.quote_data {
  width: 70%;
  max-width: 350px;
}
.sender_data_con {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.sender_data {
  width: 100%;
  /* max-width: 350px; */
}
.tableCard {
  width: 100%;
}
.paginationHead {
  width: 100%;
  padding: 0 20px;
  border: solid 1px var(--main);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 5px;
}
.paginationHead span {
  font-weight: bold;
  font-size: 16px;
}
.quoteee {
  /* background-color: blue; */
  width: 100%;
}
.image-quote {
  /* background-color: blueviolet; */
  width: 100%;
}
.best-quote {
  width: 70%;
  /* height: 180px; */
  max-width: 350px;
  background-color: #f6f6f6;
  border: 1px solid #e7e7e7;
}
.quote-header {
  background-color: #e6f4e5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: var(--main);
  border: 1px solid #e7e7e7;
}
.listss {
  list-style: none;
}
.qoute-header h4{
  padding: 0;
  margin: 0;
}
.qoute-price {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
}
.image-details {
  display: inline-flex;
  padding: 5px 10px;
}
.box-lisst {
  padding-left: 20px;
}
.insure {
  padding: 5px;
}

.deliveryCard {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* border: 0.5px solid #c7e6c5; */
}
.deliv-head {
  width: 100%;
  background-color: #E0FCE7;
  padding: 10px;
  font-weight: 700;
  font-size: 16px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.deliv-body {
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 12px;
  font-size: 13px;
  border: 0.5px solid whitesmoke;
  border-top: none;
}
.deliv-bodyv2 {
  display: flex;
  align-items: center;
  gap: 12px;
}
.deliv-body_space {
  font-size: 13px;
  border: 0.5px solid whitesmoke;
  border-top: none;

  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.payStat {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-top: 0;
  font-size: 13px;
  font-weight: 500;
}

.pay-item-edit,
.pay-item-delete {
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  font-weight: 600;
  color: var(--main);
}
.pay-item-delete {
  font-weight: 400;
  color: red;
}
.popContainer {
  width: 350px;
  padding: 30px;
}
.popContainer_payment {
  width: 350px;
  margin: 0 auto;
  /* padding: 10px; */
}
.track-make {
  display: flex;
  justify-content: space-between;
}


.accept-btn{
  border: 1px solid rgb(176, 175, 176);
    padding: 7px;
    border-radius: 4px;
    cursor: not-allowed;
    max-height: 35px;
    user-select: none;
    background-color: rgb(176, 175, 176);
    color: rgb(255, 255, 255);
}


.makepayment-new{
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

@media screen and (max-width : 600px) {
  .popContainer_payment {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .popContainer {
    width: 100%;
    padding: 20px;
  }
  
  .agent-pay-mobile div {
    /* margin-bottom: 10px; */
    margin-top: 10px;
  }
  .btnTrack-mobile {
    font-size: 15px;
    padding: 0px 10px;
    background-color: var(--main);
    color: white;
    border-radius: 5px;
    align-items: center;
    /* width: 100px; */
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  .make-pay-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
