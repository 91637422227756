.dash-cards {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.sender-dashcard-wrap {
  padding: 15px;
  /* margin: 10px; */
  width: 100%;
  background-color: #E0FCE7;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sender-icon {
  margin-right: 15px;
  vertical-align: middle;
}
.description-quote-header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.description-quote {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}
.description-quote span {
  width: 50%;
}
.card-bestqoute {
  text-align: right;
}
.sender-title h2 {
  display: inline;
}
.senderquote-img-details-wrap {
  width: 100%;
  display: flex;
  gap: 30px;
  padding: 20px;
  cursor: pointer;
}
.main-flex-info {
  margin-bottom: 20px;
  padding: 10px 10px 20px 10px;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  border-bottom: 1px solid #c7e6c5;
}
.senderquote-img-details {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  gap: 30px;
  border-bottom: 1px solid #c7e6c5;
}
.qouteinfo-left-right {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 13px;
  /* min-width: 500px; */
}
.right-flex{
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.senderquote-img img {
  max-width: 220px;
}
.senderquote-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.icon-left-text-right {
  display: flex;
}
.icon-left-text-right span {
  margin-right: 15px;
}
.senderquote-details {
  width: 100%;
}
.sender-edit-btn,
.sender-delete-btn {
  cursor: pointer;
}
.sender-edit-btn {
  color: var(--main);
  font-weight: 600;
}
.sender-delete-btn {
  color: red;
  font-weight: normal;
}
.btnTrack {
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  background-color: var(--main);
  color: white;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  width: 100px;
}
.countBtn {
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  background-color: var(--main);
  color: white;
  border-radius: 5px;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.countNum {
  min-width: 19px;
  min-height: 15px;
  border-radius: 50%;
  background-color: white;
  color: var(--main);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 1px;
}

.quote-info-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  /* width: 100%; */
  width: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.quote-info-image-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  /* width: 100%; */
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
}




.item_span {
  cursor: pointer;
}
.item_span:hover {
  color: var(--main);
  text-decoration: underline;
}

.update_itemdesc_con {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.update_itemdesc_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.update_itemdesc_text {
  padding: 15px;
}


.update_itemdesc_btncon {
  display: flex;
  /* justify-content: flex-end; */
  margin-bottom: 20px;
}
.update_itemdesc_btn {
  padding: 7px;
  border-radius: 4px;
  background-color: var(--main);
  color: white;
  border: 1px solid var(--main);
  cursor: pointer;
  width: 68px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.update_itemdesc_btn_disab {
  padding: 7px;
  border-radius: 4px;
  background-color: rgb(165, 165, 165);
  color: white;
  border: 1px solid rgb(165, 165, 165);
  cursor: pointer;
  width: 68px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

form > textarea{
  background-color: #ffffff !important;
  color: #484848 !important;
  border: 1px solid #989898 !important  ;
  outline-color: #989898 !important;
  width: 272px;
  height: 66px;
}


form > textarea::placeholder{
  /* background-color: #ffffff !important; */
  color: #484848 !important;
  /* border: 1px solid #989898 !important  ;
  outline-color: #989898 !important; */
}


/* textarea{
  display:  none !important;
} */


.relist-btn{
  margin-top: 20px;
}

.relist-btn > button{
  background: #FFFFFF;
/* Dillivry-Green */

border: 1px solid #50B14A;
box-sizing: border-box;
border-radius: 4px;
width: 64px;
height: 34px;


font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

/* Dillivry-Green */

color: #50B14A;
cursor: pointer;
}

.item-para{
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 25px;
/* or 179% */

letter-spacing: 0.02em;

/* text */

color: #484848;
width: 498px;
height: 74px;
}


@media screen and (max-width: 900px) {
  .senderquote-img-details{
    display: none;
  }
  .senderquote-img-details-wrap {
    flex-direction: column;
    padding: 10px;
  }
  .senderquote-details {
    padding: 10px 0px;
  }
  .sender-dashcard-wrap {
    box-shadow: none;
  }


  form > textarea{
    /* background-color: #ffffff !important;
    color: #484848 !important;
    border: 1px solid #989898 !important  ;
    outline-color: #989898 !important;
    width: 272px;
    height: 66px; */
    /* display: none; */
    width: auto !important;
  }
  
.top-cart-icon{
 right: inherit;
}

.ack-con{
 padding-left: 0px !important;
}


}



.upper-one{
  display: flex;
  align-items: center;
}

.itemupper span{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  
  /* text */
  
  color: #484848;
  
}

.imageanditemdesc > div{
  /* background-color: #50B14A; */
  padding-bottom: 30px;
}

.lowwertwo{
  display: flex;
  justify-content: space-between;
 padding-right: 30px;
}

.lowertwotwo > div >div > div{
 padding-bottom: 10px;
}

.lowertwotwo img{
  padding-bottom: 10px;
 }

