.mainLgFormX {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--lighter);
  height: 400px;
  border-radius: 0px 0px 10px 10px;
  padding-top: 0px;
}
.Xfff {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  /* height: 370px; */
  height: 220px;
  border-radius: 0px 0px 10px 10px;
  padding-top: 0px;
  box-shadow: 2px 2px 10px 1px #9b9e9c;
  /* box-shadow: 5px 5px 8px #eaeaea; */
}
.XformCont {
  padding: 30px 40px 20px 40px;
  min-height: 220px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
}
.Xsubtitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  
}
.XlgcheckBox {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 2.5px;
  left: 90px;
  outline: 0px;
  filter: hue-rotate(600deg);
}
.XlgCheckBoxCon {
  position: relative;
  /*background-color: blanchedalmond;*/
  padding-right: 10%;
  margin-right: 18%;
  /*color: gray*/
}
.Xheadingg {
  background-color: var(--main);
  padding-left: 30px;
  height: 40px;
  line-height: 40px;
  color: var(--lighter);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  /* justify-content: space-between; */
  /*position: relative;*/

  /* margin-bottom: 15px; */
}
.XacctTyp {
  font-style: normal;
  font-weight: 600;
  /*font-size: 16px;*/
  color: #ffffff;
  letter-spacing: 0.5px;
}


.formV2Cont {
  padding:  20px ;
  width: 100%;
  min-width: 300px;
  border-radius: 0px 0px 10px 10px;
  background-color: white;
}
.formV2body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.confirmPassw{
  color: var(--main);
  font-size: large;
  margin-bottom: 20px;
  font-weight: 600;
}
.formInputV2{
  width: 100%;
}
