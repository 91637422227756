.welcomeReg{
    width: 100%;
    height:calc(100vh / 2);
    display:flex;
    flex-direction:column; 
    justify-content:space-between;
    align-items:center;
    padding-bottom: 20px;
}

@media screen and (max-width: 900px) {
    .welcomeReg{
        height: calc(100vh - 40px);
    padding-bottom: 40px;
         
    }
}
