.homeV2{
  width: 100%;
}
.home_max_width{
  max-width: 1440px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.cookieCon{
  text-align: center;
}
/* .cookieButtonCon{
  background-color: aqua;
} */
.MuiDialogContent-root{
  overflow-x: hidden !important;
}

@media screen and (max-width: 900px) {
  .cookieButtonCon{
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .cookieCon{
    text-align: start;
  }
}