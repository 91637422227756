.stepper_container{
    /* width: calc(100% - 620px); */
    width: 1024px;
    display: flex;
    align-items: center;
    gap: 5px;
    overflow-x: scroll;
    height: 50px;
}
.stepper_cont{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 5px;
}
.stepper_text, .stepper_text_active, .stepper_text_pending{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--main);
    white-space: nowrap
}
.stepper_text_active{
    color: #004DC1;
}
.stepper_text_pending{
    color: #B0AFB0;
}
@media screen and (max-width: 900px) {
    .stepper_container{
        width: 100%;
       
    }   
    
}

@media screen 
  and (min-width: 900px) 
  and (max-width: 1200px)  { 
    .stepper_container{
        width: 700px;
    } 
}

@media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px)  { 
    .stepper_container{
        width: 1000px;
    } 
}

@media screen 
  and (min-width: 1601px)  { 
    .stepper_container{
        width: 1400px;
    } 
}
