.createAccount{
    width: 100%;
    background: #E7F1FE;
    padding-top: 80px;
  }

  .createAccountCont1{
    display: flex;
    width: 100%;
    padding: 100px 150px;
    align-items: flex-start;
  }
  .leftCreateDownload{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
  .leftCreateDownload h1{
   color: var(--textColor);
   font-size: 45px;
   font-weight: 600;
  }
  .leftCreateDownload h6{
    color: var(--textColor);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  
  .rightCreateDownload{
    display: flex;
    width: 50%;
    justify-content: flex-end;
  }
  .rightCreateDownload img{
    max-height: 450px;
  }
.comp_input_type1 select{
    width: 100%;
    outline: none;
    border: none;
    border-radius: 30px;
    font-size: 16px;
}
.comp_input_type1{
    outline: none;
    border: none;
    width: 100%;
    border-radius: 30px;
    font-weight: 300;
    background-color: white;
    padding: 12px 20px;
}
.comp_input_type{
    outline: none;
    border: none;
    width: 100%;
    border-radius: 30px;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 300;
    background-color: white;
}

.comp_input_type::placeholder {
    color:#B0AFB0;
    opacity: 1; /* Firefox */
  }

.comp_btn1{
    outline: none;
    border: none;
    width: 100%;
    border-radius: 20px;
    padding: 15px 20px;
    background-color: var(--main);
    color: white;
    font-weight: 600;
    font-size: 18px;
    margin-top: 25px;
    user-select: none;
    cursor: pointer;
}



@media screen and (max-width: 900px) {

    .createAccountCont{
        width: 100%;
        padding: 40px 20px;
        align-items: center;
        flex-direction: column-reverse;
        gap: 30px;
      }
      .createAccountCont1{
        width: 100%;
        padding: 40px 20px;
        align-items: flex-start;
        flex-direction: column;
        gap: 30px;
      }
      .leftCreateDownload{
        align-items: center;
        width: 100%;
      }
      .leftCreateDownload h1{
        font-size: 35px;
        font-weight: 600;
       }
       .leftCreateDownload h6{
         font-size: 16px;
         text-align: center;
       }
    
      .rightCreateDownload{
        display: flex;
        width: 100%;
        justify-content: center;
      }
      .rightCreateDownload img{
        max-height: 300px;
      }

    
}