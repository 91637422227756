.navbar-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-business-details,
.cart-request-question {
  padding: 5px 20px 5px 20px;
}

.nav-business-details {
  display: flex;
  background: #E0FCE7;
  width: 320px;
}
.nav-business-name{
  color: var(--textColor);
  font-size: 13px;
  display: flex;
  flex-direction: column;
}
.nav-business-name p{
  font-size: 14px;
  font-weight: 500;
}
.cart-request-question {
  display: flex;
  align-items: center;
  gap: 20px;
  /* gap: 10px; */
}

.nav-business-details {
  gap: 10px;
}
.nav-img{
  width: 50px;
 height: 50px;
 border-radius: 50%;
 border: 0.5px solid var(--msgBorderline);
 background-size: contain;
 background-repeat: no-repeat;
 background-position: center;
}

.search-input > input {
  width: 400px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 20px;
  text-indent: 20px;
}

.request-btn {
  background: #50b14a;
  border-radius: 4px;
}
.cart-icon {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.cart-icon-badge {
  min-width: 19px;
  min-height: 15px;
  border-radius: 50%;
  background-color: red;
  color: white;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 1px;
}

.cart-request-question img {
  height: 32px;
  cursor: pointer;
}

.request-main-btn {
  background: #50b14a;
  border-radius: 4px;
  padding: 10px;
  color: #ffffff;
  border: #50b14a;
  cursor: pointer;
  user-select: none;
}

.logout {
  cursor: pointer;
}

.instant-schedule{
  display: flex;
  justify-content: center;
  align-items: center;
}

.Schedule-sub{
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: center;
width: 111px;
/* text */

color: #484848;
}

.instant-sub{
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: center;
width: 111px;
/* text */

color: #484848;
}

.Schedule, .instant{
  width: 175px;
}


.instant{
  width: 175px;
height: 159px;
left: 13px;
top: 289px;

/* LIGHT GREEN DASH */

background: #E0FCE7;
border-radius: 20px;
cursor: pointer;
/* transform: rotate(-90deg); */
}

.Schedule{
  width: 175px;
height: 159px;
left: 188px;
top: 289px;

/* Background */

background: #F9F9F9;
border-radius: 20px;
/* transform: rotate(-90deg); */
cursor: pointer;
}


.icon{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 60px; */
}

.instant-text-subtext, .Schedule-text-subtext{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

@media only screen and (max-width: 1200px) and (min-width: 570px) {
  .search-input > input {
    width: 100%;
  }
}

/* 

@media only screen and (max-width:   900px) {

    .navbar-main{
        display: none;
    }

} */
