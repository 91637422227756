.hellbanner{
    background: linear-gradient(88.15deg, rgba(50, 160, 113, 0.6) -13.41%, rgba(21, 207, 116, 0.6) 67.33%, rgba(45, 167, 113, 0.6) 114.58%);
    border-radius: 8px;
    color: white;
    padding: 10px;
    width: 100%;

}
.backmsg :hover{
    color: var(--main);
}
.mobilecorp_con{
    display: none;
}
.hellbannerTop{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}
.hellbannerTop>span{
    font-size: 25px;
}
.hellbannerBottom{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

}
.openrequests{
   margin-top: 20px;
}
.openrequests>h3{
    margin-bottom: 20px;

}
.openrequests>table>thead>th{
    border-bottom: 1px solid #B0AFB0;
    background-color: white!important;
    /* text-align: left; */
    font-weight: 500;
}
.openrequests>table>tbody>td{
    border-bottom: 1px solid #E7E7E7;
    /* text-align: left; */
    
}
.search-input-navbar{
    position: relative;
    /* width: 400px; */
    
}
.searchx-icon{
    position: absolute;
    top: -57px;
    z-index: 1299;
    left: 55%;
}
.view_Containerx{
    width: 100%;
}
.corpmsgtd{
    padding: 20px 0 !important;
    border-bottom: 1px solid gainsboro;
}
.corpmsgtr:hover{
    background-color: #E5E5E5;
    /* transform: scale(1.02); */
    font-weight: 500 !important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
    border-right: none !important;

}
.gogo>.css-dyjd4v-MuiDrawer-docked .MuiDrawer-paper{
    z-index: 900;
}
.backaccount:hover{
    color: var(--main);
}

@media screen and (max-width: 900px) {
    /* .wallet_home {
        display: block;
        width: 100%;
    } */
    .settlements{
        justify-content: center;
    }
    .corpuserDesktop{
        display: none;
    }

    .mobilecorp_con{
        display: block;
    }
    .mobilecorp_gen{
       width: 100%;
       padding: 15px;
       padding-bottom: 70px;
    }
    .deskstopcorp_con{
        display: none;
    }
    .hellbannerBottom>span{
        width: 100%;
       
       
    }
    .hellbannerBottom, .hellbannerTop{
        padding: 10px;
    }
    .helloleft{
        text-align: left;

    }
    .helloright{
        text-align: right;
    }
    
}