.bgx {
  width: 100%;
  height: 100vh;
  padding: 0;
  box-sizing: border-box;
  background: #ffffff;
  padding-top: 90px;
}

.nontest {
  display: none;
}
.page {
  width: 100%;
  height: calc(100vh - 100px);
  padding: 0;
  box-sizing: border-box;
}

.view_menu1 {
  width: 100%;
  height: 60px;
  background-color: var(--lighest);
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 30px;
}
.viewmenumobile {
  display: none;
}

.view_menu {
  width: 100%;
  height: 81px;
  background-color: #e6f4e5;
  gap: 20px;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  padding: 0 100px;
  margin: 90px 0 30px 0;
}
.activeTabb {
  width: 150px;
  color: #000000;
  background-color: var(--lighter);
  display: flex;
  border: solid var(--lighest) 1px;
  /* border-top-right-radius: 10px;
      border-top-left-radius: 10px; */
  border-bottom: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 300;
  height: 45px;
}
.activeTabs:hover {
  background-color: var(--lighter);
}
.activeTabb span {
  color: var(--main);
}
.tabs span {
  color: black;
}
.tabs {
  width: 178px;
  height: 42px;
  color: var(--darkest);
  background-color: #c7e6c5;
  display: flex;
  border: solid var(--lighest) 1px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 300;
}
.tabs:hover {
  background-color: var(--lighter);
}
.quoteForm1 {
  /* padding-top: 70px; */
  width: 40%;
  margin: 0 auto;
  padding: 0;
}

.formGroup {
  display: flex;
  flex-direction: column;
}
.formGroupin2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* margin-bottom: 16px; */
}

.progres {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  height: 80px;
  margin-bottom: 20px;
}

.active {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  color: var(--main);
  height: 100%;
  position: relative;
  flex-direction: column;
  gap: 30px;
}
.active .line {
  height: 2px;
  width: 100%;
  background-color: var(--main);
}
.active .circle {
  position: absolute;
  top: -10px;
  left: calc(50% - 25px);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--main);
  display: flex;
  align-items: center;
  justify-content: center;
}
.formTag {
  font-weight: 600;
}
.inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  color: var(--lighest);
  height: 100%;
  position: relative;
  flex-direction: column;
  gap: 30px;
}
.inactive .line {
  height: 2px;
  width: 100%;
  background-color: var(--lighest);
}
.inactive .circle {
  position: absolute;
  top: -10px;
  left: calc(50% - 25px);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--lighest);
  display: flex;
  align-items: center;
  justify-content: center;
}
.progIcon {
  color: white;
  font-size: 25px;
}
.thickLine {
  width: 220px;
  height: 3px;
  border-radius: 1px;
  background-color: rgb(236, 252, 242);
}
.context {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 150px;
}
.headiing {
  padding: 0 30px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.headiing > div {
  display: flex;
}
.headiing .left button {
  padding: 8px 15px;
  border-radius: 5px;
  background-color: var(--main);
  color: white;
  cursor: pointer;
}
.headiing .leftend button {
  padding: 8px 15px;
  border-radius: 5px;
  background-color: var(--main);
  color: white;
  cursor: pointer;
}
.headiing .left button:hover {
  color: gainsboro;
  transform: scale(1.05);
}

.context-past {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 100px 0px;
  display: flex;
  gap: 20px;
}
.context-past-left {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.context-past-right {
  flex: 4;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.column {
  display: flex;
  flex-direction: column;
  height: auto;
  /* border-radius: 10px; */
  margin-bottom: 30px;
  /* border: solid rgb(230, 224, 224) 1px; */
}

.columnHead {
  padding: 15px;
  display: flex;
  background-color: #e6f4e5;
  /* border-top-left-radius: 10px;
      border-top-right-radius: 10px; */
  border: solid var(--lighest) 1px;
  border-bottom: 0;
  color: var(--main);
  font-weight: 700;
  font-size: 12px;
}
.phoneTopic {
  font-size: 13px;
}
.txtTile {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--main);
}
.txt {
  margin-bottom: 15px;
  font-weight: 600;
}
.txt1 {
  margin-bottom: 0;
  font-weight: 600;
}
.tag1 {
  font-size: 15px;
  font-weight: 500;
}
.err {
  font-size: 10px;
  color: red;
  font-style: italic;
}
.columnBody {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: var(--white);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.columnBody > div {
  display: flex;
  flex: 1;
}
.columnBo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: auto;
  background-color: var(--white);
  /* border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; */
  border: solid #c4c4c4 1px;
}
.columnBo > div {
  display: flex;
  flex: 1;
}
.columnBody1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: var(--white);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: solid var(--lighest) 1px;
  flex-direction: column;
  padding: 20px;
}
.columnBody2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  /* background-color: var(--white);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border: solid var(--lighest) 1px; */
  flex-direction: column;
  gap: 2px;
  border: solid #c4c4c4 1px;
  border-top: none;
  font-size: 12px;
}
.columnBody2 div {
  cursor: pointer;
  /* color: var(--darkest); */
  font-weight: 400;
  margin-bottom: 10px;
}
.qut {
  border: solid #c4c4c4 1px;
  width: 100%;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: #989898;
  padding-left: 10px;
  padding-top: 10px;
}
.qut.img {
  color: green;
}
.qutActive {
  border: solid #c4c4c4 1px;
  width: 100%;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: var(--main);
  padding-left: 10px;
  padding-top: 10px;
}
.qut:hover {
  color: var(--main);
}

.columnBody2 div:hover {
  color: var(--main);
}
.need {
  text-align: left;
}

.timeAction {
  display: flex;
}
.time {
  /* text-align: center; */
  flex: 2;
  padding: 15px;
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}
/* .time span{ text-align: center;} */
.actions {
  height: 100%;
  flex: 1;
  /* width: 30%; */
  user-select: none;
  display: flex;
  /* border-bottom-right-radius: 10px; */
  flex-direction: column;
  font-size: 12px;
  border: 1px #c4c4c4 solid;
  color: #989898;
}
.capture {
  flex: 1;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.capture img {
  height: 80px;
  /* width: 120px; */
  max-width: 120px;

  border-radius: 7px;
  /* max-height: 120px; */
}
.info {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: 13px;
}
.infor {
  /* flex: 3; */
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: 13px;
}

.camera {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: dashed var(--lighest) 2px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.camera img {
  height: auto;
  width: 120px;
  max-width: 120px;
  /* max-height: 120px; */
}

.customBtn {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-left: solid var(--lighest) 1px;
  border-top: solid var(--lighest) 1px;
  border-top: solid #c4c4c4 1px;
  cursor: pointer;
}
.customBtn:hover {
  /* background-color: var(--lighest); */
  background-color: #eaeaea;
}
.customBtnUp {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-left: solid var(--lighest) 1px;
  cursor: pointer;
}
.customBtnUp:hover {
  /* background-color: var(--lighest); */
  background-color: #eaeaea;
}
.customBtnDown {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-left: solid var(--lighest) 1px;
  border-top: solid var(--lighest) 1px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #c4c4c4;
  cursor: pointer;
}
.customBtnDown:hover {
  /* background-color: var(--lighest); */
  background-color: #eaeaea;
}
.photo {
  width: 100%;
  height: 80px;
  border: dashed #989898 1px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  flex-direction: column;
  gap: 10px;
}
.upload {
  background-color: var(--main);
  padding: 5px 30px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 1px 2px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploaded {
  background-color: rgba(7, 190, 7, 0.3);
  padding: 5px 30px;
  color: white;
  font-weight: 500;
  cursor:not-allowed;
  border-radius: 5px;
  box-shadow: 0 1px 2px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photoCont {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-bottom: 10px;
  /* height: 100px; */
}
.border {
  height: 500px;
  width: 100%;
  background-size: cover;
  border-radius: 5px;
  box-shadow: 0 10px 6px -6px #777;
  position: relative;
}


.border-{
  /* height: 500px;  */
    width: 100%;
    background-size: cover;
    border-radius: 5px;
    box-shadow: 0 10px 6px -6px #777;
    position: relative;
    /* padding-left: 30px;
    padding-right: 30px; */
}

.border--{
  margin-left: 30px;
}


.forPhoto----{
  padding-left: 30px;
  padding-right: 30px;
}


.description {
  font-size: 16px;
}

.forPhoto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.forPhoto-{
  
}





.forPhoto .icon {
  width: 20px;
  height: 20px;
  box-shadow: 0 10px 6px -6px #777;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 20px;
  margin-bottom: 20px;
  background-color: white;
  color: red;
}
.paginationBttns {
  flex: 1;
  /* width: 100%; */
  /* height: 15px; */
  font-size: 12px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  color: rgb(19, 17, 17);
}

.paginationBttns a {
  padding: 5px 10px;
  border: 1px solid var(--main);
  /* color: var(--main); */
  color: #2e2e2e;

  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: var(--main);
}

.paginationActive a {
  color: white;
  background-color: var(--main);
}

.paginationDisabled a {
  color: #989898;
  background-color: var(--lighest);
}
.data {
  display: flex;
  margin-bottom: 5px;
}
.informtion {
  width: 100%;
  /* padding: 30px 120px; */
}
.informed {
  width: 100%;
  border: solid 1px gainsboro;
  border-radius: 8px;
}
.infoDetails {
  flex: 5;
  font-size: 15px;
  font-weight: 400;
  display: flex;
}

.infoDetailsm {
  flex: 5;
  font-size: 15px;
  font-weight: 400;
  display: flex;
}

.infoDetails- {
  flex: 5;
  font-size: 15px;
  font-weight: 400;
  /* display: flex; */
}



.update-btn-{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.update-btn{

  /* width: auto; */
  padding: 5px 5px;
    border-radius: 5px;
    background-color: white;
    color: var(--main);
    cursor: pointer;
    border: 1px solid var(--main);
}

.profileMssg {
  width: 70%;
}
.mssgeBtn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 30%;
}
.aQuote {
  position: relative;
  padding: 6px 10px;
  background-color: var(--main);
  border-radius: 5px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  user-select: none;
  height: 30px;
  display: flex;
  width: 120px;
}
.aQuote:hover {
  background-color: #73af46;
}
.mQuote {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 98px;
  left: 57%;
  font-size: 10px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
}
.cQuote {
  position: absolute;
  /* padding: 10px; */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -10px;
  left: 0;
  font-size: 10px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
}
.cQuote1 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: -10px;
  left: 0;
  font-size: 10px;
  font-weight: 600;
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.infoTitle {
  flex: 1.4;
  color: gray;
  font-size: 12px;
  margin-right: 25px;
}
.formation {
  position: relative;
  display: flex;
  width: 100%;
  padding: 25px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  /* gap: 100px; */
}





.formation1 {
  position: relative;
  display: flex;
  width: 100%;
  padding: 25px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}
.formation:hover {
  /* background-color: rgb(236, 236, 236); */
  border: solid 1px rgb(236, 236, 236);
}
.person_infos{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.pers {
  font-size: 25px;
  padding: 25px;
}
.pers_img{
  padding: 25px 25px 0 0;
}
.divida {
  position: absolute;
  width: calc(100% - 25px);
  bottom: 0;
  right: 0;
  height: 1px;
  background-color: gainsboro;
}
.topCapture {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* border-top:  solid var(--lighest) 1px; */
}
.lissst {
  display: flex;
  /* background-color: goldenrod; */
  /* flex-direction: column; */
}

.relist {
  /* width: 100%; */
  padding: 10px;
  /* background-color: var(--main); */
  display: flex;
  align-items: center;
  justify-content: center;
}
.relistBtn {
  width: 200px;
  height: 40px;
  background-color: var(--main);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  border-radius: 5px;
}
.relistBtn:hover {
  background-color: #73af46;
}

.form_parent {
  width: 100%;
  height: calc(100vh - 70px);
  /* overflow: scroll; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.getQuoteForm {
  width: 600px;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  overflow-y: scroll;
  padding: 30px;
}
/* past deliveries css */

.comdelitop {
  height: 34px;
  left: 360px;
  top: 187px;
  background: #fcfcfc;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  font-family: Roboto;
  font-style: normal;
  color: #50b14a;
  font-size: 12px;
  padding: 10px 10px 10px 10px;
}
.comdeliflex {
  display: flex;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  height: 160px;

  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
}
.comdeliflex1 {
  flex: 1;
  padding: 22px 0px 48px 31px;
}
.comdeliflex2 {
  flex: 4;
  text-align: left;
  padding-top: 15px;
  font-size: 10px;
  line-height: 20px;
}
.comdeliflex3 {
  flex: 2;
  padding-top: 70px;
}
.comdeliflex4 {
  flex: 1;
  text-align: right;
  /* border: 2px green solid; */
}
.act {
  margin-top: 20px;
  width: 120px;
  font-size: 12px;
  border: 1px #c4c4c4 solid;
  color: #989898;
  margin-bottom: 20px;
}
/* past deliveries css end here*/

/* acount css start here*/
.acchead {
  display: flex;
  height: 34px;
  left: 100px;
  top: 187px;
  background: #f9f9f9;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 6px 10px 10px 20px;
}
.accheadtextright {
  height: 14px;
  color: #50b14a;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}
.accheadtextleft {
  color: #c54b4b;
  border: none;
}
.accbod {
  height: 182px;
  left: 100px;
  top: 220px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  font-weight: 500;
  border-top: none;
}
.accbodinformation {
  display: flex;
  padding: 30px 0px 0px 70px;
}
.inforbd2 {
  padding-left: 40px;
}
.acclic {
  text-decoration-line: underline;
  color: #666666;
  font-size: 12px;
  margin-top: 10px;
}

/* acount css end here*/
.dialogdiv {
  text-align: right;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  /* margin-left: 200px; */
}
.dialodivbt {
  width: 35px;
  height: 30px;
  border: none;
  border-radius: 5px;
  color: white;
}

.indQuote-nav {
  display: none;
}
.showMobileQuote {
  display: none;
}
.showDeskQuote {
  display: block;
}

.category_value{
  position: absolute;
  width: 100%;
  z-index: 20;
  /* border: 1px solid #C4C4C4; */
  border-radius: 5px;
  max-width: 550px;
  background-color: white;
  padding: 10px;
  box-shadow: 5px 10px 18px #C4C4C4;
  border-width: 0 !important;
}

.category_selector-{
  border-width: 0 !important;
  display: flex;
}

.category_selector-:hover{
  /* border: 1px solid black; */
  border-width: 0 !important;
}

.span-text-{
  display: flex;
  gap: 10px;
  
}

.span-text-ul > li{
  background: #E7E7E7;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px
}


.span-text-ul{
  display: flex;
  flex-wrap: wrap;
  padding: 2px 4px;
  cursor: pointer;
  background: #E7E7E7;
  border-radius: 2px;
  font-size: 13px;
}


form > ul > label{
  display: flex;
  gap: 15px;
  margin-bottom: 5px;
}


.flex-tutor{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-tutor > div:nth-child(1){
  /* width: 100px; */
  display: flex;
  /* flex-basis: ; */
}

.span-text--{
  display: flex;
 flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.check_container .check:after {
  left: 3px !important;
  top: -3px !important;
  width: 5px !important;
  height: 10px !important;
}


@media screen and (max-width: 900px) {
  .mssgeBtn{
    width: 100%;
  }
  .columnBody1 {
    padding: 0;
  }
  .formation1 {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .infoDetails {
    width: 100%;
    /* flex-direction: column; */
  }

  .infoDetailsm {
    width: 100%;
    /* flex-direction: column; */
  }

  .infoDetailsm {
    width: 100%;
    /* flex-direction: column; */
  }

  .profileMssg {
    width: 100%;
  }
  .showMobileQuote {
    display: block;
  }
  .showDeskQuote {
    display: none;
  }
  .bgx {
    background: none;
  }
  .form_parent {
    padding: 20px;
  }
  .getQuoteForm {
    width: 100%;
    /* margin-top: 100px; */
    padding: 20px;
  }
  .context {
    padding: 0 20px;
  }

  .column {
    display: flex;
    flex-direction: column;
    height: auto;
    border-radius: 10px;
    margin-bottom: 30px;
  }

  .columnBody {
    flex-direction: column;
  }
  .columnBo {
    flex-direction: column;
  }

  .topCapture {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-top: solid var(--lighest) 1px;
  }

  .info {
    /* flex: 3; */
    padding: 15px;
    font-size: 12px;
  }
  .infor {
    /* flex: 3; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    font-size: 13px;
  }
  .time {
    /* flex: 2; */
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .time span {
    text-align: center;
    font-size: 12px;
  }
  .actions {
    font-size: 12px;
  }
  .timeAction {
    display: flex;
    width: 100%;
    border-top: solid var(--lighest) 1px;
  }
  .lissst {
    display: flex;
    flex-direction: column;
  }
  .relist {
    /* width: 100%; */
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .relistBtn {
    width: 100%;
    margin-bottom: 10px;
  }

  .customBtn {
    width: 100%;
    height: 35px;
  }

  .customBtnUp {
    width: 100%;
    height: 35px;
  }

  .customBtnDown {
    width: 100%;
    height: 35px;
  }

  .context-past {
    padding: 0 20px;
  }
  .context-past-left {
    box-sizing: border-box;
    display: none;
  }

  .view_menu {
    padding: 0 20px;
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
  }
  .camera img {
    height: auto;
    width: 100px;
    max-width: 100px;
    /* max-height: 100px; */
  }
  .capture img {
    height: auto;
    width: 100px;
    max-width: 100px;
    border-radius: 7px;
    /* max-height: 100px; */
  }

  .quoteForm1 {
    width: 80%;
  }

  .page1 {
    flex: 1;
  }
  line {
    flex: 1;
  }
  .page2 {
    flex: 1;
  }
  .active {
    color: black;
  }
  .inactive {
    color: grey;
  }
  .thickLine {
    width: 100px;
  }
  .forPhoto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* width: 100px;
              height: 100px; */
  }
  .border {
    height: 150px;
    width: 100%;
    background-size: cover;
  }

  .informtion {
    padding: 2px;
  }
  /* .informed{
              width: 100%;
              border: solid 1px gainsboro;
              border-radius: 8px;
          } */
  .infoDetails {
    flex: 5;
    /* text-transform: capitalize; */
    font-size: 13px;
    font-weight: 400;
  }

  .infoDetailsm {
    flex: 5;
    /* text-transform: capitalize; */
    font-size: 13px;
    font-weight: 400;
  }
  

  .infoTitle {
    flex: 1;
    color: gray;
    font-size: 10px;
  }
  .formation {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .formation:hover {
    /* background-color: gainsboro; */
    border: solid 1px gainsboro;
  }
  .pers {
    font-size: 17px;
    padding: 15px;
    font-weight: 600;
  }
  .divida {
    width: calc(100% - 20px);
  }
  .column a {
    font-size: 12px;
  }
  .progres {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 80px;
    margin-bottom: 20px;
  }

  .active {
    gap: 30px;
  }
  .active .circle {
    position: absolute;
    top: -5px;
    left: calc(50% - 20px);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: var(--main);
  }
  .formTag {
    font-weight: 500;
  }
  .inactive {
    gap: 30px;
  }
  .btnlabel {
    font-weight: 600;
  }

  .inactive .circle {
    position: absolute;
    top: -5px;
    left: calc(50% - 20px);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: var(--lighest);
  }

  .progIcon {
    color: white;
    font-size: 20px;
  }

  .indQuote-nav {
    display: flex;
    justify-content: space-between;
    margin-top: -80px;
  }

  .logocont {
    display: block;
    padding-top: 20px;
    width: 50%;
  }

  .logocont img {
    width: 100px;
    margin: 0 auto;
  }
  .viewmenumobile {
    display: block;
    width: "100%";
  }
  .viewmenudesktop {
    display: none;
  }
  .myowncontex {
    display: none;
  }
  .muyiwa {
    display: none;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .muyiwa {
    display: block;
  }
  .right-content-information {
    margin-top: 200px;
  }
  .carousel_image {
    width: 400px;
  }

  .AgetDe {
    margin-top: 250px;
  }

.forPhoto----- {
  /* width: "150px" !important;
  padding-left: "0px" !important;
  padding-right: "0px" !important; */
  /* padding: 0 ; */
  /* margin: 0 auto; */
}

}
