* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .status-body {
      margin: 0 auto;
      width: 70%;
    margin-top: 120px;
  }
  
  
  .section-one{
      display: flex;
      justify-content: space-between;
  }
  
  .left-side-status{
      width: 50%;
  }
  
  .rightfirst{
      width: 50%;
  }
  
  
  .to-be-styled-seperately{
      background: #C4C4C4;
  }
  
  .staus-main{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }
  
  .Listed-main{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      color: #50B14A;
  }
  
  
  .Date-listed-main{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      
  }
  
  .tobe-delivered{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      padding: 20px;
  }
  
  .Contact{
      padding: 20px;
      font-size: 12px;
  }
  
  .Contact h1{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
  }
  
  .sectionB-{
      margin-top: 50px;
  
  }
  
  .section-two{
      display: flex;
      justify-content: space-between;
  }
  
  .section-two-follow-my-item{
      width: 50%;
  }

  .followmyitem{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  
  color: #000000;
  }
  
  .findout{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      margin-bottom: 30px;
  }
  
  
  .container{
      width: 100%;
  }
  
  .progressBar3 li{
      position: relative;
      margin: 30px 30px 30px 0px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  
  color: #000000;
  
  }
  
  .progressBar3{
      counter-reset: step;
  }
  
  
  .progressBar3 li::before{
  
  height: 30px;
  background-color: white;
  content: url(/src//assets/Checkmark.svg);
  
  border-radius: 15%;
  line-height: 30px;
  text-align: center;
  border: 1px solid #50B14A;
  margin-right: 10px;
  }
  
  
  .progressBar3 li::after{
      content: "";
      background-color: #ddd; 
      position: absolute; 
      bottom: 0; 
      left: 5px; 
      top: 30px;
      height: 100%; 
      width: 1.5px;
  }
  
  .progressBar3 li:nth-child(6)::after{
    display: none;
  }

  .inner-img-div > img{
      max-height: 276px;
      width: 354px;
      margin-left: 180px;
  }


  .third-section{
      display: flex;
      justify-content: space-between;
  }

  .main-twp-rate{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 3;
      background: #E5E5E5;
  }

  .smiley-green-and-red{
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
  }

  .smiley-green-and-red > img:nth-child(1){
      margin-right: 40px !important;
  }
  
  .itemdesc{
      width: 15px;
  }


  .inner-img-div > p{
      text-align: center;
      margin-top: 30px;
  }
  
  @media screen and (max-width: 900px) {

    .inner-img-div > img{
        max-height: 276px;
        width: 354px;
        margin-left: 0px;
    }
  
      .status-body{
          width: auto;
          padding: 30px;
      }
  
  
      .section-one{
          flex-direction: column;
          justify-content: center;
      }
  
      .rightfirst{
          width:  100%;
      }
  
      .left-side-status{
          width: 100%;
          margin-bottom: 30px;
      }
  
      .section-two{
          flex-direction: column;
      }
  
      .img-display > div{
       width: 100%;

      }
  
      .progressBar3 li{
          position: relative;
          width: 100% !important;
          font-size: 16px;
      }
  
      .section-two-follow-my-item{
          width: 100%;
      }
  
      .to-be-styled-seperately{
          width: 100%;
      }
  .third-section{
      margin-top: 20px;
  }
  
  .smiley-green-and-red{
    padding-right: 0px;
}

.smiley-green-and-red > img:nth-child(1){
    margin-right: 30px !important;
}

.inner-img-div > img{
    width: 200px !important;
}


.status-body {
    margin-top: 0px;
}

.empty1, .empty-three{
    flex: none;
}

  }


  @media screen and (min-width: 400px)   {
   .section-two{
       flex-direction: row;
   }
  }