.settlements{
    width: 100%;
    display: flex;
    gap: 20px;
}
.rightSettle{
    width: 200px ;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 1px solid #C4C4C4;
   border-bottom: none;
   font-weight: 600;
   height: 100%;
}
.settleTabHead{
   width: 100%;
   padding: 10px; 
   background-color: #E6F4E5;
   border-bottom: 1px solid #C4C4C4;
   color: var(--main);
   user-select: none;
}
.settleTabActive{
    width: 100%;
   color: var(--main);
    padding: 10px; 
    border-bottom: 1px solid #C4C4C4;
   user-select: none;
   cursor: pointer;
}
.settleTab{
    width: 100%;
    padding: 10px; 
   user-select: none;
   cursor: pointer;
   border-bottom: 1px solid #C4C4C4;
   color: #989898;
}
.settleTab:hover{
    color: var(--main);

}
.leftSettle{
    width: calc(100% - 200px);
    border: 1px solid #EAEAEA;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    /* background-color: red; */
}
.leftSettleHead{
    padding: 10px; 
    background-color:#f9f9f9;
    font-weight: 600;
    color: var(--main);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}
.leftSettleBody{
    width: 100%;
    padding: 20px;
}
.leftSettleTopCard{
    width: 100%;
    margin-bottom: 20px;
}
.leftSettleCardTop{
    width: 100%;
    border: 1px solid #EAEAEA;
    border-right: none;
    display: flex;
}
.carded{
    padding: 15px 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #EAEAEA;
}
.currentWeek{
   display: flex;
   flex-direction: column; 
   gap: 5px;
}
.weekk{
    font-weight: 400;
    font-size: 22px;
}
.weekSpan{
    font-weight: 400;
    font-size: 14px;
    color: #989898;
}
.quotedRevenue{
    margin-top: 25px;
    width: 100%;
    display: flex;
    gap: 2%;
}
.quotedRevenueInner{
    width: 49%;
    border-right: 1px solid #989898;
}
.quotedRevenueInner1{
    width: 49%;
}
.revText{
    font-size: 13px;
    margin-bottom: 5px;
}
.revAmount{
    font-weight: 600;
    font-size: 24px;
}

.leftSettleCardBase{
    display: flex;
    align-items: center;
    width: 100%;
    padding:5px 10px;
    gap: 10px;
    border: 1px solid #EAEAEA;
    background-color:#f9f9f9;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    user-select: none;
}
.viewReport{
    display: flex;
    user-select: none;
    cursor: pointer;
}
.viewReport:hover{
    color: var(--main);
}
.trend{
    width: 100%;
}
.trendTop{
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.typeDeliveries{
    border: none;
    outline: none;
    width: 150px;
    font-weight: 600;
    font-size: 14px;

}
.typeTime{
    border: none;
    outline: none;
    width: 80px;
    font-size: 14px;
    background: none;
    font-weight: 600;
}
.trendTopTitle{
    width: 100%;
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E6F4E5;
}
.dateMonth{
    color: var(--main);
    font-weight: 500;
}
.chartt{
    padding: 0 10px;
    cursor: pointer;
    font-size: 18px;
}
.chartt:hover{
    color: var(--main);
}
.activeChartt{
    padding: 0 10px;
    cursor: pointer;
    font-size: 18px;
    color: var(--main);
}
.trendGraph{
    width: 100%;
    padding: 20px 30px;
    border: 1px solid #EAEAEA;
    border-top: none;
}
.reportTableWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.reportHead{
    background-color: #E6F4E5;
    padding:7px 10px;
    border: 1px solid #EAEAEA;

}
.reportBdy{
    padding:7px 10px;
    border: 1px solid #EAEAEA;
    border-top: none;
}
.toolTips {
    cursor: help;
    position: relative;
}


/*== common styles for both parts of toolTips tip ==*/
.toolTips::before,
.toolTips::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
}

.toolTips:hover::before,
.toolTips:focus::before,
.toolTips:hover::after,
.toolTips:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100; 
}



.toolTips:hover::before,
.toolTips:focus::before {
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
}


/*== speech bubble ==*/
.toolTips::after {
    background: black;
    border-radius: 0.7em;
    bottom: 180%;
    color: white;
    content: attr(data-tip);
    /* margin-bottom: -8.75em; */
    margin-bottom: -4.75em;
    margin-left: 1em;
    padding: 1em;
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
    transform:  scale(.6) translateY(50%);  
    width: 17.5em;
    font-size: 10px;
}

.toolTips:hover::after,
.toolTips:focus::after  {
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
}
.backBtn{
    display: none;
}
.chartweb{
    display: block;
}
.chartMob{
    display: none;
}
.topSettle{
    display: none;
}
@media  screen and (max-width: 900px) {
    .chartweb{
        display: none;
    }
    .chartMob{
        display: block;
    }

    .backBtn{
        display: block;
    }
    .rightSettle{
        display: none;
    }
    .leftSettleCardTop{
        flex-direction: column;
    }
    .topSettle{
        display: flex;
        width: 100%;
        gap: 10px;
        margin-bottom: 15px;
        background-color: white;
    }
    .topActive{
        flex: 1;
        color: var(--main);
        background-color: white;
        padding: 10px;
        font-weight: 500;
        border-bottom: 2px solid var(--main);
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .topInactive{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        font-weight: 500;
        padding: 10px;
        background-color: white;
    }
    .leftSettleBody{
        padding: 0;
    }
    .leftSettle{
        width: 100%;
    }
  .carded{
      padding: 15px;
        border-bottom: 2px solid #EAEAEA;

  }
    .quotedRevenue{
        width: 100%;
        flex-direction: column;
        gap: 20px;
    }
    .quotedRevenueInner{
        width: 100%;
        border-right: none;
    }
    .quotedRevenueInner1{
        width: 100%;
    }
    .trendGraph{
        padding: 20px 0;
    }
    .reportTableWrapper{
        font-size: 13px;
        text-align: center;
    }

}