
.stepHomeCard{
    display: flex;
    align-items: center;
    gap: 40px;
}


.StepDetails{
    /* margin-left: 40px; */
}


.stepCard{
    display: flex;
    /* align-items: center; */
}
.stepCard_img{
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: contain;
}
.stepCardText, .StepDetails{
    color: var(--textColor);
}
.stepCardText h3, .StepDetails h3{
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 7px;
}
.stepCardText h4, .StepDetails h4{
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
}
@media screen and (max-width : 900px) {
    .stepCard_img{
        max-height: 80px;
        max-width: 80px;
    }
    
    .stepCardText h3{
        font-size: 18px;
        margin-bottom: 7px;
    }
    .stepCardText h4{
        font-size: 14px;
        line-height: 20px;
    }
}