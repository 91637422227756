*{
    padding: 0;
    box-sizing: border-box;
}
.paginational{
    padding: 10px;
    background-color: #fafafa;
    border-radius: 10px;
}
.paginational ul{
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.paginational ul li{
    width: 20px;
    display: block;
    user-select: none;
    /* background-color: #50B14A; */
    font-weight: 600;
    color: #444343;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
}
.paginational ul .selected{
    margin-bottom: 0; 
    color: var(--main);
    background-color: white;
    border-radius: 3px;
    border: 1px solid #fafafa;
}

.infoDetails22{
    display: flex;
    gap: 20px;
    width: 100%;
    /* justify-content: center;
    align-items: center; */
    margin-top: 20px;
}
.infoDetailsX{
   display: flex ;
   flex-direction: column;
   flex: 5 ; 
}
.deleteico{
    color: grey;
    cursor: pointer;
    margin-left: 10px;
}
.deleteico:hover{
    transform: scale(1.1);
}
.settingState{
    margin-right: 20px;
}
.prefBtn{
    border: none;
    background-color: var(--main);
    color: white;
    padding: 8px 18px;
    border-radius: 4px;
    /* margin-left: 10px; */
}
.preference{
    /* display: grid;
    grid-template-columns: 25% 70%; */
    display: flex;
    width: 100%;
    
    
}
.preferenceTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: gray;
    font-size: 12px;
    margin-right: 20px;
}

.cdqdiv{
    display:flex;
    /* height: 100%;
    width: 100%; */
    margin-top: 30px;
    gap: 40px;
    
}
.cdqdivpict{
    /* width: 334.19px;
    display: flex;
    justify-content: center;
    border-radius:10px;
    border: 1px solid #eaeaea */
    flex: 3;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 310px;
    border: 1px solid #eaeaea;
    /* padding-top: 50px; */
   
}
.makeStyles-root-25{
    display: none;
}
.img-cdiv{
    max-height: 270px;
    position: relative;
    z-index: -200
}
.cdqdivinfo{
  width:100%;
  flex:4;
}

.cdqdiviagt{
    flex:2;
  width:100%;
    
} 
.quohistrydiv{
  margin-top: 150px;
  margin-left: 170px;
  margin-right: 170px;
 
} 
.paymentstatus{
    display:flex
}
.areullog{
  margin-left: 170px;
  margin-right: 170px;
} 


.quote_di{
    width: 350px;
    max-width: 400px;
}
.quote_div{
    width: 300px;
}
.quote_div img{
    max-width: '300px';
}
.dasboard{
    height: auto;
    width: 100%;
}

.dashboardDesktop{
    width: 100%;
    padding:  0;
}
.dashboardDesktopTop{
    width: 100%;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    margin-bottom: 30px;
    background-color: rgb(236, 252, 242);
    box-shadow: 0 10px 6px -6px #777;
}

.topmenu{
    width: 100%;
    /* height: 80px; */
    position: absolute;
    top: 0;
    left: 0;
}


.dashboardDesktopTop >div{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}
.dashboardDesktopBody{
    width: 100%;
    padding: 0 50px; }
.myImage{
    height: 100%;
    padding: 5px;
    width: 120px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 10px 10px -6px #777;    */
}
.myImage img{
    height: 50px;
    max-width: 100px;
    cursor: pointer;
}

.backBtnMobile{
    display: none;
}
.content-view_menu{
    margin-top: 80px;
    width: 100%;
    height: 60px;
    background-color: var(--lighest);
    gap: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    padding: 0 50px;
    margin-bottom: 30px;
}
.activeTabs{
    width: 200px;
    height: 40px;
    color:var(--main);
    background-color: var(--lighter);
    display: flex;
    border: solid var(--lighest) 1px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none; 
}
.activeTabs:hover{
    background-color: var(--lighter);
}
.tab{
    width: 200px;
    height: 40px;
    color:var(--darkest);
    background-color: var(--lighest);
    display: flex;
    border: solid var(--lighest) 1px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none; 
}
.tab:hover{
    background-color: var(--lighter);
}
.content-past{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0px;
    display: flex;
    gap: 20px;
}
.content-past-left{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.content-past-right{
    flex: 5;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.content-column{
    display: flex;
    flex-direction: column;
    height: auto;
    border-radius: 10px;
    margin-bottom: 30px;
}
.content-columnHead{
    padding: 15px 0;
    /* background-color: rgb(236, 252, 242); */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border: solid var(--lighest) 1px; */
    /* border-bottom: 0; */
    color: var(--main);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}
.content-columnBody{
    
    height: auto;
    background-color: var(--white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* border: solid var(--lighest) 1px; */
    
}

.lissst{
    display: flex;
    flex-direction: column;
}
.content-columnBody1{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: var(--white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* border: solid var(--lighest) 1px; */
    flex-direction: column;
    padding: 20px;  
}
.content-columnBody2{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    background-color: var(--white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* border: solid var(--lighest) 1px; */
    flex-direction: column;
    padding: 20px;  
    gap: 20px;
}
.MuiPaper-elevation2{
    box-shadow: none !important;
}
.content-column a{
    text-decoration: none;
    color: var(--darkest);
    font-weight: 500;
    cursor: pointer;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none; 
}
.content-column a:hover{
    color: var(--main);
}
.content-column .activeLink{
    color: var(--main);
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none; 
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
 .topm{
 height: 80px;
 /* background-color: tomato; */
}
.view_Container{
    padding: 0 ;
    /* background-color: turquoise; */
    padding-bottom: 30px;
}
.view_Top{
    padding: 20px 0; 
    /* background-color: blue; */
    display: flex;
    justify-content: space-between;
}
.view_Top_mobile{
    display: none;
}
.view_T{
    padding: 20px 0; 
    /* background-color: blue; */
    display: flex;
    justify-content: space-between;
}
.div_right{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 0px;
}
.view_Map{
width: 100%;
height: 300px;
border-radius: 10px;
background-color: #EAF4EA;
border-radius: 5px;

}
.view_Item{
    width: 100%;
    padding: 20px 0;
    /* background-color: green; */
    display: flex;
    gap: 40px;
} 
.image_Here{
    width: 300px;
    height: 200px;
    border: 1px solid gainsboro;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.imageNext{
    width: 30px;
    height: 30px;
    background-color: #EAF4EA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 45%;
}
.imageprev{
    width: 30px;
    height: 30px;
    background-color: #EAF4EA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 10px;
    top: 45%;
}
.imgicon{
   color: #50B14A
}
.image_Here img{
    width: 200px;
    max-width: 200px;
    max-height: 150px;

    border-radius: 5px;

}
._direction{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
._locality{
    display: flex;
    align-items: center;
    gap: 5px;
}
._showMobile{
    display: none;
}
._viewDetails{
    width: 100%;
    /* background-color: blue; */
}

.mobile{
    display: none;
}
.desktop1{
    display: flex;
    flex: 1;
}
.desktop2{
    display: flex;
    flex: 1;
}
.desktop3{
    display: flex;
    flex: 4;
}
.mobile1{
    display: flex;
    flex: 1;
}
.mobile2{
    display: flex;
    flex: 1;
}
.mobile3{
    display: flex;
    flex: 4;
}
.divsss{
    width: 100%;
    display: flex ;
    align-items: center;
 

}
.divsss >div{
    flex: 1;
    /* background-color: black; */
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.betqt{
    height: 100%;
    border: 1px solid #C4C4C4;
  border-radius: 6px;
   

}

.betqthead{
    width: 100%;
    /* width: 297px;
    height: 35px;
    left: 987px;
    top: 519px; */
    /* background: #E6F4E5; */
    background:   #50B14A;
   border: 1px solid #C4C4C4;
   /* box-sizing: border-box;    */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color:  #FFFFFF;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.betgrcontain{
    display: flex;
    /* width: 297px;
   height: 84.01px;
   left: 987px;
   top: 544.46px; */
   background: #F9F9F9;
   border: 1px solid #C4C4C4;
   box-sizing: border-box;
  justify-content: space-evenly;
 
}
.betgrcontainpic{
    width: 60.89px;
height: 67.89px;
left: 1000.58px;
top: 550.4px;
padding: 10px 0px 10px 10px;


}
.betgrcontaintext{
    font-weight:"500";
   font-style :"normal";
    Size :8px;
    font-size: 12px;
    line-height:  20px;
    padding: 10px 10px 10px 12px;

}

.betqprice{
    display: flex;
    font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;
font-display: center;

color: #000000; 
    width: 100%;
   height: 29.7px;
   
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  justify-content: center;
}
.betqinfo-{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    background: #F9F9F9;
    font-size: 12px;
    
/* 
    width: 100%;
   height: 100%;
   display: center;
  
   background: #F9F9F9;
   border: 1px solid #C4C4C4;
   box-sizing: border-box;
   padding-left: 60px;
   padding-top: 5px;
   font-size: 12px;
   padding-bottom: 20px;
  
  
   border-bottom-left-radius:6px;
   border-bottom-right-radius: 6px;
    */

}
.betqinfo-1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   margin-top: 20px;
   margin-bottom: 20px;
    background: #F9F9F9;
    font-size: 12px;
}
.betbt{
   
padding: 4px 4px 4px 4px;
margin-left: 30px;
background: #50B14A;
border: 1px solid #50B14A;
box-sizing: border-box;
border-radius: 2px;
/* margin-left: 20px; */
margin-top: 10px;
margin-bottom: 10px;
font-style: normal;
font-weight: bold;
font-size: 10px;
line-height: 12px;
/* identical to box height */
color: #FFFFFF;
}
.betbt:hover {
    background-color: #73af46;
  }
  .profile-btn:hover {
    background-color: #73af46;
  }
  .signup-btn:hover {
    background-color: #73af46;
  }
.AgetDetop{
    display: flex;
    justify-content: center;
    width:100%;
    height: 35px;
    left: 1051px;
    top: 548px;
     color: #FFFFFF;
     font-size: 14px;
     /* padding-left: 100px; */
     padding-top: 5px;
   /* background: #E6F4E5; */
   background: #50B14A;
   border: 1px solid #C4C4C4;
   box-sizing: border-box; 
   border-top-right-radius: 6px;
   border-top-left-radius: 6px;
   
}
.AgetDepictext{
    display:flex;
  border-left:1px solid #C4C4C4;
  border-right:1px solid #C4C4C4;
 
}
.AgetDepic{
    width: 80px;
    height: 100px;
    left: 17px;
    top: 13px;
    padding:10px 10px 10px 20px;
}
.AgetDetext{
    padding:20px 10px 10px 20px; 
    font-size: 14px;
    font-weight: 700;

}
.Ageinfo{
    border: 1px solid #C4C4C4;
    border-top: none;
    height: 60px;
    padding:10px 10px 10px 20px;
    font-size: 12px;
    color: #2E2E2E;
    font-weight: 500;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.showidbt{
   
   margin-top: 10px;
    width: 65px;
    height: 26px;
    left: 117px;
    top: 56px;
    background: #50B14A;
    border: 1px solid #50B14A;
    box-sizing: border-box;
    border-radius: 2px;
    color: #FFFFFF;
}
    .showidbt:hover{
    background-color:  #73AF46;
    }
   
    .itmclick:hover{
        background-color:  #73AF46;
        }
    .topItem-first-div-btn:hover{
        background-color:  #73AF46;
    }
    .topItem-second-div-report:hover{
        background-color:  #73AF46;
    }

.submitQoute{
    width: 200px;
    height: 40px;
    background-color: var(--main);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    border-radius: 5px;
}
.submitQoute:hover{
    background-color:  #73AF46;
}
.view_Map_mobile{
    display: none;
}
.dashboardMobile{
    display: none;
}
.desc_item{
    font-size: 22px;
}


.viewQuote{
    width: 100%;
}

.quoteTable{
    width: 100%;
}
.qudivhis{
    padding-left: 50px;
    margin-top: 30px;
    font-weight: 800;
}
.quoteHead{
    margin-top: 20px;
    padding: 15px;
    border: solid 1px #e8f7e8;
    background-color: #EAF4EA;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
}

.form_id{
    width: 200px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.form_id_label{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(128,128,128,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: white;
    text-transform: none;
    font-weight: 500;
}

.quoteBody{
    padding: 15px;
    /* border: solid 1px #e8f7e8; */
    border-top: none;
    display: flex; 
    flex-direction: column;
    gap: 15px;
}
.qProvider{
    display: flex;
}
/* .qProvider >div{
    flex: 1;
} */
.submitQoute{
    width: 300px;
    height: 40px;
    background-color: var(--main);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    border-radius: 5px;
}
.submitQoute:hover{
    background-color:  #73AF46;
}
.hide{
    display: flex;
    align-items: center;
    flex-direction: column;
    /* gap: 10px; */
}
.for_input{
    display: flex;
    flex-direction: column;
    gap:20px;
    width: 100%;
    position: relative;
    padding: 20px 0 60px 0;
    border-top: none;
    /* background-color: black; */
}
.txt_box{
    width: 100%;
    height: 40px;
    padding: 5px;
    font-size: 13px;
    border-radius: 3px;
}
.for_input input[type='text']{
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    background-color: var(--lightMain);
    color: var(--main);
    font-weight: 600;
    }
    ::placeholder {
        color: var(--main);
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: var(--main);
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
       color: var(--main);
      }
textarea {
    resize: none;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-width: 2px;
    border-bottom-style: groove;
    background-color: var(--lightMain);
    color: var(--main);
    padding: 5px;
    font-size: 13px;
    border-radius: 3px;
    font-weight: 600;
    width: 100%;
    }
textarea:focus{
    outline: none;
    border-bottom-color: var(--main);
}
.txt_box:focus {
    outline: none;
    border-bottom-color: var(--main);
    } 
.btn_{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
    font-size: 25px;
    border: none;
    cursor: pointer;
    background-color: var(--main);
    border-radius: 50%;
    font-weight: 700;

}
.btn_:hover{
    color: var(--lightMain);
    background-color: var(--lighty);

}
.message{
    width: 100%;
    padding: 20px 100px;
    height: 100%;
}
.messageBody{
    padding: 0 100px;
}
.offDesk{
    display: none;
}
.floatingSubmit, .floatingSubmit1{
    display: none;
}
.forNotActive{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 230, 230);
    color: red;
    box-shadow: 0 10px 6px -6px rgb(224, 224, 224);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    padding: 15px;
    transition: padding 0.5s ease-out;
}
.assignFulfiller{
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
} 
.assignFulfiller:hover{
    background-color: var(--lightMain);
} 
.assignFulfillerSelected{
    width: 100%;
    padding: 10px 20px;
    background-color: #5b9e56;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

}  
.assignNow{
    /* float: left; */
    padding: 7px 20px;
    width: 100px;
    /* height: 30px; */
    color: var(--lightMain);
    background-color: var(--main);
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    text-align: center;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 0;
}
.assignNow:hover{
    background-color: var(--lighty);
}
.formWidth{
    width: 30%;
}
.profoot{
    display: flex;
    justify-content:space-around;
    align-items: center;
    margin-top: 50px;
    background: #eaeaea;
    width: 100%;
    height: 46px;
    margin-top: 30px;
}
.profoot1{
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000;
    margin-left: 350px;
    font-size: 20px;
  
}
.profoot2{
    background: #50b14a;
    border-radius: 4px;
    /* width: 134px;
    height: 30px; */
    /* color: #fff; */
    border: #50b14a;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-right: 280px;
    /* padding: 10px 10px 10px 40px; */
    /* cursor: pointer; */

}
.edititemcon{
    width: 501px;
    border: 1px solid #989898;
    height: 100%;
    margin-left: 80px;
    
}
.edititem1{
    background:#f6f6f6;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 4px 4px 0px 0px;
   width: 498px;
   height: 34px;
   color:#50B14A;
   font-size: 15px;
   padding-left: 20px;
   padding-top: 10px;
  
 
}
.upitem1{
    font-size: 16px;
    padding-left: 20px;
    padding-top: 20px;
   
}
.picitem{
   
    height: 120px;
  
}
.breifdescrip{
position: absolute;
width: 20%;
height: 38px;
top: 439px;
background: #FFFFFF;
border: 1px solid #989898;
box-sizing: border-box;
border-radius: 2px;
text-align: left;
padding-left: 200px;
}
.unidem{
    padding-left: 20px;
    padding-right: 20px;
}
/* .photsize{
    width:50p;
    height:20px;
} */
/* .realphotzi{
    width:20px;
    height:20px;
} */


.radiusCard{
    width:50px; 
    height:50px; 
    border-radius:50%; 
    font-weight: 600;
    user-select: none;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c4c4c4
}
.userDesktop{
    display: block;
    width: 100%;
}
.userMobile{
    display: none;
}
.editdesktop{
    display: block;
}
.editmobile{
    display: none;
}
@media screen and (max-width: 900px){
    .cdqdiv{
        flex-direction: column;
    }


.areullog {
   
}

.quohistrydiv > div{
   padding:  0 !important;
   margin:  0 !important;
    
}


    .cdqdivinfo{
        display: block !important;
    }



    .userDesktop{
        display: none;
    }
    .userMobile{
        display: block;
    }
    .allUsers{
        flex:1;
        display:flex; 
        flex-direction:column; 
        align-items:center;
        justify-content:center;
        color: #9B9B9B; 

    }
    .allUsers .spanBadge{
        padding: 1px 7px; 
        background-color: #9B9B9B; 
        color: white ;
        border-radius:7px;
        font-size: 10px;
     }
    .activeAllUsers{
        flex:1;
        display:flex; 
        flex-direction:column; 
        align-items:center;
        justify-content:center;
        color: var(--main);
        border-bottom: 2px solid var(--main);
    }
    .activeAllUsers .spanBadge{
       padding: 1px 7px; 
       background-color: var(--main); 
       color: white ;
       border-radius:7px;
       font-size: 10px;
    }



    .backBtnMobile{
        display: block;
        width: 200px;
    }
    .formWidth{
        width: 100%;
    }
    .quote_div{
        width: 220px;
    }
    .quote_div img{
        max-width: 220px;
    }
    .view_Container{
        padding: 20px;
    }
    .view_Item{
        flex-direction: column;
        gap: 20px;
        
    }
    .cat_ID{
        display: none;
    }
    .image_Here{
        width: 100%;
    }
    .image_Here img{
        width: 250px;
        max-width: 350px;
        max-height: 180px;
        
        border-radius: 5px;
    }
    .view_Top{
        display: none;
    }
    .view_Top_mobile{
        display: block;
    }

    .view_Map_mobile{
        /* display: block; */
        border-radius: 10px;
        width: 100%;
        height: 250px;
        background-color: #EAF4EA;
        border-radius: 5px;
    }
    .view_Top_mobile .topItem{
        font-size: 11px;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
   
    .qProvider{
        flex-direction: column;
    } 
   
    ._showMobile{
        display: block;
    }
    .mobile{
        display: block;
    }
    .desktop1{
        display: none;
 
    }
    .desktop2{
        display: none;
    }
    .desktop3{
        display: none;
    }
    .divsss{
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

    }
    .submitQoute{
        width: 100%;
        margin-bottom: 10px;
    }
    .dashboardDesktop{
        display: none;
    }
    .dashboardMobile{
        height: auto;
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        background-color: whitesmoke;
    }

    .dashboardMobile .taps{
        padding: 0 10px;
        background-color: white;
        z-index: 300;
        display: flex;
        overflow-x: auto;
       align-items: center;
       gap: 30px;
       margin-top: 90px;
    }

    .dashboardMobile .tap{
        border: none;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        align-items: center;
        justify-content: center;
        cursor: pointer;
       
    }
    /* ::-webkit-scrollbar {
        display: none;
    } */

    .dashboardBody{
        width: 100%;
        padding: 0 0 50px 0;
        min-height: 100vh;
        /* background-color: wheat; */
        /* padding: 70px 0 85px 0; */
    }
    .tap span{font-size: 13px;
        font-weight: 600;
        white-space: nowrap;

        }
       

    .activeTab{
        border: none;
        border-bottom: solid 2px var(--main);
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .activeTabSender{
        border: none;
        border-bottom: solid 2px var(--main);
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 178px;
        height: 42px;
    }
    .activeTab span{font-size: 13px;
        font-weight: 600;
        white-space: nowrap;
        color: var(--main);
        }

    .tapicon{
        font-size: 25px;
        color: var(--main);
    }
    .quoteCards{
        width: 100%;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        padding: 10px 10px 20px 10px;
        background-color: white;

    }
    .quoteCardTitle{
        background-color: white;
        padding:5px 10px;
        display:flex;
        justify-content: space-between; 
        align-items:center;
        border-bottom: 2px solid var(--lightMain);
    }
    .radiusCard{
        width:35px; 
        height:35px; 
        border-radius:50%;  
        font-weight: 600;
        user-select: none;
        background-size: contain;
    background-position: center;

        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #c4c4c4
    }
    .quoteCard{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 650px;
        background-color: white;
        border-bottom: 1px solid #B0AFB0;
        /* box-shadow: 0 10px 6px -6px rgb(224, 224, 224); */
        /* border-radius: 5px; */
        margin: 0 auto;
        /* margin-bottom: 20px; */
    }
   
    .quoteBody{
        display: flex;
        flex-direction: column;
        background-color: white;
        /* padding: 0 20px; */
    }
    .quoteBodyDetails{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        /* padding: 0 20px; */
    }
    .quoteBodyDetailsLeft{
        display: flex;
        flex-direction: column;
        gap: 3px;
        justify-content: center;
        
    }
    .quoteBody_Desc{
        font-size: 15px;
        font-weight: 600;
        user-select: none;
        cursor: pointer;
        
    }
    .quoteBody_items{
        display: flex;
        gap: 10px;
        align-items: center;
    }
    
    .quoteBody_items div{
        font-size: 12px;
        font-weight: 400;
    }
    .statusCheck{
        font-weight: 500;
        font-size: 13px;
        color: #484848;
    }
    .quoteBodyDetailsRight{
        width: 60px;
        height: 60px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 2px;
        border: 1px solid whitesmoke;
    }
  
    .quoteBodyBtn{
        display: flex;
        /* padding: 0 10px; */
        gap: 10px;
        width: 100%;
        justify-content: space-between;
    }
    .quoteBtn1{
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        background-color: var(--main);
        border-radius: 2px;
        color: white;
        border: 1px solid var(--main);
        font-weight: 600;
        font-size: 12px;
        gap: 3px;
        cursor: pointer;
        position: relative;
    }
    .quoteBtn1 span{
        font-size: 12px;
    }
    .quoteBtn2{
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        background-color: white;
        border: 1px solid var(--main);
        border-radius: 2px;
        color: var(--main);
        font-weight: 600;
        font-size: 12px;
        gap: 3px;
        cursor: pointer;
        position: relative;

    }
    .quoteBtn2 span{
        font-size: 12px;
    }
    .quoteBtn3{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 8px;
        background-color: #FCECEE;
        color: red;
        font-weight: 600;
        border-radius: 2;
        font-size: 12px;
        gap: 3px;
        position: relative;
        cursor: pointer;
    }
    .quoteBtn3 span{
        font-size: 7px;
    }
    .quoteBtn1:hover{
        background-color: var(--lighty); ;
    }
    .quoteBtn2:hover{
        background-color: var(--lighest); ;
    }
    .quoteBtn3:hover{
        color: #FCECEE;
        background-color: red; ;
    }
    .badge{
        width: 12px;
        height: 12px;
        background-color: red;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 8px;
        font-weight: 600;
        position: absolute;
        top: -4px;
        right: 4px;
    }
    
    ._locality{
        font-size: 12px;
        font-weight: 500;
    }
    .desc_item{
        font-size: 16px;
    }
    .submitQoute{
       display: none;
    } 
     .hide{
        display: none;
    }
    .floatingSubmit{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        flex-direction: column;
        position: fixed;
        bottom: 30px;
        right: 30px;
        border-radius: 50%;
        background-color: var(--main);
        color: var(--lighest);
        font-size: 10px;
        font-weight: 600;
        /* padding: 8px 15px; */
        z-index: 10;
        cursor: pointer;
        box-shadow: 0 5px 16px -6px rgb(192, 192, 192);
    }
    .floatingSubmit1{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        flex-direction: column;
        position: fixed;
        bottom: 30px;
        right: 90px;
        border-radius: 50%;
        background-color: var(--main);
        color: var(--lighest);
        font-size: 10px;
        font-weight: 600;
        /* padding: 8px 15px; */
        z-index: 10;
        cursor: pointer;
        box-shadow: 0 5px 16px -6px rgb(192, 192, 192);
    }
    .floatingSubmit:hover{
        background-color: var(--lighest);
        color: var(--main);
    }
    .mobile1{
        font-size: 13px;
        font-weight: 600;
    }
    .mobile2{
        font-size: 11px;
        font-weight: 500;
    }
    .mobile3{
        font-size: 10px;
        font-weight: 500;
    }
    .offDesk{
        display: block;
    }
    .assignClick{
        padding:15px 0;
        color: var(--main);
        border-bottom: solid 1px var(--main) ;
    }
    .unassignedClick{
        padding:15px 0;
        color: var(--darkest);
        border:0 ;
    }
    .content-icon{
        /* display: none; */
    }
    .editdesktop{
        display: none;
    }
    .editmobile{
        display: block;
    }
    
}

@media screen and (max-width: 600px){
    .infoDetails22{
        flex-direction: column;
    }

}


















@media screen and (max-width: 731px) and (min-width: 411px) {
  
    .cdqdivpict{
        padding-top: 0px;
    }
    .betqthead{
        width: 100%;
        
    }
    .betqt{
        margin-left: 0px;
    }
    .betgrcontain{
        width: 100%;
    }
    .betqprice{
        width: 100%;
    }
    .betqinfo{
        width: 100%;

    }
    .AgetDetop{
        width: 100%;

    }
    
    .Ageinfo{
        width: 100%;
    }
    .AgetDepictext{
        width: 100%;
    }

}
@media screen and (max-width: 568px) and (min-width: 320px) {
    .cdqdivpict{
        padding-top: 0px;
    }
    .betqthead{
        width: 100%;
    }
    .betqt{
        margin-left: 0px;
    }
    .betgrcontain{
        width: 100%;
    }
    .betqprice{
        width: 100%;
    }
    .betqinfo{
        width: 100%;

    }
    .AgetDe{
        margin-left: 0px;
    }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {

    .betqthead{
        width: 100%;
        
    }
    .betgrcontain{
        width: 100%;
    }
    .betqprice{
        width: 100%;
       
    }
    .betqinfo{
        width: 100%;
       

    }
    .AgetDe{
        margin-left: 0px;
    width: 100%;
     margin-top: 30px;
  
    }
    
}

@media screen and (max-width: 1366px) and (min-width: 1024px) {

    .view_Container{
        width: 100%;
    }
    
    .betqt{
        margin-left: 0px;
        width: 100%;
    }
  
    .betqthead{
        width: 100%;
       padding-left: 50px;
       
        
    }

    .AgetDe{
        margin-top: 0px;
        margin-left: 0px;
    }
    .betgrcontain{
        width: 100%;
    }
    .betqprice{
        width: 100%;;
        padding-left: 50px;
    }
    .betqinfo{
        width: 100%;
    }
    
    .bodyItem-{
        display: block;
        margin-left: 100%;
     
    }
    .right-content-information{
        width: 100%;
        margin-top: -0px;
        
    }
    .AgetDe{
        width: 100%;
   
    }
    .AgetDetop{
        width: 100%;
    }
    .Ageinfo{
        width: 100%;
    }
    .AgetDepictext{
        width: 100%;
    }
    
}
    
@media screen and (max-width: 653px) and (min-width: 280px) {

    .betqthead{
        width: 100%;
        
    }
    .AgetDe{
        margin-left: 0px;
    }
    .betgrcontain{
        width: 100%;
    }
    .betqprice{
        width: 100%;
    }
    .betqinfo{
        width: 100%;
    padding: 0;

    }
    .form_id{
        width: 200px;
        height: 150px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    
}
@media screen and (max-width: 1280px) and (min-width: 800px) {

    .left-content-carousel {
        display: block;
      
    }
    .AgetDe{
        margin-left: 0px;
    }
    .right-content-information{
        width: 100%;
    }
    .AgetDetop{
        width: 100%;
    }
    .Ageinfo{
        width: 100%;
    }
    .AgetDepictext{
        width: 100%;
    }
    
   
}



@media screen and (max-width: 640px){

    .dashboard{
        height: 900px;
      /* background: #50b14a !important; */
    }
    
    }

 
