.ProviderHero{
    width: 100%;
}
.heroTitles{
    text-align: center;
    color: var(--textColor); 
    font-size: 40px;
    font-weight: 500;
    padding: 60px;
 }
.ProviderHeroCont{
    display: flex;
    width: 100%;
    /* padding: 0 100px; */
    padding-bottom: 60px;

}
@media screen and ( max-width : 900px) {
    .heroTitles{
        font-size: 30px;
        padding: 40px 0;
     }
    .ProviderHeroCont{
        padding: 20px;
    }
    
}
