/* .sidebardiv{
    background: #E0FCE7;
    margin-top: 40px;
    height: 100vh;
} */


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


/* .dashboard{
    background: #E6F4E5 !important;
    height: 61px !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
font-size: 24px;
line-height: 28px;

color: #50B14A;

} */


.mybtn{
    position: absolute;
    bottom: 120px;
    padding: 20px;
} 

.mybtn > .btn-{
    background: var(--Dillivry-Green);
    font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
line-height: 19px;
text-transform: none;
}

.mybtn > .btn-:hover{
    background: var(--Dillivry-Green);
    font-size: 16px;
line-height: 19px;
}

.css-1kklrhw-MuiList-root{
    padding-top: 0px !important;
}


.image-content{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 10px 5px 10px;
}
.image-contentx{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 5px 10px 5px 10px;

}


.sidemenudetails{
    position: fixed;
    height: calc(100% - 65px);
    overflow-y: scroll;
    width: 320px;
    background: #E0FCE7;
    z-index: 10 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* min-height: 1000px; */
}






.bottom-logo{
    position: fixed;
    bottom: 0px;
    padding: 20px;
    display: flex;
    gap: 20px;
    width: 320px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}


.active4{
    font-size: 13px;
    background-color: #50B14A;
    color: white;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 10px;
    width: 120px;
    text-align: center;
    user-select: none;
}

/* .image-content > img > p{
    display: none;
} */
@media (min-width:600px)
{.css-hyum1k-MuiToolbar-root
    {min-height:60px !important ;}
}