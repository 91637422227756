.How_it_works{
    width: 100%;
    background: var(--heroBG);
    padding-top: 90px;
  }
.How_it_works_cont{
    width: 100%;
    padding: 50px 250px;
}
.How_it_works_cont h1{
    font-family: Roboto;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: var(--textColor);
    text-align: center;
    margin-bottom: 50px;
}
.work_cards{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.work_card{
    width: 100%;
    display: flex;
}
.work_card_side{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.work_card_side img{
    width: 180px;
}
.work_card_side1{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.work_card_side1 img{
    width: 180px;
}
.work_card_other_side{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: var(--textColor);
}
.work_card_other_side h2{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}
.work_card_other_side p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.arrow_image{
    display: flex;
    align-items: center;
    justify-content: center ;
}
.arrow_image img{
    width: 90px;
}
.spanContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}
.spanBody{
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.spanText{
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    padding: 10px 20px;
    border-bottom: 2px solid var(--lighest);
    user-select: none;
    cursor: pointer;
    border-radius: 2px;
}
@media screen and (max-width : 900px) {
    .How_it_works_cont{
        width: 100%;
        padding: 20px;
    }
    .How_it_works_cont h1{
        font-size: 28px;
        font-weight: 600;
        line-height: 30px;
        color: var(--textColor);
        margin-bottom: 30px;
    }

    .spanContainer{
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 50px;
    }
    .spanBody{
        width: 100%;
    }
    .spanText{
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        padding: 5px 20px;
    }
    .work_card{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .work_card_side{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .work_card_side img{
        width: 180px;
    }
    .work_card_side1{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .work_card_side1 img{
        width: 180px;
    }
    .work_card_other_side{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: var(--textColor);
    }
    .work_card_other_side h2{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    .work_card_other_side p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    .arrow_image{
        margin-bottom: 20px;
    }

}