.comp_input{
    outline: none;
    border: none;
    width: 100%;
    border-radius: 30px;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 300;
}

.comp_input::placeholder {
    color:#B0AFB0;
    opacity: 1; /* Firefox */
  }

.comp_btn{
    outline: none;
    border: none;
    width: 100%;
    border-radius: 20px;
    padding: 15px 20px;
    background-color: var(--main);
    color: white;
    font-weight: 600;
    font-size: 18px;
    margin-top: 25px;
    user-select: none;
    cursor: pointer;
}
.registrationV2{
    width: 100%;
    min-height: 100vh;
    height: auto;
    padding: 30px 100px;
    position: relative;
}
.regHead{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin-bottom: 20px;
}
.regHead h3{
    font-size: 18px;
    font-weight: 400;
}
.regPages{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.leftPage{
    padding: 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;

}
.playerCont{
    margin-top: 60px;
    width: 400px;
    height: 250px;
    
}
.react-player{
    border-radius: 20px;
}
.pageTagv2{
 font-size: 24px;
 color: var(--textColor);
 line-height: 32px;
 font-weight: 500;
}
.pageSubtagev2{
    font-size: 16px;
    color: var(--textColor);
}
.rightPage{
    display: flex;
    flex: 1;
}

.registration{
    width: 100%;
    height: 100vh;
    display: flex;
    /* justify-content: center; */
    padding: 50px 100px;
    background: linear-gradient(180deg, rgb(245, 245, 245), rgb(245, 245, 245),rgb(245, 245, 245),rgba(144, 198, 149, 1));   
}
.afterReg{
    display: flex;
    gap: 20px;
    width: 70%;
}
.formSide{
    width: 65%;
    display: flex;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 30px 0;

}
.regCont{
    overflow: scroll;
    width: 100%;
    max-width: 450px;
    height: auto;
    position: relative;
    padding: 30px 30px 0 30px;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: white;
}

.regContV2{
    width: 100%;
    max-width: 450px;
    height: auto;
    position: relative;
    padding: 30px 30px 0 30px;
    background-color: white;
}

.pageTitle{
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.pageTag{
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 15px;
}
.pageSubtage{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.btnDeskto{
    width: 100% ;
    margin-top: 30px;
}
.btnMobil{
    display: none;
}
.btnMobilAfter{
    display: none;
}
.btnMobil2{
    display: none;
}

.logoshower{
    display: none;
}

.btnCaptureDesktop{
    width:100%;
    padding:150px 0 70px 0;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
}
.btnCaptureMobile{
    display: none;
}
.storeLogo{
    height: 50px;
    cursor: pointer;
}
.storeLogo:hover{
    transform: scale(1.05);
}
.selectqb{
    display: flex;
}
.selectqbutton{
    padding: 5px 5px 5px 5px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}
.selectqbutton:hover{
    color:rgb(74, 141, 7);
}
.topLeft{
    position: absolute;
    top: 0;
    left: 0;
}
.topRight{
    position: absolute;
    top: 0;
    right: 0;
}
.bottomLeft{
    position: absolute;
    bottom: 0;
    left: 0;
}
.bottomLeft img{
    transform: rotate(180deg);
}
.bottomRight{
    position: absolute;
    bottom: 0;
    right: 0;
}

  .AgreeCheckboxContainer {
    position: relative;
    padding-right: 10%;
    margin-right: 10%;
  }
  .AgreeCheckbox {
      /* height: 20px;
      width: 20px; */
    position: absolute;
    top: -5px;
    left: 0;
    outline: 0px;
    filter: hue-rotate(600deg);
  }
 

.input-referall::placeholder{
    color: #B0AFB0;
}
.input-referall{
    outline: 0;
    border: 1px solid rgb(201, 201, 201);
    border-radius: 7px;
    padding: 8px 10.5px;
}


@media screen and (max-width: 900px) {
    .registration{
        padding: 40px 20px;
        background: none;
    }
    .btnDeskto{
        display: none;
    }
    .btnMobil{
        display: block;
        width:100%;
        position:fixed; 
        padding: 0 20px ;
        bottom:20px;  
    } 
    .btnMobilAfter{
        margin-top: 30px;
        display: block;
        width:100%;
    }
    .btnMobil2{
        display: block;
        width:100%;
        position:fixed; 
        padding: 0 40px 0 0;
        bottom:20px;  
    }
    .btnCaptureDesktop{
        display: none;
    }
    .btnCaptureMobile{
        position:fixed; 
        width:100%;
        bottom:70px;  
        padding:0 40px 0 0; 
        display:flex; 
        justify-content :space-evenly;
        align-items :center;
    }
    .pageTitle{
        display: none;
    }
    .formSide{
        width: 100%;
        display: block;
        padding: 0;

    }
    .regCont{
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px;
        border: none;
        box-shadow: none;
        
    }
    .afterReg{
        gap:0;
        width: 100%;
    }
    .storeLogo{
        height: 40px;
    }
    .topDesktop{
        display: none;
    }
    .registrationV2{
        padding: 30px;
        overflow-x: hidden;
    }

    .leftPage{
        display: none;
    }
    .rightPage{
        width: 100%;
        justify-content: center;
    }
    .regHead{
        display: none;
    }
    .regContV2{
        padding: 0;
    }
    .logoshower{
        display:flex; 
        justify-content: center; 
        align-items: center;
        margin-bottom:30px;
        flex-direction: column;
    }
    .bottomLeft img{
        width: 150px;
    }
    .topRight img{
        width: 150px;
    }

   
}

@media screen and (max-width: 395px) {

    .above18{
      /* display: none !important; */
      flex-direction: column !important;
      align-items: flex-start !important;
    }
}

