/* @media only screen and (max-width: 1069px) and (min-width: 901px) { */
/* .viewSearch {display: none;} */
/* .left-content-carousel{
    flex-direction: column;
  }

  .submit-quote-content{
    margin-left: 0px !important;
  }

}

@media only screen and (max-width: 1026px) and (min-width: 901px) {
  .right-header-input{
    width: auto !important;
  height: 40px;
  margin-right: 14px;
  }
  
} */

.Enquiry-div-message{
  /* margin-top: 20px; */
}
.iner-flex>div>.span-content{
margin-right: 10px;

}

.enquiry-header{
  background: #E0FCE7;
border-radius: 5px 5px 0px 0px;
padding: 10px 20px 10px 20px;
margin-bottom: 10px;
}

.enquiry-header > h4{
  font-family: "Roboto", sans-serif;
  font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
/* identical to box height */
color: black;

}

.Enquiry-div-message > div > p{
  /* padding: 10px 2px 10px 20px; */
  margin: 20px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
/* font-weight: bold; */
font-size: 14px;
line-height: 16px;

/* text */

color: #444343;
}

.flex-btn{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.Enquiry-div-message:nth-child(even){
  border: 1px solid #50B14A;

/* opacity: 0.5; */
box-sizing: border-box;
}
.Enquiry-div-message:nth-child(odd){
  border: 1px solid #50B14A;
  background: #E6F4E5;
}

.quote-content-div > p{
  padding: 15px 20px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
/* font-weight: bold; */
font-size: 14px;
}

.quote-content-div {
  border: 1px solid #50B14A;
  box-sizing: border-box;
  background: #FFFFFF;
}



.content-iconn {
  width: 100%;
  padding: 0;
  border: none;
}
.topTitle {
  width: 100%;
  background-color: var(--main);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid var(--main);
  padding: 5px 10px;
  color: white;
}
.itemDescs {
  width: 100%;
}
.itemDesc {
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-top: 0;
  display: flex;
  gap: 15px;
  font-size: 12px;
}

.header-body- {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(130, 130, 130, 0.25);
  height: 80px;
}

.NavHeader {
  box-shadow: 0px 2px 8px rgb(130 130 130 / 25%) !important;
}

.viewSearch .view_Top_mobile {
  display: block;

  /* margin-top: 30px; */
}
.viewSearch .view_Container {
  margin: 0 auto;
  max-width: 70%;
  margin-top: 30px;
}

.viewSearch .view_Top_mobile .topItem {
  font-size: 11px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topItem-first-div > button > span {
  font-family: "Roboto", sans-serif;
  width: 40px;
  height: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* background: #50b14a; */
  color: #ffffff;
}

.topItem-second-div-watch {
  margin-left: 10px;
}

.topItem-second-div-watch > span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  color: #50b14a;
}

.topItem-first-div > button {
  width: 40px;
  height: 25px;
  background: #50b14a;
  border-radius: 2px;
  border: #50b14a;
  cursor: pointer;
  padding-bottom: 3px;
}

.topItem-second-div-report > span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  color: #ffffff;
}

.topItem-first-div-category,
.topItem-first-div-item-category {
  width: 122px;
  height: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

div .topItem-second-div-Id,
.topItem-second-div-item-category {
  height: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
  margin-right: 10px;
}

.topItem-second-div {
  display: flex;
  align-items: center;
}

.viewfulfiler-btn {
  height: 28px !important;
  padding-top: 5px;
  margin-right: 5px;
}

.topItem-second-div-watch {
  width: 56px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #50b14a;
  box-sizing: border-box;
  border-radius: 2px;

  margin-right: 20px;
  cursor: pointer;
}

.topItem-second-div-report {
  width: 56px;
  height: 28px;
  background: #50b14a;
  border-radius: 2px;
  border: #50b14a;

  cursor: pointer;
  color: #ffffff;
}

.view_Map {
  margin-top: 12px;
  border-radius: 8px;
  /* padding: 0 50px 0 50px; */
  background-color: #ffffff !important;
}

/* .bodyItem  > div ~ div  + div{
    display: none;
  } */

.submit-quote-content {
  /* background: #ffffff; */
  /* border: 1px solid #eaeaea; */
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;
  /* margin-left: 50px; */
  /* height: 142px; */
  /* width: 354px !important; */
}

div > .label-quote {
  width: 89.7px;
  height: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-right: 28px;
  color: #2e2e2e;
}

div .input-quote {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  height: 29px;
  width: 202px;
  margin-top: 10px;
}

div .input-quote::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  color: #c4c4c4;
  text-indent: 10px;
}

.nrn-sign {
  margin-top: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-left: 235px;
  color: #000000;
}

.parent-div {
  width: 200px;
  height: 200px;
  background-color: aqua;
}

.div-3 {
  background-color: bisque;
  height: 200px;
}
.itemDescs- {
  display: flex;
  /* margin-bottom: 10px; */
  /* border: 0.5px solid #E0E0E0; */
  border-bottom: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  padding: 5px;
}

.icn-two-,
.icn-one- {
  max-width: 200px;
  margin-right: 20px;
}

.icn3-,
.icn4-,
.icn5-,
.icn6- {
  max-width: 200px;
  margin-right: 14px;
}

.hide {
  display: none;
}

.to-be-flex {
  display: flex;
  /* width: 50%; */
}

.to-be-flex > div > span {
  /* width: 25%; */
  /* padding-right: 35px;
  padding-bottom: 10px; */
}

.itemThree,
.itemFour {
  margin-bottom: 20px;
}

.itemDescs-desc {
  background: #ffffff;

  box-sizing: border-box;
}

.itemDescs- > span > img {
  margin-right: 20px;
}

.left-content-carousel {
  display: flex;
}

.bodyItem-- {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  gap: 50px;

  flex-direction: row;
  flex-wrap: wrap;
}

.carousel_image {
  /* width: 222.84px !important;
    max-height: 207px !important;
    height: 207px !important; */
  /* margin-right: 50px; */
  width: 222.84px !important;
  max-height: auto !important;
}

/* .carousel > .Carousel-root-11 > .CarouselItem > div{
    display:  none !important;
  }
   */

.item-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-family: 18px;
  line-height: 21px;
  margin-bottom: 12px;
  /* background: #50B14A;
  border-radius: 8px 8px 0px 0px; */
}

.parent-send-div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.Enquiry-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  font-size: 13px;
  color: #000000;
}

.btn-send {
  background: #50b14a;
  border: 1px solid #50b14a;
  box-sizing: border-box;
  border-radius: 2px;
  width: 45px;
  height: 20px;
  color: #ffffff;
}

.enquiry-body {
  width: 360px;
  height: 224px;
  padding: 20px;
}

.dilivry-info {
  /* max-width: 80%;
    margin-left: 20px; */
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
  padding: 10px;
  background: #50b14a;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;

  /* background: #EAEAEA;
    border-radius: 4px 4px 0px 0px;
    padding: 10px 50px 10px 10px; */
}

.icon-desc {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #2e2e2e;
}
.icon-desc span {
  font-weight: 500;
}

.quote-div {
  background: #50b14a;
  /* border-radius: 4px 4px 0px 0px; */
  border-radius: 8px 8px 0px 0px;
  padding: 10px 50px 10px 10px;
  /* width: 354px; */
}

.quote-div > h1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* 
  color: #2E2E2E; */
  /* width: 117px; */
  height: 16px;
  color: #ffffff;
}

.current-quote-body > form {
  width: 100% !important;
  padding: 0 10px 0 10px;
}

.Quote-Title-message {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;

  color: #000000;
}

.ques- {
  margin-right: 30px;
}

.right-content-information- {
  /* width: 336px;
    height: 257px; */
  /* padding-bottom: 20px; */
  flex: 3;
  width: 336px;
  height: 257px;
}

.current-quote-body {
  /* height: 387px; */
  /* width: 336px; */
  background: #ffffff;
  border: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  /* padding: 10px; */
  /* padding-bottom: 10px; */
}

/* .itemDescs-desc > div:nth-child(7){
    padding-bottom: 20px;
  } */

.current-quote-body > .Quote-Title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  padding: 0 10px 0 10px;
  color: #2e2e2e;
}

.quote-amount {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-weight: normal;
  font-size: 12px;
  color: #2e2e2e;
}

.quoteinputcontent {
  width: 151px;
  height: 26px;
  padding: 0 10px;

  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.btn-quote-form {
  background: #50b14a;
  border: 1px solid #50b14a;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  width: 93px;
  height: 30px;
  margin-left: 30px;
  /* margin-top: 10px;
    margin-right: 8px; */
}

.submit-quote- {
  height: 30px;
  background: #50b14a;
  border-radius: 4px;
  border: #50b14a;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-family: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  width: 109px;
  cursor: pointer;
}

.provider-history-div {
  /* padding: 0 50px; */
  /* margin-top: 55px; */
  /* margin-top: 185px; */
  margin-top: 20px;
  /* padding: 30px; */
}

.table_ > tr {
  height: 63px;
}

.table_ > tr:nth-child(odd) {
  /* background: #f7fff7;
    border: 1px solid #f7fff7;
    box-sizing: border-box;
     background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 8px 8px 0px 0px !important; */
  /* border: 1px solid #f7fff7; */
  /* box-sizing: border-box; */
  background: #ffffff;
  height: 48px;
}

.table_ > tr:nth-child(even) {
  background: #ffffff;
  border: 1px solid #ffffff;
  background: #f7fff7;
  box-sizing: border-box;
  /* border-bottom: 1px solid #c4c4c4; */
  /* border: 1px solid #c4c4c4; */
  height: 58px;
}

.profile-btn {
  width: 89px;
  height: 30px;
  background: #50b14a;
  border-radius: 4px;
  border: #50b14a;
  /* margin-right: 70px; */
  cursor: pointer;
  border-radius: 2px;
}

.profile-btn > span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}

.item-content-logo {
  width: 149.03px;
  height: 42px;
}

.item-header-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 69px 0 69px;
}

.right-header-view {
  padding-left: 50px;
}

.right-header-input {
  width: 542px;
  height: 30px;
  margin-right: 14px;
}

.right-header-input::placeholder {
  text-indent: 10px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #636363;
}

.right-header-button {
  width: 90px;
  height: 30px;
  background: #50b14a;
  border-radius: 4px;
  color: #ffffff;
  border: #50b14a;
  cursor: pointer;
  border-radius: 2px;
}

.right-header-button-span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.table-tr-th > tr > th {
  background: #50B14A !important;
  padding: 15px 0 15px 10px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: left;
}

.table-tr-th > tr > {
  background-color: #50b14a !important;
  /* padding-left: 40px !important; */
}

.table-tr-th > tr > td {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  width: 230px;
  font-size: 14px;
}

.table-tr-th > tr > td:nth-child(2) {
  color: #50b14a;
}

.table-tr-th > tr > td > button {
  width: 89px;
  height: 30px;
  background: #50b14a;
  border-radius: 4px;
  border: #50b14a;
  margin-right: 70px;
  cursor: pointer;
  border-radius: 2px;
}

.provider-history {
  padding: 0 0 0 10px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  font-family: "Roboto", sans-serif;
  color: #000000;
  margin-bottom: 20px;
}

.hide-declined {
  padding: 0 0 0 69px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;

  color: #50b14a;
  margin-top: 12px;
  cursor: pointer;
}

.logistics-content- {
  display: flex;
  /* justify-content: space-between; */
  /* padding-right: 220px; */
  background: #eaeaea;
  /* margin-top: 30px;  
   padding-top: 10px;
   padding-bottom: 10px; */
}

.logistics-content-div {
  display: flex;
}

.logistics-tag {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  /* width: 307px; */
  height: 28px;
  /* margin: 0 420px 0 0px !important */
}

.sh-btn {
  width: 103px;
  height: 31px;
  background: #50b14a;
  border-radius: 4px;
  border: #50b14a;
  margin-left: 340px !important;
  margin-right: 20px;
  cursor: pointer;
}

.lgt-btn > span {
  margin-right: 220px;
  width: 134px;
  height: 42px;
}

.sh-btn > span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}

/* .lgt-btn{
    font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  
  color: #FFFFFF;
  margin-left: 220px;
  }
  */

.a {
  /* width: 1440px; */
  height: 46px;
  /* left: 5px;
  top: 1408px; */

  background: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* 
  
  align-self: center;
   */

  /* padding-left: 350px; */
  /* padding-right: 110px; */
  margin-top: 50px;
}

.a2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;

  color: #000000;

  height: 28px;
}

.a3 > button {
  width: 103px;
  height: 31px;

  background: #50b14a;
  border-radius: 4px;
  border: #50b14a;
  cursor: pointer;
}

.a3 > button > span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}

.new-tablehead {
  display: flex;
}

.new-tablehead > div {
  flex: 1;
  padding-left: 60px;
  background: #eaeaea;
  height: 60px;
  padding-top: 28px;
}

.div-1-,
.div-2-,
.div-3- {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  width: 113px;
  height: 16px;
  color: #000000;
}

.footerover-ride > .footercontainer {
  padding-left: 0px;
  padding-right: 0px;
}

.quote-info-- {
  display: flex;
  justify-content: space-between;
  /* padding-left: 10px; */
  /* border: 0.5px solid #E0E0E0; */
  border-bottom: 0.5px solid #e0e0e0;
  padding: 8px;
}

.enquiry-mssg {
  background: #50b14a;
  border: 1 px solid #50b14a;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  width: 93px;
  height: 30px;
  /* margin-top: 10px; */
  margin-right: 8px;
  cursor: pointer;
}

.enquiry-submit {
  display: flex;
  justify-content: right;
  padding: 8px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.enquiry-mssg > p {
  margin-top: 8px;
  margin-left: 8px;
  cursor: pointer;
}

.parent-submit-quote-enquiry-msg {
  display: flex;
}

.main-fulfiler-page {
  margin-top: 40px;
  display: flex;
  gap: 40px;
}

.imgage-content {
  flex: 3;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 310px;
  border: 1px solid #eaeaea;
}

.carousel_image- {
  max-height: 280px;
  max-width: 280px;
}
.dillivry-info {
  flex: 4;
  width: 100%;
}

.viewSearch .submitQuot .bodyItem .itemDescs {
  font-size: 12px;
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 10px;
}
.viewSearch .submitQuot .bodyItem .itemDescs .itemDesc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
}
.viewSearch .submitQuot .bodyItem .qH {
  padding: 10px;
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  height: 310px;
}

.carousel_image- {
  max-height: 280px;
}

.dillivry-info {
  flex: 4;
  width: 100%;
}

.quote-info {
  flex: 3;
  /* width: 380px; */
  width: 100%;
}
/* } */
@media screen and (max-width: 920px) {
  .iner-flex> div >.span-content{
    margin-right: 5px;
    }

}

@media screen and (max-width: 900px) {
  .viewSearch .view_Container {
    width: 100%;
    max-width: 100%;
      margin-top: 0px;
  }
  .enquiry-body{
    width: 100%;
  }


  /* .enquiry-mssg > p {
    margin-top: 8px;
    margin-left: 8px;
    cursor: pointer;
  } */

  .enquiry-mssg > p {
    /* margin-top: 0px !important; */
    text-align: center;
    margin-left: 0px;
  }

  .btn-quote-form{
    margin-left: 0px;
    margin-right: 0px;
  }
}
