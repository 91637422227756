.footerBtns{
    display: none;
}

@media screen and (max-width: 900px){
.footerBtns{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    background-color: white;
    box-shadow: 6px -1px 6px 2px rgb(243, 243, 243);
    z-index: 10;

}
.footerBtn{
    flex: 1;
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
}
.activeBtn{
    flex: 1;
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    color: var(--main);
}
.footerBtn span{font-size: 10px;
    font-weight: 600;
}
.activeBtn span{font-size: 10px;
    font-weight: 600;
}
}