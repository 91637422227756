.select_country_container {
  width: 100%;
  border-radius: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  border: 1px solid #C4C4C4;

}
.select_country {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* padding: 10px; */
  height: 100% ;
  border-right: 1px solid #C4C4C4;
}
.select_options_cont{
  padding: 10px 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 20;
  background-color: white;
  box-shadow: 5px 10px 18px #C4C4C4;
  max-height: 400px;
  overflow: scroll;
}

.select_options{
  font-size: medium;
  padding: 7px 12px;
  user-select: none;
  cursor: pointer;

}
.select_options:hover{
  background-color: var(--lighest);
}

.search_country{
  padding: 10px;
  width: 100%;

}
.search_country input{
  width: 100%;
padding: 5px 10px;
outline: none;
border: 1px solid #C4C4C4;
border-radius: 20px;
font-size: medium;
}
.search_country input::placeholder {
  color: #666666;

}

.flag_house{
  margin: 0;
  padding: 0;
}
.flag_house img{
  height: 20px;
  border-radius: 3px;
  /* border: 1px solid#C4C4C4; */
}
.country_code{
  font-size: medium;
}

.phone_num_input{
  width: calc(100% - 200px);
  border-radius: 20px;
  background-color: transparent;
  height: 100%;
  border: none;
  outline: none;
  font-size: medium;
  letter-spacing: 2px;
}
.phone_num_input::placeholder {
  color: #666666;
}

.phone_num_input::-webkit-outer-spin-button,
.phone_num_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.phone_num_input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 900px) {
  /* .select_country_container {
    height: 40px;
  } */
  .select_country {
    width: 70px;
    gap: 7px;
  }
  .flag_house img{
    height: 15px;
  }
  .chevron_icon{
    size: 10px;
  }

}