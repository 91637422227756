
.userView{
    width: 100%;
    /* height: 100%; */
    background-color: white;
    padding: 5px;
}
.userCards{
    width: 100%;
    /* height: 100%; */
    border: solid 1px whitesmoke;
}
.userCardsTop{
    width: 100%;
    padding: 8px;
    background-color: var(--main);
    color: white;
}
.userCardsBody{
    width: 100%;
    display: flex;

}
.user_container{
    width: 100%;
    max-width: 400px;
}
.userCardsImage{
    width:100px; 
    height:100px; 
    user-select: none;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c4c4c4;
    border-radius: 5px;
}
.userCardsDetailsss{
    width: 40%;
}
.userCardsDetails{
    width: 100%;
    padding: 8px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 15px;
}
.userCardsDetail{
    display: flex;
    align-items: center;
    width: 100%;
}
.userCardsButtons{
    user-select: 'none';
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    font-size: 15px;
    flex-direction: column;
}
.delBtn{
    color: white;
    background-color: var(--main);
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 3px;
    padding: 3px;
    width: 100px;
    justify-content: center;
    cursor: pointer;
}
.disBtn{
    color: var(--main);
    border: var(--main) 1px solid;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 3px;
    padding: 3px;
    width: 100px;
    justify-content: center;
    cursor: pointer;
}
.ediBtn{
    color: var(--main);
    border: var(--main) 1px solid;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 3px;
    padding: 3px;
    width: 100px;
    justify-content: center;
    cursor: pointer;
}
@media screen and (max-width: 900px) {
.userCardsBody{
    flex-direction: column;
}
.userCardsDetailsss{
    width: 100%;
}
.userView{
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 5px;
}
.userCards{
    width: 100%;
    height: 100%;
    border: solid 1px whitesmoke;
}
.userCardsTop{
    width: 100%;
    padding: 8px;
    background-color: var(--main);
    color: white;
}
.userCardsImage{
    width:100px; 
    height:100px; 
    user-select: none;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c4c4c4;
    border-radius: 5px;
}
.userCardsDetails{
    width: 100%;
    padding: 8px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 13px;
}
.userCardsDetail{
    display: flex;
    align-items: center;
    width: 100%;
}
.userCardsButtons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 13px;
    flex-direction: row;
}
.delBtn{
    padding: 3px 10px;
    color: white;
    background-color: var(--main);
    display: flex;
    align-items: center;
    gap: 5px;
    width: auto;
    border-radius: 3px;
}
.disBtn{
    padding: 3px 10px;
    color: var(--main);
    border: var(--main) 1px solid;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 3px;
    width: auto;

}
.ediBtn{
    padding: 3px 10px;
    color: var(--main);
    border: var(--main) 1px solid;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 3px;
    width: auto;

}
}