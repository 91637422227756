
.googlecontainer{
    padding:30px 120px;
    width: 100%;
    background-color: var(--main);
    position: relative;
    z-index: 1;
}
.elipse11{
    position: absolute;
    top: 0;
    right: 50%;
}

.circle{
    position: absolute;
    width: 368px;
    height: 368px;
    right: 11%;
    bottom: 0;
    background: #61CA5A;
    clip-path: circle(50% at 50% 72%);
}
.googlegrid{
   display: flex;
   /* gap: 30px; */
   justify-content: space-between;
}

.googletextcontainer{
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-top: 20px;
    width: 50%;
}

.textcont{
    z-index: 1;
}

.googletextcontainer p{
    color: white;
    font-size: 20px;
    /* background: aqua; */
    line-height: 30px;
    font-weight: 300;
}

.googletextcontainer h4{
    color: white;
    font-size: 40px;
}

.googleimagecontainer{
    display: flex;
    gap: 5px;
    z-index: 1;
    width: 270px;
    /* background: red; */
}

.gimagecontainer img{
    border-radius: 10px;
}

.pimagecontainer{
    position: relative;
    display: flex;
    /* align-items:center; */
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    width: 429px;
    height: 380px;
    /* padding-bottom: 200px; */
}

.pimagecontainer img{
    /* height: 50%;  */
    height: 420px;
   
}


@media screen and (max-width: 900px) {

    .googlecontainer{
        /* width: 100vw; */
        height: auto;
        width: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px 0;

    }
    
    .googlegrid{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 0;
        text-align: center;
        padding: 0;
        margin: 0;
    }
    
    .googletextcontainer{
        /* background: red; */
        width: 100%;
    }
    
    .googleimagecontainer{
        /* background: yellow; */
        display: flex;
        margin: 0 auto;
        /* margin-left: 15px; */
    }
    
    .gimagecontainer img{
       width: 120px;
    }
    
    .pimagecontainer{
        /* background: yellow; */
        width: 100%;
        padding: 30px;
    }
    
    .pimagecontainer img{
        height: 300px;
    }
    .elipse11{
       display: none;
    }
    .circle{
        display: none;
    }
    .googletextcontainer h4{
        font-size: 25px;
    }
}

@media screen and (max-width: 400px){
    
    .gimagecontainer img{
        width: 100px;
    }

    .googleimagecontainer{
        justify-content: center;
    }
    
}