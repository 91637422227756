
.getstartedCont{
  width: 100%;
  background-color: var(--heroBG);
  padding-top: 70px;
  height: 750px;
  display: flex;
  align-items: center;
}
.getStartedBody{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.heroFormCont{
  width: 100%;
  max-width: 500px;
}

/* get started form style start here */

.CustomInput_container{
  width: 100%;
  position: relative;
}
.CustomInput_input{
  width: 100%;
  /* padding: 10px 20px; */
  border: 1px solid #C4C4C4;
  border-radius: 20px;
  display: flex;
  gap: 8px;
}
.CustomInput_input:hover{
  border: 1px solid black;
}
.CustomInput_input input{
  width: 100%;
  padding: 12px 20px;
  outline: none;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  color: #757575;
}

.Input_input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757575;
  opacity: 1; 
}

/* get started form style end here */


/* get started text switch style start here */
.textSwitchCont{
  width: 100%;
  text-align: center;
}
.textSwitchCont h1{
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 55px;
  text-align: center;
  color: var(--textColor);
  white-space: nowrap;
}
.textSwitchCont span{
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 50px;
  text-align: center;
  color: var(--orange);
}
.textSwitchCont h2{
  font-size: 16px;
  color: var(--textColor);
  margin: 30px 0 40px 0;
  font-weight: 300;
}

/* get started text switch style end here */

@media screen and (max-width: 900px) {
  .getstartedCont{
    height: 650px;
  }
  .heroFormCont{
      padding: 0 20px;
    }
  .textSwitchCont{
    padding: 20px;
  }
  .textSwitchCont h1{
    font-size: 32px;
    line-height: 40px;
    white-space:normal;
  }
  .textSwitchCont span{
    font-size: 32px;
    line-height: 40px;
  }
  .textSwitchCont h2{
    margin: 15px 0 10px 0;
  }
  
}