
.innercontaner {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to right, #5E83BA, #69C78A);
  border-radius: 8px;
  margin: 26px 30px 0;
}


.right_sect {
  margin-top: 10px;
  
  margin-right: 43px;
}

.left_sect {
  padding-top: 26px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.download_buttons {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.top_title {
  font-family: 'Inter', sans-serif;
  font-style: Bold;
  font-size: 22px;
  color: #ffffff;
}

.img_behind_phone {
  display: flex;
  position: absolute;
  
}

.phone_img {
  display: flex;
  position: relative;
}

/* .content{
  align-content: center;
}
.circle_image{
  padding-top: 69px;
  
 
} */

.contenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 69px;
  padding-bottom: 50px;
}

.content_writing {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.15000000596046448px;
  margin-bottom: 20px;
}

.contenter button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 60px;
  background: #50B14A;
  border-radius: 4px;
  color: #FFFFFF;
  border: 2px solid #50B14A;
  margin:0;
}

@media only screen and (max-width: 600px) {
  .innercontaner {
    padding: 33 24 37 27px;
    justify-content: center;
    flex-wrap: wrap; 
    align-items: center;
  }
  .left_sect{
    padding: 1 20 0 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .download_buttons{
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  .content_writing{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
    margin: 20px;
    padding: 13 5 21 4px;
  }
  .top_title{
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }
  .right_sect{
    margin-right: 0px;
    padding-right: 0px ;
  }
  .img_behind_phone{
    position: absolute;
    
    left: 146px;
    top: 170px;
    border-radius: 0px;
  }
  .left_sect {
    padding-top: 26px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
}