.ul-flex{
    display: flex;
    justify-content: space-evenly;
}
.ul-flex-past{
    display: flex;
    gap: 80px;
    padding: 0 60px;
    position: absolute;
}

.menu-item{
    display: flex;
}

.main-item-main{
    display: flex
}

.inactiveuser{
    font-weight: 600;
    font-size: 18px;
    color: #444343;
    padding: 4px 0;
    user-select: none;
    cursor: pointer;
} 

.inactiveuser:hover{
    color: var(--lighty);
} 


.activeuser{
    font-weight: 600;
    font-size: 18px;
    color: var(--main);
    padding: 4px 0;
    border-bottom: solid 3px var(--main);
    border-radius: 2px;
    user-select: none;
    cursor: pointer;
}

.quoteCont{
    padding: 0 0 20px 0;
    width: 100%;
}


.input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;

  }

  .input-field{
      background: url("../../Assets/Vector.svg") no-repeat right  ;
      background-position: 380px ;
      background-size: 20px;
      width: 100%;
      padding: 18.5px 14px;
      border: 1px solid #B0AFB0;
box-sizing: border-box;
border-radius: 4px;
outline: none;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

/* inactive */

color: #B0AFB0;
  }



  .input-field-location-from{
    background: url("../../Assets/location.svg") no-repeat right  ;
    background-position: 380px ;
    background-size: 20px;
    width: 100%;
    padding: 18.5px 14px;
    border: 1px solid #B0AFB0;
box-sizing: border-box;
border-radius: 4px;
outline: none;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

/* inactive */

color: #B0AFB0;
}



.input-field-location-to{
    background: url("../../Assets/location.svg") no-repeat right  ;
    background-position: 380px ;
    background-size: 20px;
    width: 100%;
    padding: 18.5px 14px;
    border: 1px solid #B0AFB0;
box-sizing: border-box;
border-radius: 4px;
outline: none;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

/* inactive */

color: #B0AFB0;
}




.input-field-item-description{
    /* background: url("../../Assets/location.svg") no-repeat right  ;
    background-position: 380px ; */
    background-size: 20px;
    width: 100%;
    padding: 18.5px 14px;
    border: 1px solid #50B14A;
box-sizing: border-box;
border-radius: 4px;
/* outline: none; */
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

/* inactive */

color: #B0AFB0;
}



.status-body-{

    background: #50B14A;
    /* Shadow/sm */
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
}

.fiter-content{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
margin: 0;
/* identical to box height */
cursor: pointer;

/* white */

color: #FFFFFF;

}

.filterandicon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.parent-dropdown{
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 4px;
    top: 120px;
    right: 23px;
    width: 203px;
   /* padding: 10px; */
}

.each{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
/* identical to box height */


/* text */

color: #484848;
margin: 6px;
cursor: pointer;
}


.data-thead{
    background-color: white;
    color: #484848;
    padding: 20px !important;
height: 49px;
cursor: pointer;
}

.msg-table > th {
    background-color: #ffffff !important;
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 14px;
color: #484848;
border-bottom: 2px solid #E7E7E7;


}


.quote-info-image-url{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51.92px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

th, td{
/* text-align: center; */
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
color: #484848;
cursor: pointer;
}

.msg-trow-{
    /* border-top: 1px solid #E7E7E7; */
    transform: matrix(1, 0, 0, 1, 0, 0);
    border-bottom: 1px solid #E7E7E7;
    /* background-color: #50B14A; */
    position: relative;
    z-index: -1;
}



.msg-tdata-{
    cursor: pointer;
}

.itemdesc-span{
    cursor: pointer;
}


.awaiting-top-bottom{

    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
border-radius: 4px;
position: relative;
}


.awaiting-top-bottom > div{
    margin-bottom: 10px;
}

/* .ack-div{
    display: flex;
    background: #FFFFFF;
box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
border-radius: 4px;
padding: 16px;
height: 81.83px;



 
}

.waitdiv, .accptdiv{
   
    Width: 217px;
   
box-sizing: border-box;
border-radius: 4px;

margin: 0px 10px;
cursor: pointer;
}

.wait-for-morequote{
    Width: 217px;
    Height: 49.83px;
    background: #FFFFFF;
    border: 1px solid #B0AFB0;
box-sizing: border-box;
border-radius: 4px;
cursor: pointer;
color: #B0AFB0;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;


}


.accept-existing-quote{
    Width: 217px;
    Height: 49.83px;
    background: #B0AFB0;;
    border: 1px solid #B0AFB0;
box-sizing: border-box;
border-radius: 4px;
cursor: pointer;
color: #FFFFFF;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
} */



/* .ack-div-hom{
position: relative;
width: 479px;
height: 81.83px;

background: #FFFFFF;
box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
border-radius: 4px;
} */



.ack-div{
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 4px;
    position: absolute;
    right: 62px;
    top: 1px;
    display: flex;
    justify-content: center;
    padding: 10px;
    gap: 10px;
}

/* .ack-div-{
    display: flex;
    gap: 20px;
} */


.wait-for-morequote{
background: #FFFFFF;
border: 1px solid #50B14A;
box-sizing: border-box;
border-radius: 4px;
width: 217px;
height: 39px;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
color: #50B14A;
cursor: pointer;
}

.accept-existing-quote{
    background: #50B14A;
border: 1px solid #B0AFB0;
box-sizing: border-box;
border-radius: 4px;
width: 217px;
height: 39px;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
color: #FFFFFF;
cursor: pointer;
}


.accept-existing-quote-1{
    background: #FFFFFF;
    border: 1px solid #B0AFB0;
box-sizing: border-box;
border-radius: 4px;
width: 217px;
height: 39px;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
color: #B0AFB0;
cursor: not-allowed;
}


.accept-existing-quote-2{
    background: #B0AFB0;;
    border: 1px solid #B0AFB0;
box-sizing: border-box;
border-radius: 4px;
width: 217px;
height: 39px;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
color: #FFFFFF;
cursor: not-allowed;
}







.icon-logo{
    cursor: pointer;
}

tbody{
    position: relative;
    z-index: 1;
}


.top-cart{
    position: relative;
    cursor: pointer;
    user-select: none;
}


.top-cart-icon{
    
    min-width: 19px;
    min-height: 15px;
    border-radius: 50%;
    background-color: #50B14A;
    color: white;
    position: absolute;
    top: -5px;
    right: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    padding: 1px;
}

.top-cart-icon img {
    height: 32px;
    cursor: pointer;
    position: fixed;
}



.search-input-navbar > input{
    width: 400px;
    height: 39px;
    background: #ffffff;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 20px;
    text-indent: 20px;
    position: fixed;
    top: 20px;
    z-index: 1299;
    left: 45%;
    /* outline-color: var(--Dillivry-Green); */
    outline-color: #50B14A;
}


.search--- > input{
    background: #ffffff;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 20px;
    text-indent: 20px;
    outline-color: var(--Dillivry-Green);
    height: 39px;
}

.MuiLinearProgress-colorPrimary{
    background-color: #FFFFFF !important;
    border: 1px solid #50B14A
}


@media only screen and (max-width: 900px) {


.parent-dropdown{
    right: inherit !important;
    left: 23px;
    top: 100px !important;
    z-index: 100;
    
}


.search-input-navbar > input{
    display: none;
 }
 .SenderUpdateRegXX> .MuiDialogContent-root > div{
    width: auto;
}

}


/* @media only screen and (max-width: 1300px) {

    .search-input-navbar > input{
        width: 300px;
    }


} */


@media only screen and (min-width: 901px) and (max-width: 1300px)  {

    .search-input-navbar > input{
        width: 300px;
    }

}


@media only screen and (max-width: 400px) {

/* .iconConTextStyle{
    display: none !important;
} */


}