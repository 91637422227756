
.small{
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    background-color: var(--main);
    color: var(--lighter);
    cursor: pointer;
    font-size: 13px;
    border-radius: 5px;
}
.small:hover{
    color: var(--lighter);
    background-color: var(--darkest);
}