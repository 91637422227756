.form-body {
  width: 436px;
  height: 502px;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
}

.form-heading-close-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-content-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #50b14a;
}

.right-close-btn {
  height: 25px;
  width: 25px;
  background: #c54b4b;
  border-radius: 50%;
}

.global-class {
  margin-top: 5px;
  margin-left: 5px;
}

.form-instructions {
  /* width: 394px; */
  height: 28px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  margin-top: 30px;
  color: #000000;
}

/* .line-top{
    border-top: 5px solid red;
    margin-top: 20px;
    margin-right: 0px !important;
    width: 100%;
    margin-right: -20px !important;
} */

.form-providertype-drop-down-corporate {
  /* height: 43px; */
  background: #50b14a;
  border-radius: 8px 8px 0px 0px;
  margin-top: 2px;
  margin-top: 20px;
  padding: 10px;
}

.form-providertype-drop-down-corporate-inner {
  display: flex;
  justify-content: space-between;
}

.provider-type-content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}



.form-body-sign-in {
  height: 246px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
}

.form-home- {
  margin-top: 5px;
  padding: 0 0 0 0px;
}

.input-1-email {
  /* width: 334px; */
  width: 80%;
  height: 38px;
  text-indent: 40px;
  position: relative;
}

.input-2-email {
  /* width: 334px; */
  width: 80%;
  height: 38px;
  text-indent: 40px;
  position: relative;
}

.input-1-email::placeholder {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* text-indent: 20px; */
  /* identical to box height */

  color: #666666;
}

.position1-to-use {
  display: flex !important;
  justify-content: center !important;
  position: relative;
  /* background-color: #c54b4b; */
}
.position2-to-use {
  display: flex !important;
  justify-content: center !important;
  position: relative;
  background-color: #c54b4b;
}
.position1 {
  position: absolute;
  left: 20px;
}
.position2 {
  position: absolute;
  top: 0px;
}

.form-body-form {
  /* width: 100% !important;
    padding: 30px;      To be edited and work on  */
  width: 100% !important;
  padding: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.password-icon {
  position: absolute;
  top: 322px;
  left: 24%;
  /* top: 270px; */
  /* left: 21%; */
}

.remember-forgot-password {
  display: flex;
  justify-content: space-between;
}

.remember-me-content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  /* identical to box height */
  /* width: 65px;
height: 14px; */

  color: #000000;
}

.forgort-password-content {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  cursor: pointer;
  color: #50b14a;
}

.remember-checkbox {
  width: 13.66px;
  height: 14px;
}

.sign-in-to-your-account {
  /* width: 198px;
height: 27px; */

  /* background: #50b14a;
  border-radius: 4px;
  margin: 0px 50px;
  height: 27px;
  width: 198px; */
}

.sign-in-content {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* width: 166px; */
  height: 19px;
  color: #ffffff;
  margin-top: 4px;
}

.new-dillivry {
  width: 396px;
  height: 93px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
}

.new-dillivry {
  display: flex;
  justify-content: center;
}

.inner-register-content {
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.register-btn- > button {
  /* margin: 0 0 0 30px; */
  width: 92px;
  height: 27px;
  background: #50b14a;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  border: #50b14a;
  cursor: pointer;
  color: #ffffff;
}

.sign-in-to-your-account > p > button {
  background: #50b14a;
  border: #50b14a;
  color: #ffffff;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .form-body {
    width: auto !important;
  }

  .new-dillivry {
    width: auto;
  }

  .input-1-email {
    width: auto;
  }
  .input-2-email {
    width: auto;
  }

  .email-icon {
    /* top: 260px; */
    display: none;
  }

  .password-icon {
    /* top: 318px; */
    display: none;
  }

  .form-providertype-drop-down-corporate {
    margin-top: 40px;
  }
}

@media screen and (max-width: 450px) {
  .form-providertype-drop-down-corporate-inner {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .form-body {
    padding: 0px;
  }

  .global-class {
    margin-left: 2px;
  }
}
