.dil-logo {
  /* background-color: rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}


.receiver-payment-wrapper {
  /* background-color: rosybrown; */
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.receiver-agent-info {
  /* background-color: seagreen; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 30%; */
  flex: 1;
  padding: 20px;
}
.receiver-item-image {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  max-width: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.receiver-agent-status {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #444343;
}
.receiver-agent {
  /* background-color: steelblue; */
  justify-content: center;
  align-items: center;
  display: flex;
  
}
.imagereceiver{
  width: 250px;
}
.receiver-agent-number{
  position: absolute; 
  bottom: 20px; 
  width: 100%;
  text-align: center;
}
.receiver-information-wrapper {
  /* background-color: tomato; */
  display: flex;
  flex-direction: column;
  /* width: 60%; */
  padding: 20px;
  flex: 2;
  width: 100%;
}
.receiver-delivry-information {
  padding: 20px;
  background-color: #E0FCE7;
  border: 1px solid #c7e6c5;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
}
.receiver-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.description-box {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c7e5c5;
  margin-bottom: 5px;
  width: 100%;
}
.info-icon {
  padding: 7px 10px;
}
.detailss {
  padding: 7px 10px;
 
}
.price-box {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  /* height: 80px; */
  align-items: center;
  border: 1px solid #c7e5c5;
  padding: 10px;
}
.price-icon-title span {
  /* padding: 10px; */
  margin-right: 5px;
}
.price-detailss {
  padding: 10px;
}
.delivery-info {
  background-color: #50b14a;
  padding: 10px;
  color: #ffffff;
  margin-bottom: 10px;
  border-radius: 10px 10px 0px 0px;
}
.payment-info {
  background-color: #50b14a;
  padding: 10px;
  color: #ffffff;
  margin-bottom: 10px;
  border-radius: 10px 10px 0px 0px;
}
.receiver-pay-button {
  border: none;
  background-color: #50b14a;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  
  padding: 0px;
}
/* .receiver-pay-button:hover {
    background-color: #ffffff;
    border: 1px solid #50b14a;
    color: #50b14a;
  } */
  @media screen and (max-width: 1068px) {
    .description-box{
      display: grid;
      grid-template-columns: 50% 50%;
    }

  }
@media screen and (max-width: 900px) {
  .receiver-payment-wrapper {
    flex-direction: column-reverse;
    width: 100%;
  }
  .receiver-agent-info img {
    max-width: 200px;
  }
  .dil-logo div {
    width: 65%;
  }
  .icon-title {
    display: flex;
    align-items: center;
    word-break: break-all;
  }
  .description-box{
    display: grid;
    grid-template-columns: 55% 45%;
  }
  .detailss{
    word-break: break-all;

  }
  .price-box{
    flex-direction: column;
  }
  .receiver-information-wrapper{
    padding: 10px;
  }
  .receiver-delivry-information{
    padding: 10px;
  }
  /* .form-group{
    background-color: #444343!important;
    display: none !important;
  } */
 

    

}
