
.category_container{
  width: 100%;
  position: relative;
}
.category_selector{
  width: 100%;
  padding: 12px 20px;
  border-radius: 4px;
  display: flex;
  gap: 8px;
}
.category_selector:hover{
  border: 1px solid black;
}

.category_selector span{
  color: #757575;
}
.select_icon{
  margin-left: auto;
}

.category_value{
  position: absolute;
  width: 100%;
  z-index: 20;
  /* border: 1px solid #C4C4C4; */
  border-radius: 5px;
  max-width: 550px;
  background-color: white;
  padding: 10px;
  box-shadow: 5px 10px 18px #C4C4C4
}
.value_list{
  cursor: pointer;
  padding: 10px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.value_list img{
  width: 60px;
}
.value_list span{
  font-size: 13px;
  width: 100%;
  text-align: center;
}

.subcaterogyTitle{
  width:100%; 
  text-align: center;
  position:relative;
  font-size: 14px;
  color: var(--darkest);
}
.subcat_close{
  position:absolute;
   right:20px
}

@media screen and (max-width: 900px) {
  .value_list span{
    font-size: 12px;
  }
}
