.table_ > tr > th {
  background-color: #eaeaea;
}

.checkers{
  list-style: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #2e2e2e;
  cursor: pointer
}

table{
  border-collapse: collapse !important;
  width: 100%;
}

.table_ {
  border-collapse: collapse !important;
}

.table_ > tr > th{
  border-collapse: collapse !important;
}



.table_ > tr > th:nth-child(1){
  border-top-left-radius: 12px !important;
  
  border-left-width: 0px !important;
  border-collapse: separate !important;
}

.table_ > tr > th:nth-child(5){
  border-top-right-radius: 12px !important;
 
  border-left-width: 0px !important;
  border-collapse: separate !important;
}



.search-image {
  /* width: 100px !important;
  height: 83px !important; */
  max-width: 100% !important;
  height: auto !important;  
  object-fit:cover;
   object-position:50% 50%;
  border-radius: 4px !important;
  cursor: pointer;
}

.product-button {
  background: #50b14a;
  border-radius: 2px;
  width: 75px;
  height: 25px;
  font-size: 10px;
  line-height: 12px;
  border: #50b150;
 
cursor: pointer;
  color: #ffffff;
}

.product-button:hover{
  opacity: .8;
  /* color: #8adb86; */
}

.next-page-btn {
  display: flex;
  
  justify-content: space-between;
}

.next-page {
  background: #50b14a;
  border-radius: 4px;
  width: 156px;
  height: 47px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

div > .page-number {
  width: 40px;
  height: 10px;
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 2px;

}

.pagination-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.previous-btn,
.next-btn {
  width: 20px;
  height: 20px;
  background: #50b14a;
  border-radius: 2px;
}


.icn {
  width: 8px;
  height: 8px;
}



.item-style{
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #2e2e2e;
}
.item-style-hover:hover{
  text-decoration: underline;
  /* color: #111010; */
  color: #50b14a;
}


.item-style> div{
  margin-bottom: 10px;
}

.item_desc_watch {
  
  margin-bottom: 10px !important;
}

table > tbody > tr > td:nth-child(2) {
 
}

th , td{
  padding: 6px !important;
}

.watch-btn-star{
  background: #FFFFFF;
border: 1px solid #50B14A;
box-sizing: border-box;
border-radius: 2px;

height: 18px;
cursor: pointer;
color: #50B14A;
border-radius: 4px;
display: flex;
padding-right: 3px;
}

.watch-btn-star:hover{
 
  background: #449e3f;  
color: white;

  /* color: #8adb86; */
}

.watch-text{
  color: #50B14A !important;
  font-family: "Roboto", sans-serif;
  font-style: normal;
font-weight: normal;
cursor: pointer;

}

.watch-text:hover{
  color: lime !important;
  /* color: #8adb86; */
}

.global-class-name-watch-icon:hover{
  color: lime !important;
}

.paginateButtons{
  background-color: rgba(230, 244, 229, 0.5);
  border: 1px solid rgba(80, 177, 74, 0.5);
  cursor: pointer;
  padding: 6px 12px;

}

.paginateButtons:hover{
  opacity: .8;
}


.quoteBodyDetailsRight{
  width: 60px;
        height: 60px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 5px;
        border: 1px solid whitesmoke;
        cursor: pointer;
}


.quoteBodyDetailsRight:hover{
  opacity: .5;  
}

.table_ > tbody > tr:nth-child(odd) {
  height: 29%;
  overflow: hidden;
}

