.cart-cont {
  width: 100%;
  display: flex;
  justify-content: center;
}
.cart-cards {
  width: 80%;
  padding: 30px 100px;
}
.checkout-btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 20px 0;
}
.paymentCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.paymentLeft {
  width: 250px;
}
.paymentRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 300px;
  gap: 20px;
  padding: 20px 0;
}
.paymentRight_inner {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  width: 100%;
  gap: 20px;
  padding: 20px 30px;
  background-color: #E0FCE7;
}
.sub_ {
  font-size: 13px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: 400;
}
.sub_titl {
  color: #6C656B;
}
.sub_a {
  font-size: 14px;
  color: blue;
}

.total_ {
  border-top: 1px solid #c9c9c9;
  font-size: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px 0;
  font-weight: 500;
}
.total_titl {
  color: #6C656B;
}
.total_a {
  color: blue;
}
.promo_input {
  /* width: 100%; */
  height: 35px;
  padding: 0 10px;
  outline: none;
  border: 1px solid #e7e7e7;
  font-size: 13px;
}

/* Chrome, Safari, Edge, Opera */
.paymentLeft input::-webkit-outer-spin-button,
.paymentLeft input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.paymentLeft input[type=number] {
  -moz-appearance: textfield;
}


.promo_btn {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  outline: none;
  border: none;
  border-radius: 3px;
  color: white;
  background-color: var(--main);
  font-size: 13px;
  cursor: pointer;
}
.senderquote-wrap {
  gap: 20px;
  display: flex;
  align-items: center;
}
.check_container_disable{
    display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor:pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark_disable{
    position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 2px solid #B0AFB0 ;
  border-radius: 3px;
}

.check_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* style of checkbox start here */

.check_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 2px solid var(--main);
  border-radius: 3px;
}
.check_container:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.check_container input:checked ~ .checkmark {
  background-color: var(--main);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check_container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ckeockout-text {
  /* textAlign:'center, color:'var(--main)', fontWeight:'500', fontSize:'24px', marginBottom:'20px' */
  text-align: center;
  color: var(--main);
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20pz;
}
/* style of checkbox ends here */

.payment-methods{
  position: absolute;
  width: 100%;
  z-index: 20;
  top: 37px;
  background-color: white;
  padding: 10px;
  box-shadow: 1px 1px 20px rgba(96, 97, 96, 0.1);
  border-radius: 4px;
}
.payment-method{
  width: 100%;
  padding:10px 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid #dfdede;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.payment-method:hover{
  transform: scale(1.02);
}

.payment-method1{
  width: 100%;
  padding: 10px 5px 0 5px;
  align-items: center;
  display: flex;
  gap: 5px;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
}

.payment-method1:hover{
  transform: scale(1.02);
}

.MuiGrid-grid-xs-8{
  flex-basis: 0% !important;
}

@media screen and (max-width: 900px) {
  .senderquote-wrap {
    justify-content: center;
  }
  .cart-cards {
    width: 100%;
    padding: 20px;
  }
  .senderquote-img-details {
    flex-direction: column;
    padding: 10px;
  }
  .senderquote-details {
    padding: 10px 0px;
  }
  .paymentCont {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .paymentRight {
    align-items: center;
  }
  .paymentLeft {
    align-items: center;
  }
  .ckeockout-text {
    display: none;
  }
}
