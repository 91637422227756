.mobileShow{
    display: none;
}
.deskShow{
    display: block;
}
.fulfillerView{
    width: 100%;
    padding: 0;
}
.fulfillerView .fulfillerViewTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    background-color: none;
    box-shadow: 0 10px 10px -6px #777;
}
.fulfillerView .viewLogo img{
    height: 25px;
}
 .fulfillerViewTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    background-color: none;
    box-shadow: 0 10px 10px -6px #777;
}
.viewLogo img{
    height: 25px;
}
.fulfillerView .fulfillertable{
    width: 100%;
    padding: 20px;
    padding-bottom: 80px;
}
.fulfillertable .tableTitle{
    width: 100%;
    padding: 10px;
    background-color: var(--main);
    color: white;
    font-size: 13px;
}
.fulfillertable .tableBody{
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;

}
.fulfillertable .tableBody .img {
    width: auto;
    
    height: 80px;
    background-size: cover;
    background-repeat: no-repeat;max-width: 80px;
    background-position: center;
    border: 1px solid whitesmoke;
}
.fulfillertable .tableBody .verified{
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    margin-top: 10px;
}
.fulfillertable .tableBody table tr{
    background: white;
    box-sizing: border-box;
    border: 1px solid #c4c4c4;
}

.fulfillerView .reviewTable .reviewHeable{
    background-color: #EAEAEA;
    padding: 7px 15px;
    font-weight: 600;

}
.fulfillerView .reviewTable .reviewBody{
    font-size: 12px;
    width: 100%;
}

.fulfillerView .reviewTable .reviewBody > div:nth-child(odd) {
    padding: 15px;
    width: 100%;
    background-color: white;
    border: 1px solid #c4c4c4;
}
.fulfillerView .reviewTable .reviewBody > div:nth-child(even) {
    padding: 15px;
    width: 100%;
    background-color: var(--msgBg);
    border: 1px solid #c4c4c4;
}
@media screen and (max-width : 900px) {
    .deskShow{
        display: none;
    }
    .mobileShow{
        display: block;
    }
}