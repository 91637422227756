*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.loaderWrapper{
    width: 8rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinners {
    position: relative;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
}

.spinners::before,
.spinners:after{
    content: "";
    position: absolute;
    border-radius: 50%;
}

.spinners:before {
    width: 100%;
    height: 100%;
    background-image:linear-gradient(90deg, var(--main) 0%,var(--white) 100% );
    animation: spin .5s infinite linear;
}
.spinners:after {
    width: 90%;
    height: 90%;
    /* background-color: #151515; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
}
.insideSpinner{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background-color: var(--white);
    position: absolute;
    top: 5%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.insideSpinner img{
    max-width: 100px;
    max-height: 100px;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }

}


@media screen and (max-width : 900px) {
    .loaderWrapper{
        width: 4rem;
        height: 4rem;
    }
    
    .spinners {
        width: 4rem;
        height: 4rem;
    }
    .insideSpinner img{
        max-width: 50px;
        max-height: 50px;
    }
}