.move_left{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.last_btn{
    margin-top: 10px;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    padding: 13px;
    background-color: var(--main);
    color: white;
    border-radius: 3px;
    border: 0;
}