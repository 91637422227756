@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

.footercontainer {
  height: auto;
  /* margin-top: 50px; */
  
  background: linear-gradient(90deg, #333333 74398%, #373737 74408.77%, #444444 74423.13%, #5A5A5A 74437.49%, #797979 74451.85%, #A0A0A0 74466.21%, #D0D0D0 74480.57%, #F5F5F5 74487.75%, #B6B6B6 74527.24%, #9C9C9C 74545.19%, #999999 74652.89%, #949494 74670.84%, #878787 74688.79%, #717171 74710.33%, #535353 74728.28%, #2C2C2C 74749.82%, #1C1C1C 74757%);
  color: white;
}

.firstfootergrid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;
  padding-top: 50px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 50px;

}

.containerflex {
  display: flex;
}

.dillivrycontainer {
  width: 45%;
}
.compflex {
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.pi:hover {
  color: #73af46;
}

.streettext p {
  line-height: 25px;
  color: white;

}

.companycontainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footercontainer a {
  color: white;
  font-weight: 300;
}

.footercontainer a:hover {
  color: #73af46;
}

.servicescontainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.servicescontainer a {
  color: white;
  font-weight: 300;

}

.servicescontainer a:hover {
  color: #73af46;
}

.supportcontainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.supportcontainer a {
  color: white;
  font-weight: 300;

}

.supportcontainer a:hover {
  color: #73af46;
}

.iconscontainer {
  font-family: Roboto;
  display: flex;
  gap: 15px;
  margin-top: 20px;
  /* background: #000; */
}

.footerflex {
  /* background: green; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
}

.copyrightcontainer {
  /* background: red; */
  width: 50%;
}

.privacyflex {
  justify-content: flex-end;
  display: flex;
  gap: 20px;
  width: 50%;
}

.link:hover a {
  background: grey;
}

.p1:hover {
  color: #73af46;
}

@media screen and (max-width: 900px) {
  .footercontainer {
    padding: 30px;
    margin-top:0px;
  }

  .firstfootergrid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px;
  }

  .containerflex {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .dillivrycontainer {
    width: 100%;
  }
  .compflex {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  .supportcontainer a {
    color: white;
    font-size: 14px;
  }
  .companycontainer a {
    font-size: 14px;
  }
  .servicescontainer a {
    font-size: 14px;
  }

  .pi:hover {
    color: #73af46;
  }

  .streettext p {
    /* background: white; */
    line-height: 25px;
  }

  .companycontainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .footerflex {
    gap: 20px;
    flex-direction: column;
  }

  .copyrightcontainer {
    /* background: red; */
    width: 100%;
    /* text-align: center; */
  }

  .privacyflex {
    justify-content: center;
    display: flex;
    gap: 20px;
    width: 100%;
    padding-bottom: 100px;
  }

  .link:hover a {
    background: grey;
  }

  .p1:hover {
    color: #73af46;
  }
}
