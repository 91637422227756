.ack-con div {
  margin-bottom: 5px;
}
.ack-con {
  /* padding: 10px; */
  padding-left: 10px;
  padding-bottom: 10px;
}

.ack-wrap {
  border-bottom: 1px solid #c7e6c5;
}
.sub-flex-info {
  padding: 10px 10px 20px 10px;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
}
.ack-text {
  color: blue;
  line-height: 16px;
  font-size: 14px;
}
.ack-butn-con {
  display: flex;
  justify-content: space-between;

  padding-top: 10px;
}
.wait-btn {
  border: 1px solid #b0afb0;
  color: #b0afb0;
  padding: 7px;
  background-color: #ffffff;
  border-radius: 4px;
  cursor:not-allowed;
  font-size: 12px;
  user-select: none;

}
.accept-btn {
  padding: 7px;
  background-color: #b0afb0;
  color: #ffffff;
  border: 1px solid #b0afb0;
  border-radius: 4px;
  cursor:not-allowed;
  font-size: 12px;
  user-select: none;

}
@media screen and (max-width: 340px) {
  .ack-butn-con {
    flex-direction: column;
    gap: 10px;
  }
}
