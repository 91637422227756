.submitQuot .topItem{
    font-size: 13px;
    width: 100%;
    padding: 7px 15px;
    background-color: var(--main);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}
.submitQuot .bodyItem{
    padding: 10px;
    width: 100%;
    background-color: white;
}
.submitQuot .bodyItem .itemImg{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}
.submitQuot .bodyItem .itemDescs{
    font-size: 12px;
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 10px;
}
.submitQuot .bodyItem .itemDescs .itemDesc{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.submitQuot .bodyItem .qH{
    padding: 10px;
    background-color: #EAEAEA;
    border: 1px solid #EAEAEA;
    font-size: 14px;
    font-weight: 600;
}
.submitQuot .bodyItem .qB{
    padding: 10px;
    background-color: white;
    border: 1px solid #EAEAEA;
}
.quo_input_in{
    height: 30px  
}
.quo_input{
    outline: none;
    padding: 5px 10px;
    border-radius: 7px;
    width: 180px;
    border: 1px solid #EAEAEA;
}
.quo_btn{
    outline: none;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: 600;
    background-color: var(--main);
    height: 30px;
    width:180px;
}
@media screen and (max-width : 390px) {
    .quo_input{
        width: 130px;
    }
    .quo_btn{
        width: 130px;
    }
}

