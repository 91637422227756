.AppDownloadHero{
  width: 100%;
  background: #E7F1FE;
  padding-top: 80px;
}

.AppDownloadHeroCont{
  display: flex;
  width: 100%;
  padding: 70px 150px;
  align-items: center;
}
.AppDownloadHeroCont1{
  display: flex;
  width: 100%;
  padding: 70px 150px;
  align-items: center;
}
.leftAppDownload{
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
.leftAppDownload h1{
 color: var(--textColor);
 font-size: 45px;
 font-weight: 600;
}
.leftAppDownload h6{
  color: var(--textColor);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.appDownloadImg{
  display: flex;
  gap: 30px;
}
.appDownloadImg img{
  width: 190px;
  cursor: pointer;
}

.appDownloadImg img:hover{
  transform: scale(1.01);
}

.rightAppDownload{
  display: flex;
  width: 50%;
  justify-content: flex-end;
}
.rightAppDownload img{
  max-height: 500px;
}
.addPadding{
  padding-left:50px
}
.playerConte{
  margin-bottom: 40px;
  width: 400px;
  height: 220px;
  
}
.react-player{
  border-radius: 20px;
}
@media (max-width : 575px) {
  .AppDownloadHero .row {
    flex-direction: column-reverse !important
  }
}
@media screen and (max-width : 900px) {
  .playerConte{
    margin-bottom: 20px;
    width: 100%;
    height: 200px;
    
  }

  .AppDownloadHeroCont{
    width: 100%;
    padding: 40px 20px;
    align-items: center;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .AppDownloadHeroCont1{
    width: 100%;
    padding: 40px 20px;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
  .leftAppDownload{
    align-items: center;
    width: 100%;
  }
  .leftAppDownload h1{
    font-size: 35px;
    font-weight: 600;
   }
   .leftAppDownload h6{
     font-size: 16px;
   }

  .appDownloadImg{
    gap: 20px;
  }
  .appDownloadImg img{
    width: 100%;
    max-width: 180px;
  }
  .rightAppDownload{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .rightAppDownload img{
    max-height: 350px;
  }
  .addPadding{
    padding-left: 0
  }
}
