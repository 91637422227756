.displayDesktop{
    display: block;
}
.displayMobile{
    display: none;
}
.topBar{
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 100;
}

.topBarBody{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;

}
.homeLogo img{
    width: 170px;
    cursor: pointer;
}

.linksCont{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.linksCont a{
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #484848;
}
.linksCont a:hover{
    color: var(--main);
}
.actionBtnCont{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.sideBarDrawer{
    width:100%;
    display:flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
}
.dilliLogo{
    display: flex;
    /* flex: 1; */
    align-items: center;
    justify-content: center;
}
.dilliLogo img{
    width: 120px;
    /* margin-left: -20px; */
}

@media screen and (max-width: 900px) {
    .displayDesktop{
        display: none;
    }
    .displayMobile{
        display: block;
    }
}