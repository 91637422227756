.earnExtraCont{
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    height: 190px;
    background-image: url('../../../assets/EarnExtraBG.svg');
}
.earnExtra{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.earnExtraBody{
    display: flex;
    gap: 100px;
    align-items: center;
}
.extraText{
    color: white;
}
.extraText h3{
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 7px;
}
.extraText h4{
    font-size: 18px;
    font-weight: 300;
}
.extraButton{
    padding: 15px 25px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 18px;
    color: var(--textColor);
    font-size: 18px;
    font-weight: 500;
    user-select: none;
    cursor: pointer;
}
@media screen and ( max-width : 900px) {
    .earnExtraBody{
        flex-direction: column;
        gap: 20px;
    }
    .extraText h3{
        font-size: 28px;
    }
    .extraText h4{
        font-size: 16px;
    }
    .extraButton{
        padding: 12px 20px;
        font-size: 16px;
    }
}