* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .status-body {
      margin: 0 auto;
      width: 70%;
    margin-top: 120px;
  }
  
  
  .section-one{
      display: flex;
      justify-content: space-between;
      /* justify-content: flex-start; */
  /* flex-direction: row; */
  }
  
  .left-side-status{
      width: 50%;
  }
  
  
  .rightfirst{
      width: 50%;
  }
  
  
  .right-side-status{
      /* background: #C4C4C4; */
  
  }
  
  .to-be-styled-seperately{
      background: #C4C4C4;
      /* width: max-content; */
  }
  
  .staus-main{
      font-family: "Roboto", sans-serif;
      font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  }
  
  .Listed-main{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      color: #50B14A;
  }
  
  
  .Date-listed-main{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      /* identical to box height */
      color: #000000;
      
  }
  
  .tobe-delivered{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      /* margin-bottom: 30px; */
      padding: 20px;
  }
  
  .Contact{
      padding: 20px;
      font-size: 12px;
      /* color: #000000; */
  }
  
  .Contact h1{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      /* font-size: 18px;
      line-height: 21px; */
  }
  
  
  .sectionB-{
      margin-top: 50px;
  
  }
  
  .section-two{
      display: flex;
      justify-content: space-between;
  }
  
  .section-two-follow-my-item{
  /* flex: 2; */
  width: 50%;
  }
  
  .img-display{
      /* flex: 2; */
      /* width: 50%;
      display: flex; */
      width: 50%;
  }
  
  .inner-img-div{
  
  }
  
  .followmyitem{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  
  color: #000000;
  }
  
  .findout{
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      /* identical to box height */
      color: #000000;
      margin-bottom: 30px;
  }
  
  
  .container{
      width: 100%;
      /* margin: 20% auto; */
  }
  
  .progressBar-missed li{
     
      position: relative;
      margin: 30px 30px 30px 0px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  
  color: #000000;
  
  }
  
  .progressBar-missed {
      counter-reset: step;
  }
  
  
  .progressBar-missed li::before{
  
  height: 30px;
  background-color: white;
  content: url(/src//assets/Checkmark.svg);
  
  border-radius: 15%;
  line-height: 30px;
  text-align: center;
  border: 1px solid #50B14A;
  margin-right: 10px;
  }
  
  /* .progressBar-missed li:nth-child(6)::before{
      height: 30px;
      background-color: white;
      content: 'x';
      border-radius: 15%;
      line-height: 30px;
      text-align: center;
      border: 1px solid #50B14A;
      margin-right: 10px;
    }
  
    .progressBar-missed li:nth-child(6){
        color: #E5E5E5 !important;
    } */
  
  
  
    .progressBar-missed li:nth-child(7)::before{
      height: 30px;
      background-color: white;
      content: 'x';
      border-radius: 15%;
      line-height: 30px;
      text-align: center;
      border: 1px solid #50B14A;
      margin-right: 10px;
    }
  
    .progressBar-missed li:nth-child(7){
        color: #E5E5E5 !important;
    }
  
  
  
  .progressBar-missed li::after{
      content: "";
      background-color: #ddd; 
      position: absolute; 
      bottom: 0; 
      left: 5px; 
      top: 30px;
      height: 100%; 
      width: 1.5px;
  }
  
  
  
  .progressBar-missed li:nth-child(6)::after{
    display: none;
  }
  
  
  @media screen and (max-width: 900px) {
  
      .status-body{
          width: auto;
          padding: 30px;
      }
  
  
      .section-one{
          flex-direction: column;
          justify-content: center;
      }
  
      .rightfirst{
          width:  100%;
      }
  
      .left-side-status{
          width: 100%;
          margin-bottom: 30px;
      }
  
      .section-two{
          flex-direction: column;
      }
  
      .img-display{
          width: 100%;
      }
  
      .progressBar-missed li{
          /* width: 20%; */
          position: relative;
          /* margin-top: 30px; 
          margin-left: 0px;
          margin-right: 0px;
          margin-bottom: 30px; */
          /* 30px 30px 0px; */
          width: 100% !important;
      }
  
      .section-two-follow-my-item{
          width: 100%;
      }
  
      .to-be-styled-seperately{
          width: 100%;
      }
  
      /* .progressBar-missed li:nth-child(6)::before{
          height: 30px;
          background-color: white;
          content: 'x';
          border-radius: 15%;
          line-height: 30px;
          text-align: center;
          border: 1px solid #50B14A;
          margin-right: 10px;
        }
      
        .progressBar-missed li:nth-child(6){
            color: #E5E5E5 !important;
        }
       */
      
      
        .progressBar-missed li:nth-child(7)::before{
          height: 30px;
          background-color: white;
          content: 'x';
          border-radius: 15%;
          line-height: 30px;
          text-align: center;
          border: 1px solid #50B14A;
          margin-right: 10px;
        }
      
        .progressBar-missed li:nth-child(7){
            color: #E5E5E5 !important;
        }
      
      
  
  
  }