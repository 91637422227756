.acceptQuote{
    width: 100%;
    padding: 0;
}
table {
    border-collapse: collapse;
    width: 100%;
    text-align: justify;

  }
.grid-container{
    display: grid;
    width: 100%;
    align-items: center;
   
}
.gridHead{
    display: grid;
    grid-template-columns: repeat(9, 1fr) ;
    gap: 3px;
    background: #F9F9F9;
   border: 1px solid #C4C4C4;
   box-sizing: border-box;
   padding: 10px;
}
.gridHead >div{
    font-size: 11px;
    font-weight: 400;
    color: var(--dark);
    color:#50B14A;  
}
.gridBody{
    display: grid;
    grid-template-columns: repeat(9, 1fr) ;
  padding: 0px;
    gap: 5px;
    align-items: center;
    border: 1px solid #C4C4C4;
   border-top: none;
   background: #FFFFFF;
   height: 50px;
   
   
 
}
.meesegrid{
    border: 1px solid #C4C4C4;
    border-top: none;
    background: #FFFFFF;
    height:100%;
    font-size: 12px;
    cursor: pointer;
   
   

}
.gridBody >div{
    font-weight: 400;
}
.transProvider{
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    padding-left: 15px;
}
.ratinng{
    color: var(--dark);
    font-size: 13px;
    font-weight: 500;
    
}
.verified{
    color: var(--dark);
    font-size: 13px;
    font-weight: 400;
   
}
.insurance{
    color: var(--dark);
    font-size: 13px;
    font-weight: 400;
    
}
.Qamt{
    font-size: 18px;
    font-weight: 600;
    color: var(--dark);
}
.msgBTN{
    width: 100%;
    padding: 8px;
    border-radius: 7px;
    /* background-color: var(--lightMain); */
    color:#666666;
    display: flex;
    justify-content:center ;
    align-items: center; 
    font-size: 13px;
    cursor: pointer;
    display: flex;
    gap: 3px;
    height: 24px;
    background: #E0E0E0;
    border-radius: 4px;
    font-size: 9px;

}
.msgBTNReject{
    width: 100%;
    padding: 8px;
    border-radius: 7px;
    background-color: #A92003;
    color: white;
    display: flex;
    justify-content:center ;
    align-items: center; 
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    gap: 3px;
    height: 5px;
}
.acceptBTN{
    width: 80%;
    padding: 10px;
    border-radius: 7px;
    background-color: var(--main);
    color: var(--lightMain);
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content:center ;
    align-items: center; 
    gap: 3px;
    height: 30px;
    margin-top: 17px;
    margin-bottom: 20px;
    margin-left: 10px;
    
}
.acceptBTN:hover{
    background-color:  #73AF46;

}
.msgCount{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color:black;
    color: #FFFFFF;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.mssg{
    font-size: 12px;
    padding: 10px;
    color: gray;
}
._mobile{
    display: none;
}
.asked{
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
}
.wrote{
    font-size: 11px;
    font-weight: 400;
    padding: 10px;
    background: #F9F9F9;
    height: 60px;
    line-height: 15px;
    border: 1px solid #C4C4C4;
    border-bottom: none;
    margin-left: none;
    margin-right: none;
}
.expangrid{
    display: grid;
    grid-template-columns: repeat(1, 1fr) ;
    gap: 5px;
    align-items: center;
    padding: 0px 10px;
    /* border: solid 1px var(--lightMain);
    border-top-width: 0; */
    border: 1px solid #C4C4C4;
   background: #FFFFFF;
   height: 38px;
   font-size: 12px;
   color: #50B14A;
   cursor: pointer;
   text-decoration: underline
}

@media  screen and (max-width : 900px) {
   
    .grid-container{
        display: none;
    }
    ._mobile{
        display: block;
        padding: 10px;
        border: solid 1px var(--lightMain);
        margin-bottom: 20px;
    }
    .msgBTN{
        margin-top: 10px;
    }
    .acceptQuote{
        width: 100%;
        padding: 20px;
    }    
    .grid-container-mobile{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr) ;
        gap: 10px;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        
    }
    .grid-container-mobile >div{
        height: 35px;
    }

}
