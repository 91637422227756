@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

.max-bodyy{
  width: 100%;
  display: flex;
  justify-content: center;
}
.max-screen-quote{
  width: 100%;
  max-width: 1440px;

}
.gridcontainerone {
  padding-top: 70px;
  padding-bottom: 50px;
  padding-left: 120px;
  padding-right: 120px;
}
.buttoncontainer {
  margin-top: 50px;
  height: 70px;
}
.quotebutton {
  font-size: 16px;
  background: #50b14a;
  width: 200px;
  height: 60px;
  color: white;
  text-align: center;
  padding: 20px;
  font-weight: 500;
  border-radius: 5px;
}

.quotebutton:hover {
  background: #73af46;
}

.getaccountcontainer {
  width: 50%;
  padding-top: 8rem;
}

.getaccountcontainer h3 {
  font-size: 60px;
  margin-left: 5rem;
}
.qouteRight{
  width:50%;
   max-width:550px;
}
.imgcontainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  max-height: calc(100vh - 300px);
}
.imgcontainer img{
  width: 100%;
  max-width: 700px;
}

@media screen and (max-width: 900px) {
  .gridcontainerone {
    height: auto;
    flex-direction: column;
    padding: 0 30px;
    gap: 30px;
    margin-top: 30px;
    background-image: none;

  }

  .qouteRight{
    width:100%;
  }
  .imgcontainer {
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    margin-top: 30px;
    width: 100%;
  }
  .imgcontainer img{
    /* height: auto;
    max-height: 250px; */
    width: 80%;
  max-width: 700px;

  }
  .getquotecontainer {
    width: 100%;
    padding-bottom: 30px;
  }

  /* .imgcontainer img {
    max-width: 280px;
  } */
  .buttoncontainer {
    margin-top: 20px;
  }

  .quotebutton {
    width: 180px;
  }
}
