.stepHomeCardp{
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 40px;
}

.stepCard_imgp{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.stepCardTextp, .StepDetailsp{
    color: var(--textColor);
}
.stepCardTextp h3, .StepDetailsp h3{
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 7px;
}
.stepCardTextp h4, .StepDetailsp h4{
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
}


@media screen and (max-width : 900px) {
    .stepCard_img{
        max-height: 80px;
        max-width: 80px;
    }
    
    .stepCardText h3{
        font-size: 18px;
        margin-bottom: 7px;
    }
    .stepCardText h4{
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 255px){
    .stepHomeCardp{
        display: flex;
        align-items: center;
        justify-items: center;
        gap: 10px;
    }
}