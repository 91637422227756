.msg-wrapper {
  width: 100%;
  padding-right: 100px;
}
/* .msg-table {
  width: 90%;
} */

.msg-thead {
  background-color: white;
  color: var(--main);
  padding: 20px !important;
  text-align: left !important;
}
.msg-trow {
  border-bottom: 0.5px solid #c7e6c5 !important;
  transform: rotate(-0.03deg);
  /* border: 0.5px solid #c7e6c5 !important ; */
}
.msg-trow:hover {
  background-color: rgba(245, 255, 244, 0.8);
  cursor: pointer;
  font-weight: 700;
}
.msg-tdata {
  padding: 20px !important;
  text-align: left !important;
}
.direct-msg-icon-con {
  position: fixed;
  /* background-color: blue; */
  bottom: 0px;
  cursor: pointer;
  /* left: 10px; */
  right: 10px;
  /* float: right; */
  /* left: 0px; */
}
.message-right {
  position: relative;
  background: #50b14a;
  text-align: left;
  /* min-width: 20%;
  max-width: 45%; */
  padding: 10px 15px;
  max-width: 45%;
  border-radius: 6px;
  min-width: 20%;
  /* width: 40%; */
  /* border: 1px solid #ccc; */
  float: right;
  right: 50px;
  color: white;
}
.message-left {
  position: relative;
  background: #f2f2f7;
  text-align: left;
  max-width: 400px;
  width: 100%;
  min-width: 20%;
  padding: 10px 15px;
  border-radius: 6px;
  /* border: 1px solid #ccc; */
  float: left;
  left: 10px;
}

.imageView-cropper img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.message-left::before {
  content: "";
  position: absolute;
  visibility: visible;
  top: -1px;
  left: -10px;
  /* border: 10px solid transparent; */
  border-top: 10px solid #ccc;
}

.message-left::after {
  content: "";
  position: absolute;
  visibility: visible;
  top: 0px;
  left: -7px;
  border: 10px solid transparent;
  border-top: 10px solid #f2f2f7;
  clear: both;
}

.message-right::before {
  content: "";
  position: absolute;
  visibility: visible;
  top: -1px;
  right: -10px;
  border-top: 10px solid #ccc;
}

.message-right::after {
  content: "";
  position: absolute;
  visibility: visible;
  top: 0px;
  right: -8px;
  border: 10px solid transparent;
  border-top: 10px solid #50b14a;
  clear: both;
}
.right-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
  /* margin-top: 10px; */
}
.left-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
}
.message-timeStamp {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 10px;
  /* width: 50%; */
  /* float: right; */
}
.all-time {
  width: 100%;
  text-align: right;
}

.main-msg {
  width: 100%;
}

.reply-message-con {
  border: 1px solid #b0afb0;
  box-sizing: border-box;
  border-radius: 3px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  /* position: fixed; */
  bottom: 40px;
  /* width: calc(100% - 450px); */
  background-color: white;
  /* min-height: 100px; */
}

.reply-message-wrap {
  /* position: fixed;
  bottom: 0px; */
  padding: 20px;

  width: 100%;
  background-color: white;
}
.reply-input {
  width: 100%;
  border: none;
  padding: 20px;
  outline: none;
  margin-top: 3px;
  color: #000000DE;
  background: transparent;
  /* outline-color: var(--main); */
}
.send-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.send-icons {
  display: flex;
  gap: 20px;
  cursor: pointer;
}
.chat-con {
  padding: 20px;
  display: flex;
  height: 94vh;
  position: relative;
  /* bottom: -80px; */
  overflow-y: auto;
  flex-direction: column;
  /* justify-content: space-evenly; */
}
.send-icon img:hover {
  transform: scale(0.8);
}
.sending {
  margin-right: 20px;
}
/* .text-sty {
  text-transform: capitalize;
} */
@media only screen and (max-width: 900px) {
  .message-right {
    right: 0px;
  }
  .msg-wrapper {
    display: none;
  }
  .moblie-msg-wrap {
    padding: 20px 0px;
    height: calc(100vh - 55px);
    /* background-color: chartreuse; */
  }
  .mobile-msg-tdata {
    padding: 20px !important;
    text-align: left !important;
  }
  .mobile-msg-tdata h4{
    font-size: 14px;
  }
  .mob-msg-img-con {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mob-msg-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #50b14a;
    color: #ffffff;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .mob-msg-img h3{
    margin: 0;
    padding: 0;
  }
  .mobile-msg-row:active {
    background-color: rgba(245, 255, 244, 0.8);
  }

  .mobile-direct-msg-icon {
    position: fixed;
    bottom: 50px;
    cursor: pointer;
    width: 100px;
    height: 100px;
    right: 0px;
  }
  .mobile-msg-row {
    border-bottom: 0.5px solid #c7e6c5 !important;
  }
  .message-right-mobile {
    position: relative;
    background: #50b14a;
    text-align: left;
    padding: 10px 15px;
    border-radius: 6px;
    float: right;
    /* right: 50px; */
    /* width: 89%; */
    color: white;
  }
  .message-left-mobile {
    font-size: 13px;
    position: relative;
    background: #f2f2f7;
    text-align: left;
    /* width: calc(100% - 40px); */
    padding: 10px 15px;
    border-radius: 6px;
    float: left;
    left: 8px;
  }
  .message-left-mobile::before {
    content: "";
    position: absolute;
    visibility: visible;
    top: -1px;
    left: -10px;
    /* border: 10px solid transparent; */
    border-top: 10px solid #ccc;
  }
  .message-left-mobile::after {
    content: "";
    position: absolute;
    visibility: visible;
    top: 0px;
    left: -7px;
    border: 10px solid transparent;
    border-top: 10px solid #f2f2f7;
    clear: both;
  }

  .message-right-mobile::before {
    content: "";
    position: absolute;
    visibility: visible;
    top: -1px;
    right: -10px;
    border-top: 10px solid #ccc;
  }

  .message-right-mobile::after {
    content: "";
    position: absolute;
    visibility: visible;
    top: 0px;
    right: -8px;
    border: 10px solid transparent;
    border-top: 10px solid #50b14a;
    clear: both;
  }
  .reply-input {
    padding: 5px 20px;
    color: #000000DE;
    background: transparent;
  }
  .send-con {
    padding: 5px 10px;
  }
  .reply-message-wrap {
    padding: 0px;
  }
}
