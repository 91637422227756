.max-screenTop {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 300;
}
.flexnavbar {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background: transparent;
}
.flexnavbar.ctive {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 300;
  background-color: white;
  height: 70px;
  width: 100%;
}

.navflex {
  display: none;
}

.ind-nav {
  /* display: none; */
}

.logocontainer-web {
  justify-content: flex-start;
  padding: 0 0 0 100px;
}

.logocontainer-web img {
  width: 200px;
  cursor: pointer;
}

.logocontainer-mobile {
  display: none;
}

.linkscontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 100px 0 0;
}

.linkscontainer ul {
  display: flex;
  gap: 20px;
  cursor: pointer;
  font-weight: 500;
  justify-content: flex-end;
  align-items: center;
}

.linkscontainer li {
  list-style: none;
  font-size: 15px;
}

.linkscontainer a {
  text-decoration: none;
  color: #000;
  white-space: nowrap;
}

.linkscontainer a:hover {
  color: #73af46;
}

.linkscontainer button {
  height: 35px;
  background: #50b14a;
  border-radius: 3px;
  padding: 0 10px;
  cursor: pointer;
  border: hidden;
  outline: none;
  color: white;
}

.linkscontainer button:hover {
  background: #73af46;
}

.myDillivryBtn {
  text-transform: none;
  font-size: 14px;
}
.myDillivryBtn:hover + .myDillivryBtnSubMenu {
  display: none;
}
.myDillivryBtnSubMenu {
  /* display: none; */
  position: fixed;
  top: 60px;
  text-transform: none;
  background-color: var(--main);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}
.myDillivryBtnSubMenu1 {
  position: fixed;
  top: 95px;
  text-transform: none;
  background-color: rgb(247, 184, 184);
  color: red;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

/* ........MOBILE RESPONSIVENESS...... */
@media screen and (max-width: 900px) {
  .linkscontainer {
    display: none;
  }

  .flexnavbar {
    display: none;
  }
  .flexnavbar.ctive {
    display: none;
  }

  .logocontainer {
    display: block;
    padding-top: 5px;
    width: 50%;
  }

  .logocontainer img {
    /* height: 50%; */
    width: 100px;
    margin: 0 auto;
  }
  .navflex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* background: green; */
  }
  .logocontainer-web {
    display: none;
  }
}
