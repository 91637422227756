.e251_2274 { 
	width:72px;
	height:72px;
}
.e251_2275 { 
	border-radius:50%;
	width:72px;
	height:72px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.e251_2275 { 
	border:4px solid rgba(80.00000283122063, 177.0000046491623, 74.0000031888485, 1);
}
.e251_2276 { 
	border-radius:50%;
	background-color:rgba(80.00000283122063, 177.0000046491623, 74.0000031888485, 1);
	width:42px;
	height:42px;
}
