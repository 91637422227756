.msgTable {
  margin-top: 10px;
  width: 100%;
  /* padding: 10px; */
  padding-bottom: 20px;
  border: solid 1px var(--msgBorderline);
  background-color: var(--msgBg);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.msgTableHead {
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 10px;
  font-size: 14px;

  border-bottom: solid 1px var(--msgBorderline);
  /* border-bottom: 1px; */
}
/* checkbox css start here */

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: green;
}
.ff {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.dmMessage {
  padding: 30px 150px;
}
.timediplaydesk {
  display: block;
}
.timediplaymobile {
  display: none;
}
.headfrom {
  flex: 3;
}
.headSubject {
  flex: 8;
}
.headRecieved {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}
.msgTableRow {
  display: flex;
  align-items: center;
  padding: 12px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  border-bottom: solid 1px var(--msgBorderline);
}
.msgTableRow:hover {
  background-color: var(--lightMain);
}
.tablerowwrapper {
  display: flex;
  flex: 10;
}
.tableRowfrom {
  flex: 3;
}
.tableRowSubject {
  gap: 5px;
  display: flex;
  flex: 8;
}

.tableRowRecieved {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}
.viewTableMsg {
  width: 100%;
  padding: 0;
  border-radius: 10px;
  border: solid 1px var(--msgBorderline);
}
.viewTableMsg1 {
  width: 100%;
  padding: 0;
  height: calc(100vh / 2);
  border-radius: 10px;
  border: solid 1px var(--msgBorderline);
}
.paginationmobile {
  display: none;
}
.messageSubjmobil {
  display: none;
}

.viewTableHead {
  background-color: var(--lightMain);
  padding: 20px;
  display: flex;
  gap: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: solid 1px var(--msgBorderline);
}
.tableheadImage {
  width: 100px;
  height: 100px;
  border: solid 1px var(--msgBorderline);
  border-radius: 50%;
}
.tableHeadDesc {
  font-size: 20px;
  color: var(--main);
}
.tableHeadLocation {
  display: flex;
  gap: 10px;
}
.viewTableBody {
  padding: 40px;
  border-bottom: solid 1px var(--msgBorderline);
  position: relative;
}
.tableHeadDe {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

textarea .textarea {
  resize: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-width: 2px;
  border-bottom-style: groove;
  background-color: white;
  color: var(--main);
  padding: 5px;
  font-size: 13px;
  border-radius: 3px;
  font-weight: 600;
}
.column1 {
  width: 100%;
}

.meresult {
  display: flex;
  width: 100%;
  height: 56px;
  top: 186px;
  bottom: 100px;
  background: #f9f9f9;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  margin-bottom: 14px;
  justify-content: space-between;
}
.insidemersult {
  /* flex:1; */

  width: 120px;
  height: 14px;
  top: 207px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;

  text-align: left;
  /* identical to box height */
  text-align: center;
  color: #50b14a;
  padding-top: 20px;
}
/* .insidemersult2{
        flex:1;
   
        text-align: right;
    }
    .msbod{
        border: solid #C4C4C4 1px;
        border-top: none;
    } */

.msbdy {
  display: flex;
  justify-content: space-between !important;
}

.msbod11 {
  /* background-color: green !important; */
  /* width: 200px; */
  background: #ffffff;
  border: 1px solid #666666;
  box-sizing: border-box;
  border-radius: 4px;
  width: 200px;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 5px;
}
.msbod12 {
  width: 138px;
  height: 40px;
  left: 1084px;
  top: 265px;
  background: #eaeaea;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  padding-top: 10px;
  font-size: 12px;
  line-height: 16px;
}
.msbod13 {
  flex: 1;
  width: 50px;
  height: 40px;
  top: 12px;
  font-size: 12px;
  line-height: 16px;
  background: #eaeaea;
  border: 1px solid #e0e0e0;
  text-align: center;
  color: #989898;
  padding-top: 10px;
  border-radius: 4px;
}

.msbod123 {
  display: flex;
  width: 20%;
  justify-content: space-between;
}
.msbod1 {
  padding: 10px 20px;
  border: solid #c4c4c4 1px;
  width: 100%;
  border-bottom: none;
  color: #989898;
  background: #f9f9f9;
  box-sizing: border-box;
}

.msbod2 {
  border: solid #c4c4c4 1px;
  width: 100%;
  padding: 5px 10px;
  border-bottom: none;
  height: 32px;
  top: 311px;
  background: #f9f9f9;
  box-sizing: border-box;
  font-size: 13px;
}
.messagein {
  display: flex;
  font: "Roboto";
  font-weight: 700px;
  size: "12px";
  line-height: 16px;
}
.messagein1 {
  width: 25%;
  padding-left: 10px;
  cursor: pointer;
}
.messagein2 {
  width: 50%;
  margin-left: 50px;
  cursor: pointer;
}
.messagein3 {
  width: 25%;
  text-align: right;
  padding-right: 10px;
}

.msbod3 {
  border: solid #c4c4c4 1px;
  width: 100%;
  padding: 5px 10px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: #50b14a;
  padding-top: 20px;
  height: 50px;
  top: 0px;
  background: #ffffff;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700px;
}

.msbod4 {
  border: solid #c4c4c4 1px;
  width: 100%;
  padding: 5px 10px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: #50b14a;
  height: 50px;
  top: 0px;
  background: #ffffff;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  padding-top: 20px;
}
.msbod5 {
  border: solid #c4c4c4 1px;
  width: 100%;
  padding: 5px 10px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: #50b14a;
  height: 50px;
  top: 0px;
  background: #ffffff;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  padding-top: 20px;
}
.msbod6 {
  border: solid #c4c4c4 1px;
  width: 100%;
  padding: 5px 10px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: #50b14a;
  height: 50px;
  top: 0px;
  background: #ffffff;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  padding-top: 20px;
}

.msbod7 {
  border: solid #c4c4c4 1px;
  width: 100%;
  padding: 5px 10px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: #989898;
  height: 56px;
  top: 543px;
  background: #f9f9f9;
  box-sizing: border-box;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  padding-top: 15px;
}
.messageCheck {
  width: 100%;
  padding: 7px 15px;
  border-right: solid 1px #c4c4c4;
  border-left: solid 1px #c4c4c4;
}
.messageType {
  width: 150px;
  outline: none;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 7px;
}

.messagess {
  display: flex;
  align-items: center;
  width: 100%;
  border: solid 1px #c4c4c4;
  border-bottom: none;
  padding: 10px 20px;
}
.messageFrom {
  width: 20%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  font-weight: 500;
}
.messageFrom:hover {
  color: var(--main);
}
.messageSubj {
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  font-weight: 500;
  width: 20%;
  cursor: pointer;
}
.messageSubj:hover {
  color: var(--main);
}
.messageSubj span {
  font-size: 14px;
  font-weight: 300;
  color: black;
}
.messageText {
  /* color: var(--main); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 300;
  width: 55%;
}

.messageTime {
  width: 10%;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}
.msgView {
  width: 100%;
  display: flex;
  gap: 30px;
  border: 1px solid #c4c4c4;
}
.msgViewContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.msgViewContainerTop {
  width: 100%;
  display: flex;
  border: 1px solid #c4c4c4;
  padding: 10px;
  align-items: center;
  gap: 20px;
  background-color: #f9f9f9;
}

.msgViewContainerTop2 {
  width: 100%;
  display: flex;
  border: 1px solid #c4c4c4;
  padding: 10px;
  align-items: center;
  gap: 20px;
  background-color: #f9f9f9;
}


.msgViewContainerTopImage {
  width: 120px;
  height: 75px;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.msgViewTopText {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
}
.msgCard {
  padding: 20px;
  display: flex;
  width: 100%;
  border: 1px solid #c4c4c4;
  gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
.msgCardPics {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
}
.msgCardChat {
  width: calc(100% - 70px);
  font-size: 14px;
  line-height: 25px;
}
.msgCardChat h4{
  font-size: 14px;
}
.topPaginate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border: 1px solid #c4c4c4;
  border-bottom: none;
}
.paginateButtons {
  background-color: rgba(230, 244, 229, 0.5);
  border: 1px solid rgba(80, 177, 74, 0.5);
  cursor: pointer;
  padding: 6px 12px;
}
.msgDivCont {
  width: 100%;
  height: 100%;
  padding: 0;
}
.mobileMsgHead {
  display: none;
}
.mobileMsgfooter {
  display: none;
}
.DesktopMsgfooter {
  width: 100%;
  display: block;
  padding: 10px 20px;
  background-color: whitesmoke;
  border: 1px solid #c4c4c4;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.padding4Mobile {
  padding: 0;
}
.msgHouseSender {
  width: 100%;
  padding: 0 100px;
}
.showNow {
  display: none;
}

/* message flex start from here */
.msgflexb {
  display: flex;
}
.msgflexbsub2 {
  margin-left: 35px;
  margin-top: 37px;
}
.mgsub1 {
  border: 1px solid #c4c4c4;
  width: 350px;
  height: 135px;
  background-color: #f9f9f9;
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
}
.mgsub2 {
  width: 350px;
  height: 58px;
  background-color: #ffffff;
  border: 0.5px solid #c4c4c4;
  border-top: none;
  border-bottom: none;
  padding: 20px 20px 20px 120px;
}
.mgsub3 {
  width: 350px;
  height: 106px;
  background-color: #f9f9f9;
  border: 1px solid #c4c4c4;
  padding-top: 30px;
  padding-left: 100px;
}

.msg-tdata-f{
  /* display: none; */
  padding: 20px 20px 20px 0px !important;
  text-align: center !important;

}

.data-thead--ff{
  background-color: white;
    color: #484848;
    /* padding: 20px !important; */
    height: 49px;
    cursor: pointer;
}

/* message flex start from end */
@media screen and (max-width: 900px) {
  .paginateButtons {
    padding: 2px 5px;
    font-size: 13px;
  }
  .dmMessage {
    padding: 20px 0 0 0;
  }
  .msgView {
    border: none;
  }
  .msgViewContainer {
    gap: 20px;
  }
  .msgCard {
    border: 1px solid #c4c4c4;
  }
  .showNow {
    display: block;
  }
  .msgViewContainerTop {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
    background-color: #f9f9f9;
    border: 1px solid #c4c4c4;
  }
  .msgViewTopText {
    align-items: center;
    justify-content: center;
  }

  .padding4Mobile {
    padding: 0 20px;
  }
  .DesktopMsgfooter {
    display: none;
  }
  .mobileMsgHead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 7px 20px;
    font-weight: 500;
    background-color: whitesmoke;
    border: 1px solid #c4c4c4;
    border-bottom: "none";
  }
  .mobileMsgfooter {
    width: 100%;
    display: block;
    padding: 10px 20px;
    background-color: whitesmoke;
    border: 1px solid #c4c4c4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .msgDivCont {
    padding: 0 20px;
  }
  .tablerowwrapper {
    flex-direction: column;
  }
  .tableRowSubject {
    display: block;
    flex: 1;
  }
  .msgTableHead {
    display: none;
  }
  .topPaginate.noshow {
    display: none;
  }
  .messagess.noshow {
    display: none;
  }
  .messageFrom.noshow {
    display: none;
  }
  .messageSubj {
    width: 95%;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .messagess {
    position: relative;
  }
  .messageTime {
    width: 20%;
    position: absolute;
    top: 2px;
    right: 5px;
    font-size: 11px;
  }
  .messageSubj span:hover {
    color: var(--main);
  }
  .messageText {
    display: none;
  }
  .msgHouseSender {
    width: 100%;
    padding: 0;
  }
  .paginationmobile {
    display: block;
    width: 100%;
  }
  .messageSubjmobil {
    display: block;
  }
  .timediplaydesk {
    display: none;
  }
  .timediplaymobile {
    display: block;
    font-size: small;
    /* margin-bottom: 30px; */
  }
  .messageTime {
    padding-left: 0px;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .messageTime {
    padding-left: 0px;
  }
}



.msg-body{
  /* background-color: #c4c4c4; */
  display: flex;
}

.first-msg{
  flex: 2;

}

.second-msg{
  flex: 2;
  background: #FFFFFF;
}


.msg-trow-active{
background-color: rgba(245, 255, 244, 0.8);
}



