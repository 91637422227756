.regCard{
	width: 100%;
	height: 144px;
	display: flex;
	border-radius: 10px;
	justify-content: space-between;
	padding: 15px;
	margin-bottom:40px;
	user-select: none;
	box-shadow: 0 10px 6px -6px #777;
	cursor: pointer;
	/* box-shadow:
		0 2.8px 2.2px rgba(0, 0, 0, 0.034),
		0 6.7px 5.3px rgba(0, 0, 0, 0.048),
		0 12.5px 10px rgba(0, 0, 0, 0.06),
		0 22.3px 17.9px rgba(0, 0, 0, 0.072),
		0 41.8px 33.4px rgba(0, 0, 0, 0.086),
		0 100px 80px rgba(0, 0, 0, 0.12) */
}
.regCard:hover{
	transform: scale(1.01);
}
.left{
	width: 50%;
	display: flex;
	flex-direction: column;
	gap: 20px;
	color: white;
	justify-content: flex-start;
	align-items: flex-start;
}
.leftend{
	width: 50%;
	display: flex;
	flex-direction: column;
	gap: 20px;
	color: white;
	justify-content: flex-start;
	align-items: flex-end;
}
.leftTop{
	font-size: 16px;
	font-weight: 600;
}
.leftBottom{
	font-size: 12px;
	font-weight: 500;
}
.right{
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}