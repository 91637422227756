.tab-flex {
  display: flex;
  gap: 80px;

  justify-content: flex-start;
}
.sender-settings-wrap {
  padding: 0 60px;
}
.personal-settings {
  padding-top: 60px;
  display: flex;
  gap: 80px;
  width: 50%;
  height: 50vh;
  /* background-color: chartreuse; */
}
.business-settings {
  padding-top: 60px;
  display: flex;
  gap: 80px;
  /* width: 50%; */
  height: 50vh;
}
.personal-input-field-wrap {
  border: 0.5px solid #b0afb0;
  border-radius: 4px;
  /* padding: 20px; */
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: cornflowerblue; */
}
.business-input-field-wrap {
  border: 0.5px solid #b0afb0;
  border-radius: 4px;
  width: 100%;
}
.business-input-field-wrap li {
  display: flex;
  justify-content: space-between;

  margin: 20px;
}
.business-input {
  border: none;
  padding: 0px 10px;
  width: 50%;
  /* width: fit-content; */
  text-align: right;
  outline-color: var(--main);
  cursor: pointer;
}
.business2-input {
  border: none;
  padding: 0px 10px;
  text-align: right;
  outline-color: var(--main);
  cursor: pointer;
}
.personal-input-field {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
}
.personal-input-field input {
  border: none;
  padding: 10px;
  outline-color: var(--main);
  cursor: pointer;
}
.password-change {
  border: 0.5px solid #b0afb0;
  padding: 10px;
  outline-color: var(--main);
  width: 100%;
}

.personal-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  /* background-color: chocolate; */
}
.change-password p {
  color: var(--main);
  margin-bottom: 40px;
  text-decoration: underline;
}
.update-info-btn {
  display: flex;
  justify-content: flex-end;
}
.update-info-btn button {
  margin-left: 20px;
  width: 70px;
  height: 30px;
  border: 1px solid #50b14a;
  border-radius: 4px;
  cursor: pointer;
}
.cancel-update {
  background-color: white;
  color: var(--main);
}
.save-update {
  background-color: var(--main);
  color: white;
}
.passnone {
  display: none;
}
.send-text {
  font-weight: 500;
  font-size: 16px;
}
.change-image {
  display: flex;
  justify-content: flex-end;
}
.change-image p {
  color: var(--main);
  text-decoration: underline;
}
.changebox p {
  cursor: pointer;
}
.change-image p {
  cursor: pointer;
}
.personal-image img{
  width: 150px;
  height: 150px;
}

@media screen and (max-width: 900px) {
  .sender-settings-wrap{
    padding: 0;
  }
  .change-password p {
    margin-bottom: 10px;
  }
  .personal-settings{
    padding: 30px 20px;
    width: 100%;
    gap: 20px;
  }
  .change-image{
    justify-content: center;
  }
  .personal-image img{
    width: 100px;
    height: 100px;
  }

  .business-settings{
    flex-direction: column;
    gap: 50px;
    padding: 20px;
    align-items: center;

  }
}
