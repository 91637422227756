
.header{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    transition: 0.3s;
    padding: 20px;
}

.logo a{
    display: block;
}

.logo a > h2{
    display: block;
    width: 100%;
}


.navigation{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #444343;
}

.side_menu{
    z-index: 1;
    transition: 0.5s;

}

.side_menu li{
    list-style-type: none;
    /* color: #ffffff; */
}

.menu-inner-div{
    display: flex;
    margin-top: 40px;
    padding: 30px;
    /* margin-bottom: 10px; */
    user-select: none;
}

.icondiv{
    margin-right: 20px;
}

.side_menu li a{
    color: #444343;
    text-decoration: none;
    display: block;
    padding: 40px 25px;
    font-size: 18px;
    line-height: 1;
    transition: 0.3s;
}

/* .hamburger{
    position: relative;
    width: 30px;
    height: 4px;
    background: #50B14A;
    border-radius: 10px;
    cursor: pointer;
    z-index: 2;
    transition: 0.3s; 
}

.hamburger::before,
.hamburger::after{
    content: "";
    position: absolute;
    height: 4px;
    right: 0;
    background: #50B14A;
    border-radius: 10px;
    transition: 0.3s;
}

.hamburger::before{
    top: -10px;
    width: 20px
}

.hamburger::after{
    top: 10px;
    width: 25px;
} */

/* .toggle-menu{
    position: absolute;
    width: 30px;
    height: 100%;
    z-index: 3;
    cursor: pointer;
    opacity: 0;
} */




/* .navigation input:checked ~ .hamburger::before{
    top: 0;
    transform: rotate(-45deg);
    width: 30px

}
.navigation input:checked ~ .hamburger::after{
    top: 0;
    transform: rotate(45deg);
    width: 30px;
}

.navigation input:checked ~ .side_menu{
   left: 0;
    box-shadow: -20px 0 40px rgba(0,0,0,0.3);
} */

.search-cart{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

/* .side_menu > div{
    margin-top: 50px;
} */


.logo-description{
    display: flex;
    gap: 10px;
}

.business-details > p{
    font-size: 12px;
}


.selected-menu{
    /* margin-top: 50px; */
}

.selected-menu > div, .help-logo-title{
    padding: 10px 30px;
}
.tltletag{
    font-weight: 600;
}
.menu-items{
    user-select: none;
}
.menu-items-active{
    user-select: none;
    color: var(--main);
}
@media screen and (max-width: 992px){
    .hamburger{
        display: block;
        z-index: 5;
    }

     .toggle-menu{
        display: block;
        z-index: 4;
    }

    .header, .logo-description, .bottom-logo{
        padding: 10px 20px;
        /* position: fixed; */
    }

    .bottom-logo{
        position: fixed;
        bottom: 0px;
        cursor: pointer;
     
    }
    .logo-body{
        width: 50px;
        height: 50px;
        border-radius: 4px;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid var(--main);
        background-size: contain;
    }
    .side_menu{
        justify-content: start;
        flex-direction: column;
        align-items: center;
        /* position: fixed;
        top: 0;
        left: -300px; */
        background: #E0FCE7;
        width: 300px;
        height: 100%;
        z-index: 3;
        overflow: scroll;
       
    }

    /* .side_menu li{
        width: 100%;
        margin-top: 60px;
    }

    .side_menu li a {
        padding: 30px;
        font-size: 24px;
        box-shadow: 0 1px 0 rgba(255,255,255,0.1)
        inset;
    } */
}
