* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.status-body {
    margin: 0 auto;
    width: 70%;
  margin-top: 120px;
}


.section-one{
    display: flex;
    justify-content: space-between;
}

.left-side-status{
    width: 50%;
}


.rightfirst{
    width: 50%;
}

.to-be-styled-seperately{
    background: #C4C4C4;
}

.staus-main{
    font-family: "Roboto", sans-serif;
    font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
color: #000000;
}

.Listed-main{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #50B14A;
}


.Date-listed-main{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    
}

.tobe-delivered{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    padding: 20px;
}

.Contact{
    padding: 20px;
    font-size: 12px;
}

.Contact h1{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
}


.sectionB-{
    margin-top: 50px;

}

.section-two{
    display: flex;
    justify-content: space-between;
}

.section-two-follow-my-item{
    width: 50%;
}

.img-display{
    width: 50%;
}


.followmyitem{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
font-size: 24px;
line-height: 28px;

color: #000000;
}

.findout{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 30px;
}


.container{
    width: 100%;
}

.progressBar li{
   
    position: relative;
    margin: 30px 30px 30px 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}

.progressBar {
    counter-reset: step;
}


.progressBar li::before{
    height: 30px;
    background-color: white;
    content: url(/src//assets/Checkmark.svg);
    border-radius: 15%;
    line-height: 30px;
    text-align: center;
    border: 1px solid #50B14A;
    margin-right: 10px;
}

.progressBar li:nth-child(5)::before{
    height: 30px;
    background-color: white;
    content: 'x';
    border-radius: 15%;
    line-height: 30px;
    text-align: center;
    border: 1px solid #50B14A;
    margin-right: 10px;
  }

  .progressBar li:nth-child(5){
      color: #E5E5E5 !important;
  }

  .progressBar li:nth-child(6)::before{
    height: 30px;
    background-color: white;
    content: 'x';
    border-radius: 15%;
    line-height: 30px;
    text-align: center;
    border: 1px solid #50B14A;
    margin-right: 10px;
  }

  .progressBar li:nth-child(6){
      color: #E5E5E5 !important;
  }

.progressBar li::after{
    content: "";
    background-color: #ddd; 
    position: absolute; 
    bottom: 0; 
    left: 5px; 
    top: 30px;
    height: 100%; 
    width: 1.5px;
}

.progressBar li:nth-child(6)::after{
  display: none;
}

@media screen and (max-width: 900px) {

    .status-body{
        width: auto;
        padding: 30px;
    }


    .section-one{
        flex-direction: column;
        justify-content: center;
    }

    .rightfirst{
        width:  100%;
    }

    .left-side-status{
        width: 100%;
        margin-bottom: 30px;
    }

    .section-two{
        flex-direction: column;
    }

    .img-display{
        width: 100%;
    }

    .progressBar li{
        position: relative;
        width: 100% !important;
    }

    .section-two-follow-my-item{
        width: 100%;
    }

    .to-be-styled-seperately{
        width: 100%;
    }

    .progressBar li:nth-child(5)::before{
        height: 30px;
        background-color: white;
        content: 'x';
        border-radius: 15%;
        line-height: 30px;
        text-align: center;
        border: 1px solid #50B14A;
        margin-right: 10px;
      }
    
      .progressBar li:nth-child(5){
          color: #E5E5E5 !important;
      }
    
      .progressBar li:nth-child(6)::before{
        height: 30px;
        background-color: white;
        content: 'x';
        border-radius: 15%;
        line-height: 30px;
        text-align: center;
        border: 1px solid #50B14A;
        margin-right: 10px;
      }
    
      .progressBar li:nth-child(6){
          color: #E5E5E5 !important;
      }
}