/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
 
}

@media (max-width: 900px) {
  .dsk {
    display: none !important;
  }

.pagination{
  margin-bottom: 10px;
}

  .pagination > span{
   margin-bottom: 30px !important;
  }

  .left-pagination-entries{
    margin-left: 0px !important;
    margin-bottom: 10px;
  }

  .right-inner{
    display: flex;
    align-items: center;  
  }
  
.left-pagination{
  display: flex ;
  margin-bottom: 20px;
}

.main-back{
  display: flex;
  justify-content: center;
}

}

@media (min-width: 101px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  }

  .NavHeader {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(130, 130, 130, 0.25) !important;
    padding: 20px 70px 40px 100px;
    height: 100px;
  }

  .Nav {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    width: 150px;
    height: auto;
  }

  .right-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Nav-input {
    /* width: 75px; */
    height: 30px;
    /* height: 40px; */
    width: 542px;

    background: #ffffff !important;
    border: 1px solid #636363 !important;
    border-radius: 4px;
    outline: #ffffff;
    text-indent: 10px;
  }

  .Nav-input::placeholder {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #636363;
    width: 43px;
    height: 16px;
    padding: 10px;
  }

  .right-buton > button {
    background: #50b14a;
    border-radius: 4px;
    width: 75px;
    height: 30px;
    color: #ffffff;
    margin-right: 8px !important;
    border: #50b14a;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
  }

  .right-buton > button:hover{
    opacity: .8;
  }

  .btnnn > button {
    background: #50b14a;
    border-radius: 4px;
    width: 134px;
    height: 40px;
    color: #ffffff;
    border: #50b14a;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  .dot {
    width: 40px;
    height: 40px;
    background: #c4c4c4;
    border-radius: 50%;
    display: inline-block;
  }
  .main-search {
    display: flex;
   margin-top: 100px;
  }
  .main-search > div:nth-child(1){
    background-color: #F5F5F5 !important;
    border-right: 1px solid #989898;
    max-height: 100vh + 150px;
    border-bottom: 1px solid #989898;
    border-top: 1px solid #989898;
    border-style: solid;
  border-width: thin;
  }

  .left-sidebar {
    background-color:#F5F5F5 !important;
    max-height: 100vh + 150px;
    /* border-bottom: 1px solid #989898; */
  }

  .product-button {
    background: #50b14a;
    border-radius: 2px;
    border: #50b14a;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px !important;
    line-height: 12px !important;

    color: #ffffff;
  }

  .right-content {
    background-color: #ffffff !important;
    flex: 9;
    padding: 0px 10px 0px 5px;
    border-left: var(--main);
  }

  .btn-1,
  .btn-2 {
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 16px;
    background-color: #ffffff;
    color: #50b14a;
    border: 1px solid #50b14a;
    height: 40px;
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  .btn-1 {
    width: 134px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #50b14a;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
  }

  .btn-2 {
    width: 97px;
    background: #ffffff;
    border: 1px solid #50b14a;
    box-sizing: border-box;
    border-radius: 4px;
    height: 30px;
  }


  .btn-1:hover{
    color: #8adb86;
  }





  .btn-3 {
    width: 81px;
  }

  .btn-4 {
    width: 139px;
  }

  .btn-5 {
    width: 116px;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    border-bottom: 1px solid #d1d0d0 !important;
    border-top: 1px solid #d1d0d0 !important ;
    border-right: 1px solid #d1d0d0 ;
    border-left: 1px solid #d1d0d0 ;
    
  }
.table-header > div{
  margin-bottom: -10px;
}

  .table-signup {
    padding-left: 10px;
    margin-bottom: -12px;
  }

  .table-logistics > p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    height: 21px;
    color: #000000;
  }

  thead > tr > th {
    /* background: #eaeaea !important; */
    /* border-right: 2px solid #ffffff !important; */
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #2e2e2e;
  }



  thead > tr > th:nth-child(6) > span:nth-child(2){
    display: none;
  }


  .table_ > tbody > tr:nth-child(even) {
    background: #f7fff7;
    border: 1px solid #f7fff7;
    box-sizing: border-box;
    border: 1px solid #c4c4c4;
  }

  .table_ > tbody > tr:nth-child(odd) {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border: 1px solid #c4c4c4;
  }

  .bottom-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    width: 100%;
    height: 39px;
  }

  .right-inner {
    display: flex;
    align-items: center;
  }

  .showing-entries {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #2e2e2e;
  }

  .search-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-right: 5px;
    color: #2e2e2e;
  }

  .right-input-field {
    width: 141px !important;
    height: 17px !important;
    background: #ffffff !important;
    border: 1px solid #989898 !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
  }

  .first-search > input[type="checkbox"]:checked {
    border: 1px solid #333;
    color: aqua !important;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    width: 100%;
    height: 39px;
    padding: 20px;
    align-items: center;
  }

  .pagination-button {
    display: flex;
  }

  .left-pagination {
    display: flex;
    align-items: center;
  }

  .dropdown-btn-1 {
    width: 13.66px !important;
    height: 14px !important;
    background: black !important;
    border: 1px solid #2e2e2e !important;
    box-sizing: border-box !important;
    border-radius: 2px;
    size: 10px;
    color: white;
  }

  .first-search-1 {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: auto;
  }

  td > span > .current-quote {
    display: none;
  }

  .filter-head-inner {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    width: 96px;
    height: 16px;
    color: #2e2e2e;
  }

  .inner-filter-head {
    font-family: "Roboto", sans-serif;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    width: 112px;
    height: 14px;
    color: #2e2e2e;
  }

  .header-entries {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 14px !important;
    width: 237px;
    height: 14px;
    color: #2e2e2e !important;
  }

  .pagination-show,
  .pagination-entries {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 14px !important;
margin-right: 5px;
    color: #2e2e2e !important;
    margin-left: 5px;
  }

  .pagination-input {
    width: 42px;
    height: 26px;
    background: #ffffff;
    border: 1px solid #989898;
    box-sizing: border-box;
    border-radius: 2px;
  }

  .pagination-input::placeholder {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }

  .first-pagination {
    width: 56.21px;
    height: 28px;
    background: rgba(230, 244, 229, 0.5);
    border: 1px solid rgba(80, 177, 74, 0.5);
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #989898;
    padding-top: 5px;
  }

  .first-pagination::after {
    content: "";
    width: 56.21px;
    height: 28px;
    background: rgba(230, 244, 229, 0.5);
    border: 1px solid rgba(80, 177, 74, 0.5);
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #989898;
    padding-top: 5px;
  }

  .first-pagination > a {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    width: 28.62px;
    height: 16px;
    font-size: 14px;
    line-height: 16px;
    color: #989898;
  }

  .previous-pagination {
    width: 74px;
    height: 28px;
    background: rgba(230, 244, 229, 0.5);
    border: 1px solid rgba(80, 177, 74, 0.5);
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #989898;
  }

  .first-right {
    display: flex;
    position: relative;
    margin-bottom: -12px;
  }

  .first-right > div .global-class-name {
    position: absolute;
    top: 8px;
    right: 10px;
  }

  .upper-search-icon {
    position: absolute;
    top: 30px;
  }

  .clb {
    display: none;
  }

  .right-filter > span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    width: 45.33px;
    height: 19px;
    color: #2e2e2e;
  }

  .Backto-top {
    display: flex;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    height: 39px;
    margin-top: 30px;
  }

  .main-back > span {
    width: 51px;
    height: 12px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    color: #000000;
  }

  .before-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    background: #eaeaea;
    width: 100%;
    height: 50px;
  }

  .before-content {
    display: flex;
    align-items: center;
  }

  .logistics-before-content {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;

    color: #000000;
    margin-right: 50px;
  }

  .logistics-signup {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .image-watch {
    display: flex;
    align-items: center;
  }

  .image-watch > div > img {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .item_desc {
    max-width: 46px !important;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .new-pagination > ul {
    display: flex;
  }

  .new-pagination > ul .selected {
    width: 28px;
  }

  .page-item {
    width: auto;
    height: 28px;
    background: #e6f4e5;
    border: 1px solid rgba(80, 177, 74, 0.5);
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    padding: 10px;
    margin: 5px;
  }

  .page-linkk {
    padding-bottom: 10px;
  }

  .active- {
    background: #50b14a;
    border: 1px solid #50b14a;
    box-sizing: border-box;
    color: #ffffff !important;
    width: 28.62px;
    height: 28px;
  }

  .active- > a {
    color: #ffffff;
  }

  .right-pagination {
    display: flex;
    flex-direction: row;
  }

  div .new-pagination {
    display: flex;
  }

  .last-pagination {
    width: 56.21px;
    height: 28px;
    background: #e6f4e5;
    border: 1px solid rgba(80, 177, 74, 0.5);
    box-sizing: border-box;
    padding-top: 2px;
    cursor: context-menu;
  }

  .last-pagination > a {
    width: 27.59px;
    height: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-left: 10 px;

    color: #2e2e2e;
  }

  .image-watch > div {
    padding-top: 0 !important;
  }

  .table_ > tbody > tr > td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }




.table_ > thead > tr:nth-child(1){
  height: 40px !important;
}

  .table_ > tbody > thead{
    height: 20px!important;
  }

  .table_  tbody  tr td {
    max-width: 190px;
  }

  .table_  tbody  tr td  main{
    overflow: hidden;
    overflow-wrap: break-word;
    -webkit-column-width: 150px;
    column-width: 150px;
  
  }

.table_ tbody tr td:nth-child(6) > span:hover{
  color: red;

}


.global-class-name-icon{
  top: 3px;
  position: relative;
  color: #50b14a !important;
}


.table_ thead  tr th:nth-child(6) div + div + span {
display: none;
}

.table_ thead  tr th:nth-child(6) div:hover {
  display: none;
}

.table_ thead  tr th:nth-child(6) div + div:hover {
  display: none;
}

.table_ thead  tr th:nth-child(6) div + div:hover + span{
  color: #ffffff;
  display: block;
  position: absolute;
  z-index: 300;
  background: #7c7878;
  max-width: 100px;
  text-align: center;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
}


  button > a {
    color: #ffffff;

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  .sidebar-btn {
    background: #50b14a;
    border-radius: 4px;
    width: 134px;
    height: 40px;
    color: #ffffff;
    margin-right: 8px !important;
    border: #50b14a;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  .sidebar-btn {
    display: none;
  }

  .header-nav- > .navigationbar > .flexnavbar > .linkscontainer {
    padding: 0 20px 0 0;
    display: block;
  }

  .header-nav- > .navigationbar > .flexnavbar > .logocontainer-web {
    display: block;
  }

  .header-nav- > .navigationbar > .flexnavbar > .linkscontainer > ul {
    grid-gap: 10px;
  }

  .mbl {
    display: none !important;
  }
  .watch-btn-:hover{
    background: #50b14a !important;
    color: #ffffff !important;
  }

  .watch-btn-::before:hover{
    content: "♥" !important;
    color: #ffffff;
  }


  .icn:hover{
    color: #ffffff !important;
    filter: opacity(0.5) drop-shadow(0 0 0 blue);
  }

.watch-btn-{
  width: 51px !important;
}

.loading > .puffloader{
  color:  red !important;
}


.wrapper-- {
  position: relative;
}


.gold-box {
  position: fixed;
  z-index: 5; 
  background: gold;
  width: 80%;
  left: 800px;
  top: 15em;
}

}

.left-pagination-entries{
  margin-left: 45px;
}

.item_desc_watch{
  cursor: pointer !important;
  
}

