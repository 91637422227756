*{
    box-sizing: border-box;
}
.Fulfiller_account{

    width: 100%;
    padding: 0px 40px;
    gap: 20px;
}
._account{
    padding-left: 20px;
    width: 100%;
    display: flex;
    border: solid 1px var(--lightMain);
}
.profileInfo{
    flex: 1;
    padding: 20px;
    padding-top: 70px;
    background-color: var(--main);
    max-height: 400px;

}
.fulfillers{
    flex: 3;
    padding: 20px;
}
.profile{
    width: 100%;
    background-color: white;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 65px 20px 20px 20px;

}
.profileimg{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: solid 10px var(--lightMain);
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    font-weight: 600;
    color: var(--lightMain);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
}
.profileimg img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.profilename{
    color: var(--main);
    font-size: 22px;
    font-weight: 600;
    text-align: center;
}
.profilelocation{
    font-size: 18px;
    font-weight: 600;
}
.profilenum{
    font-size: 14px;
    font-weight: 500;
}
.countGrids{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* padding: 0 20px; */
}
.countGrid{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;   
}
.countNum{
    font-size: 25px;
    font-weight: 600;
}
.countTxt{
    font-size: 10px;

}
.profileRating{
    display: flex;
    gap: 10px;
}
.btnAssigns{
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 20px;
}
.btnAssign{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main);
    color: var(--lightMain);
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    gap: 3px;
}
.btnAssign:hover{
    background-color: var(--lighty);
}
.txt_box{
    width: 100%;
    height: 40px;
    padding: 5px;
    font-size: 13px;
    border-radius: 3px;
}
.for_input{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.for_input input[type='text']{
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    background-color: var(--lightMain);
    color: var(--main);
    font-weight: 600;
    }
    ::placeholder {
        color: var(--main);
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: var(--main);
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
       color: var(--main);
      }

.txt_box:focus {
    outline: none;
    border-bottom-color: var(--main);
    } 
.btn_{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
    font-size: 25px;
    border: none;
    cursor: pointer;
    background-color: var(--main);
    border-radius: 50%;
    font-weight: 700;

}
.btn_:hover{
    color: var(--lightMain);
    background-color: var(--lighty);

}
.quoteDiv{
    width: 300px;
    position: relative;
    padding-bottom: 70px;
    
}
.tapps{
    width: 100%;
    padding: 10px 20px;
    background-color: var(--main);
    color: var(--lightMain);
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border: solid 1px var(--lightMain);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none; 
}
.tapps:hover{
    transform: scale(1.02);
    background-color: var(--lighty);
}
.activeTapps{
    width: 100%;
    padding: 10px 20px;
    background-color: var(--lightMain);
    color: var(--lighty);
    font-weight: 600;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border: solid 1px var(--lighty);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;
}
.wideCard{
    width: 100%;
    display: flex;
    gap: 20px;
    margin: 20px 0;
}
.innerCard{
    cursor: pointer;
    user-select: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    background-color: var(--lightMain);
    position: relative;
    padding: 15px 20px 7px 20px;
    gap: 7px;
}
.innerCard:hover{
    transform: scale(1.015);
}
.innerCardActive{
    cursor: pointer;
    user-select: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    background-color: var(--test);
    position: relative;
    padding: 15px 20px 7px 20px;
    gap: 7px;
    transform: scale(1.02);

}
.uppercard{
    width: 50px;
    height: 50px;
    position: absolute;
    top: -25px;
    left: calc(50% - 25px);
    background-color: var(--main);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cardCount{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cardno{
    font-size: 28px;
    color: gray;
    font-weight: 600;
}
.cardIco{
    font-size: 13px;
    font-weight: 600;
    color: var(--main);
}
.cardTag{
    font-size: 12px;
    color: gray;
    font-weight: 600;

}
._accountMobile{
    display: none;
}
.submit_btn_custom{
    width: 100%;
    background-color: var(--main);
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
}
.submit_btn_custom_disable{
    width: 100%;
    background-color: #c4c4c4;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
}

@media screen and (max-width: 900px) {
    .mobileOnly{
        padding-bottom:'150px' ;
    }
    ._account{
        display: none;
    }
    ._accountMobile{
        display: block;
        width: 100%;
    }
    .logEmail{
        text-align: right;
        font-weight: 400;
        margin-bottom: 10px;
font-size: 14px;

    }
    .userType{
        text-align: right;
        font-weight: 400;
        margin-bottom: 10px;
font-size: 14px;
    }
    .cirle{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .grad_circle{
        border: 1px solid grey;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        font-size: 35px ;
        color: black;
        font-weight: 500;
        justify-content: center;
        margin-bottom: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .logName{
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;

    }
    .logId{
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .rate{
        display: flex;
        width: 100%;
        padding: 10px 0;
    }
    
    .rateIco{
        display: flex;
        flex: 1;
        justify-content: center;
        padding: 20px;
        position: relative;
        /* align-items: center; */
    }

    .badged{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--main);
        position: absolute;
        top: 0px;
        right:40px;
    }
    .badged span{
        font-size: 12px;
        color: white;
    }
    .transactFlex{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px 0;
        /* background-color: white; */
         /* margin-bottom: 10px; */
    }
    .lefty{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .middly{
        flex: 4;
        user-select: none;
    }
    .righty{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Fulfiller_account{
        padding: 20px;
    }
    
    .fulfillers{
        padding: 0;
    }
    
    .btnAssigns{
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        margin-top: 20px;

        
    }
    .btnAssign{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--main);
        color: var(--lightMain);
        cursor: pointer;
        padding: 8px 15px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 600;
        gap: 3px;
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none; 
    }
    .btnAssign:hover{
        background-color: var(--lighty);
    }
   
   
}
