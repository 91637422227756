.acknowtime{
    font-weight: 500;
font-size: 14px;
line-height: 16px;

/* text */

color: #444343;

}


.timeack{
    display: flex;
    gap: 40px;
}

.senderquote-img-details-wrap-{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 20px;
    cursor: pointer;
    padding-bottom: 0;
    /* border: none !important; */
}

.small-test{
    color: #004DC1;
    width: 252px;
    font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
height: 38px;
margin-top: 10px;
}

.borderbottom{
    border-bottom:  1px solid #C7E6C5;
}


.acceptandwait{
    display: flex;
    justify-content: space-between;
}


.rightbtn{
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
}

.wait{
    border: 1px solid #50B14A;
box-sizing: border-box;
border-radius: 4px;
background: #FFFFFF;
color: #50B14A;
padding: 2px 5px;
cursor: pointer;
font-size: 12px;
user-select: none;
}


.accpt{
    border: 1px solid #50B14A;
    box-sizing: border-box;
    border-radius: 4px;
    background: #50B14A;
    color: #FFFFFF;
    padding: 2px 5px;
    cursor: pointer;
    font-size: 12px;
    user-select: none;
}

.accpt_t{
    border: 1px solid #50B14A;
    box-sizing: border-box;
    border-radius: 4px;
    background: #50B14A;
    color: #FFFFFF;
    padding:2px 5px;
    cursor: pointer;
    height: 100%;
    font-size: 12px;
    user-select: none;
    /* max-height: 35px; */

}

.paystack_btn{
    box-sizing: border-box;
    border-radius: 4px;
    background: #50B14A;
    color: #FFFFFF;
    padding:12px;
    font-weight: 600;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    width: 100%; 
    text-align: center;
    
}
.paystack_btn_disable{
    box-sizing: border-box;
    border-radius: 4px;
    background: #949694;
    color: #FFFFFF;
    padding:12px;
    font-weight: 600;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    width: 100%; 
    text-align: center;
}