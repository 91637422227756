/* .main-content {
  display: none;
} */

.main-content {
  display: flex;
}

.table .MuiTableRow-root:nth-child(even) {
  background-color: #f7fff7;
}

.right-filter > img {
  cursor: pointer;
}

.mode-information {
  display: flex !important;
}

.mode-div {
  margin-right: 10px;
}

.table .MuiTableBody-root > tr > td:nth-child(4) {
  width: 300px !important;
}

.table .MuiTableBody-root > tr > td:nth-child(3) {
  width: 230px !important;
}

.table .MuiTableBody-root > tr > td:nth-child(5) {
  width: 300px !important;
}

.table .MuiTableHead-root > tr > th:nth-child(1) {
  width: 20px !important;
}

.table .MuiTableBody-root > tr > td:nth-child(1) {
  width: 20px !important;
}

.table .MuiTableBody-root > tr > td:nth-child(7) {
  width: 200px !important;
}

.table .MuiTableBody-root > tr > td {
  /* display: none !important; */
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
  color: #2e2e2e !important;
}

.table
  div.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-15.MuiToolbar-gutters {
  border-bottom: 1px solid #d1d0d0 !important;
  border-top: 1px solid #d1d0d0 !important;
  border-right: 1px solid #d1d0d0;
  border-left: 1px solid #d1d0d0;
}

.filter-span {
  background: #eaeaea;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  padding: 2px;
  margin-right: 3px;
  margin-bottom: 3px;
}

/* 
.filters-item-div{
  display: flex;
} */

.filter-close {
  background: #eaeaea;
  border: none;

  /* height: 20px !important; */
}

.table-images:hover {
  opacity: 0.8;
}

.table .MuiTableSortLabel-iconDirectionAsc:nth-child(3) {
  display: none !important;
}

.table
  .MuiSvgIcon-root
  .MuiTableSortLabel-icon
  .MuiTableSortLabel-iconDirectionAsc {
  display: none !important;
}

.right-content .table {
  width: 100% !important;
}


.MuiTypography-root.MuiTypography-caption {
  padding: 0 10px !important;
}
.MTablePaginationInner-root-24 .MuiButtonBase-root.MuiIconButton-root {
  background-color: rgba(230, 244, 229, 0.5) !important;
  border-radius: 0 !important;
  border: 1px solid rgba(80, 177, 74, 0.5) !important;
  padding: 6px 12px !important;
}


/* .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 8.5px 14px !important;
} */
.MuiIconButton-label {
  color: grey !important ;
}


.watch-btn-star-remove {
  background: #50b14a;
  border: 1px solid #50b14a;
  box-sizing: border-box;
  border-radius: 2px;
  height: 18px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  padding-right: 3px;
  padding-left: 3px;
}

.watch-btn-star-remove:hover {
  background: #ffffff;
  color: #50b14a;
}

.Enquiry-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  font-size: 13px;
  color: #50b14a;
}

.quoteinputcontent- {
  color: black !important;
}

.quoteinputcontent-:focus {
  background-color: #ffffff !important;
  border: 1px solid black;
  color: black;
}

.btn-send {
  background: #50b14a;
  border: 1px solid #50b14a;
  box-sizing: border-box;
  border-radius: 2px;
  width: 45px;
  height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.btn-submiting {
  color: #50b14a;
}

.enquiry-body {
  width: 360px;
  height: 224px;
  padding: 20px;
}

.MuiTableSortLabel-iconDirectionAsc {
  display: none !important;
}

/* .toolTips2 {
  cursor: help;
  position: relative;
} */

.myImage {
  height: 100%;
  padding: 5px;
  width: 120px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 10px 10px -6px #777; */
}

.myImage img {
  height: 50px;
  max-width: 100px;
}

.global-class-name-watch-icon-{
  border-radius: 50%;
  background: #50b14a;
  color: white;
}


.global-class-name-watch-icon-minus{
  background: #D33333;
  border-radius: 50%;
}


.filter-options{
display: flex;
padding: 8px;
background-color: #50b14a;
width: 200px;
justify-content: space-between;
margin-bottom: 1px;
margin-top: 90px;
}

.filter-option-btn{
  background: #50b14a;
  border: none !important;
  color: #ffffff;
}


@media (max-width: 899px) {
  .main-search{
  padding-top: 0px !important;
  margin-top: 0px !important;
  }
  .enquiry-body{
    width: 100%;
  }

}


@media only screen and (min-device-width: 813px) {
  .dsk- {
    display: block;
  }
  .mbl- {
    display: none;
  }
}

@media only screen and (max-width: 812px) {
  .dsk- {
    display: none;
  }
  .mbl- {
    display: block;
  }

  .mobile-display-relative {
    position: relative;
  }

  .mobile-display-absolute {
    position: absolute;
    background-color: aqua;
    z-index: 12;
  }

  .mobile-display-absolute > ul {
    background-color: antiquewhite;
  }

  .table .MuiTableBody-root > tr > td:nth-child(4) {
    width: 30px !important;
  }

  .mobile-display {
    position: absolute;
    background-color: rgba(230, 244, 229, 0.7) !important;
    width: 100%;
    padding: 20px;
    right: 5px;
    color: #50b14a !important;
    z-index: 2;
  }

  .mobile-ul-display {
    background: white;
    color: black;
  }

  .mobile-ul-display > li{
    border: 1px solid #ddd;
    padding: 0.5em 1em;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-size: 12.7px;
  }

}


.main-search {
  position: relative;
}



.left-content {
  position: absolute;
  z-index: 12;

  background-color: #f5f5f5 !important;
  border-right: 1px solid #989898;
  max-height: 100vh + 150px;
  border-bottom: 1px solid #989898;
  border-top: 1px solid #989898;
  border-style: solid;
  border-width: thin;
}


