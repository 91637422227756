*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.tracking-content{
    margin: 0 auto;
    width: 70%;
    margin-top: 120px;
}

.traction-section-one{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-one-right-img{
    max-width: 136px !important;
}


.section-one-left-title{
    font-family: "Roboto", sans-serif;
    font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;

color: #50B14A;
}

.section-one-left-heading{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;
}


.tracting-section-two{
margin-top: 20px;
}

.tracting-code-paragraph-div{
    background: #50B14A;
border: 1px solid #50B14A;
box-sizing: border-box;
border-radius: 8px 8px 0px 0px;
/* height: 36px; */
}

.tracking-code-paragraph-p{
font-family: "Roboto", sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 21px;
color: #FFFFFF;
/* height: 21px; */
margin-top: 5px;
margin-left: 5px;
}

.TrackingMap-svg{
    width: 100%;
}

.tracking-section-three{
    display: flex;
    gap: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    /* justify-content: space-between; */
    /* width: 900px; */
}

.Agent-image-{
    width: 123px;
height: 101px;
}


.section-after-assigned-agent{
    display: flex;
    align-items: center;
    gap: 30px;
    background: #FFFFFF;
    border: 1px solid #666666;
    border-radius: 4px;
    padding: 10px;
    justify-content: center;
    align-self: center;
    flex: 3;
    width: 100%;
}


.Delivery-information-section{
flex: 3;
width: 100%;
}



.delivery-information-heading{
    background: #50B14A;
border: 1px solid #50B14A;
box-sizing: border-box;
border-radius: 8px 8px 0px 0px;

}


.inner{
    display: flex;
}

.delivery-info-inner-content{
    display: flex;
    justify-content: space-between;
    padding: 5px;
}


.empty-section-to-keep-space{
    flex: 3;
    width: 100%;
}

.itemdesc{
    margin-right: 10px;
}

.cnt-body{
    background: #fff;
    border: 0.5px solid #e0e0e0;
    box-sizing: border-box;
}

.agent-title-header{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
font-size: 14px;
line-height: 14px;
/* identical to box height */
margin-bottom: 10px;

color: #2E2E2E;
}

.agent-img{
    width: 123px;
height: 101px;
}

.agent-name, .agent-id{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
font-size: 14px;
line-height: 16px;

color: #2E2E2E;
}


.desc-name-title{
    font-family: "Roboto", sans-serif;
    font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 14px;
/* identical to box height */


color: #2E2E2E;

}


.main-desc{
    font-family: "Roboto", sans-serif;
    font-style: normal; 
    font-weight: normal;
font-size: 14px;
line-height: 16px;
text-align: right;

color: #2E2E2E;
}


@media screen and (max-width: 900px) {
    
    .tracking-section-three{
        flex-direction: column;
    }
.section-after-assigned-agent{
    flex-direction: column;
}

}