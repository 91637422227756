.main-parent{
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #50B14A; */
box-sizing: border-box;
padding: 5px;
margin-bottom: 30px;
}

.table-header- > table > thead {
    /* background: #50b14a!important; */
    border-radius: 3px 3px 0px 0px;
    color: #ffffff;
    text-transform: none;
    border-bottom: 1px solid #B0AFB0;
}

.table__ > tr{
    border: 1px solid #50B14A;
}

tbody > tr{
    border-bottom: 0.5px solid #E7E7E7;
    text-align: left !important;
}
.redBg{
    color: red !important;
}