.loginContainer{
  display: flex;
  align-items: center;
  padding: 50px;
  height: 100vh;
  flex-direction: column;
  position: relative;
  color: var(--textColor);
  /* background-color: aqua; */
  justify-content: center;
}
.loginFormContainer{
  width: 100%;
  max-width: 350px;
  /* background-color: aqua; */
  /* margin:  auto; */
}
.loginHead{
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  margin-bottom: 20px;
}
.loginHead h3{
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}
.loginLogo img{
  width: 150px;
  cursor: pointer;
}
.logintopLeft{
  position: absolute;
  top: 0;
  left: 0;
}
.logintopRight{
  position: absolute;
  top: 0;
  right: 0;
}
.loginbottomLeft{
  position: absolute;
  bottom: 0;
  left: 0;
}
.loginbottomLeft img{
  transform: rotate(180deg);
}
.loginbottomRight{
  position: absolute;
  bottom: 0;
  right: 0;
}


.login {
  width: 100%;
  background-color: var(--lighter);
}

.loginSection {
  width: 100%;
  height: 78.5vh;
  background-color: var(--lighter);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu {
  width: 100%;
  height: 100px;
}
.lgHead {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: var(--main);
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  padding: 0 10px;
}
.loginContent {
  width: 100%;
  height: 70%;
  display: flex;
}

.loginForm {
  flex: 1;
  justify-content: center;
  display: flex;
  border-radius: 10px;
}
.selected {
  margin-bottom: 20px;
  width: 80%;
}
.signupForm {
  padding-top: 30px;
  flex: 1;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
}
.formCont {
  padding: 20px 50px 20px 50px;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.formCont2 {
  padding: 30px 50px 20px 50px;
  height: 160px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.formCont3 {
  padding: 50px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mainLgForm {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0px 0px 10px 10px;
  padding-top: 0px;
  max-width: 500px;
}
.xmainLgForm{
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0px 0px 10px 10px;
  padding-top: 0px;
  max-width: 500px;

}
.fff {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--lighter);
  border-radius: 10px 10px 10px 10px;
  padding-top: 0px;
  box-shadow: 2px 2px 10px 1px #e3e6e4;
}
.XXXfff {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--lighter);
  /* height: 370px; */
  height: 330px;
  border-radius: 10px 10px 10px 10px;
  padding-top: 0px;
  box-shadow: 2px 2px 10px 1px #e3e6e4;
  margin-top: 20px;

  /* box-shadow: 5px 5px 8px #eaeaea; */
}
.headingg {
  background-color: var(--main);
  padding-left: 30px;
  height: 50px;
  line-height: 50px;
  color: var(--lighter);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
  /*position: relative;*/

  /* margin-bottom: 15px; */
}
.acctTyp {
  font-style: normal;
  font-weight: 600;
  /*font-size: 16px;*/
  color: #ffffff;
  letter-spacing: 0.5px;
}
.copOrInd {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;

  color: #6a6e6c;
  width: 100px;
  margin: 10px 20px 10px 10px;
  border: 0px;
  border-radius: 5px;
  outline: 0px;
  appearance: none;
  background-image: url("../../assets/imageArrow.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-x: 78px;
  background-position-y: center;
}
/*.arricon {
    position: absolute;
    top: 2.5px;
    left: 90%;
    color: gray;
  }*/

.loginImage {
  flex: 1;
}
.formInput {
  width: 100%;
  margin-bottom: 30px;
}
.lgcheckBox {
  /* width: 15px;
  height: 15px; */
  position: absolute;
  top: 2.5px;
  left: 0;
  outline: 0px;
  filter: hue-rotate(600deg);
}
.subtitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.lgCheckBoxCon {
  position: relative;
  /*background-color: blanchedalmond;*/
  padding-right: 10%;
  margin-right: 10%;
  /*color: gray*/
}

.headline {
  width: 100%;
  text-align: center;
}
.loginImage img {
  width: 600px;
  height: 400px;
  border-radius: 10px;
  margin: 10px 0;
  /* padding-top: 20px; */
}
.btnlabe {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*gap: 10px;*/
  font-weight: 600;
  line-height: 10px;
}
.lgBtnn {
  /*background-color: blue;*/
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.LgReg {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  margin-bottom: 25px;
}
button.supbtn {
  padding: 10px;
  line-height: 15px;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.4px;
}
button.supbtn:hover {
  box-shadow: 1px 1px 3px 1px #bbbdbb;
}
.pTag {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: gray;
}
.handLogo {
  width: 300px;
}
.cancelAndRegist {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}
.resetBt {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.menulisss {
  padding: 0 100px;
}
.forgetpassContent{
  display: flex;
  /* background-color: aliceblue; */
  /* justify-content: space-between; */
  width: 80%;
  /* padding: 20px; */
  max-width: 1500px;
 

}
.forgetpassForm{
  flex: 2;
}
.forgetpassImage{
  flex: 1;
  
}
.forgetpassSection{
 /* background-color: #6a6e6c; */
 height: calc(100vh - 100px);
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;

 


}

@media screen and (max-width: 900px) {
  .loginContainer{
    padding: 50px 30px;
  }
  .loginbottomLeft img{
    width: 150px;
  }
  .logintopRight img{
    width: 150px;
  }
  .menulisss {
    padding: 0;
  }
  .loginContent {
    flex-direction: column;
  }
  .menulisss {
    padding: 0;
  }
  .loginImage {
    display: none;
  }
  .up {
    flex: 4;
    /* background-color: tomato; */
    display: flex;
    flex-direction: column-reverse;
  }
  .upLeft {
    flex: 1;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  .upRight {
    display: none;
  }
  .loginForm {
    width: 100%;
    /*background-color: red;*/
    padding-bottom: 40px;
    padding-top: 0px;
    margin-top: 0px;
  }
  .signupForm {
    padding-left: 0px;
    align-items: center;
  }
  .headingg {
    font-size: 20px;
    font-weight: 600;
  }
  .formCont {
    padding: 20px 20px 20px 20px;
  }
  .formCont2 {
    padding: 20px 20px 20px 20px;
    height: 135px;
  }
  .copOrInd {
    background-image: none;
  }
  button.supbtn {
    width: 35% !important;
  }
  .mainLgForm {
    width: 70%;
  }
  .handLogo {
    width: 250px;
  }
  .forgetpassImage{
    display: none;
  }
 
  .forgetpassForm{
   flex: 1;
  }
  .forgetpassContent{
    flex-direction: column;
  }
  .xmainLgForm{
    width: 100%;
  }
  
}
