.senderReg{
    width: 100%;
    /* height: calc(100vh - 60px); */
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
  justify-content: center;
  padding: 0 100px;
}
.senderCont{
    width: 100%;
    max-width: 1440px;
    padding: 70px 50px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.sendLeft{
  padding: 40px 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}
.sendRight{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.sendRightt{
    width: 50%;
    padding: 40px 0;
    display: flex;
    justify-content: center;
}
.sRight{
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
}
.signupImage{
    margin-top: 150px;
    width: 100%;
    max-width: 500px;
}

.updateCon{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
}
.formContain{
    width: 450px;
    padding: 20px;

}
.formContain_request{
    width: 450px;
    padding: 5px;

}


.formContain_request-{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
}

.form-style{
    width: 450px;
    padding: 20px;
}

.check_agree {
    display: block;
    position: relative;
    /* padding-left: 35px; */
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* style of checkbox start here */
  
  .check_agree input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmarker {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: white;
    border: 2px solid var(--main);
    border-radius: 3px;
  }
  .check_agree:hover input ~ .checkmarker {
    background-color: white;
  }
  
  .check_agree input:checked ~ .checkmarker {
    background-color: var(--main);
  }
  
  .checkmarker:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .check_agree input:checked ~ .checkmarker:after {
    display: block;
  }
  
  .check_agree .checkmarker:after {
    left: 4px;
    top: 0px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .proceeding{
    margin-top: -2px;
      width: 100%;
      text-align: center;
      padding-left: 20px;
      font-size: 12px;
  }
  .proceeding span{ 
    color: var(--main) !important;
    cursor: pointer;
}
.proceeding span:hover{ 
    text-decoration: underline;
}
  .haveAcct{
    width: 100%;
    text-align: center;
    font-size: 14px;
}
.haveAcct a{
    color: var(--main);
}
.haveAcct a:hover{
    text-decoration: underline;
}

.showimgae{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

}


.heading{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.heading__title__tag{
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px;
color: #484848;
/* width: 197px;
height: 28px; */
}

.heading__subtitle__tag{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */

text-align: center;

/* text */

color: #484848;

}

.submit__and__edit{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding-left: 30px;
  padding-right: 30px;


}


.submit__and__edit > div:nth-child(1){
  flex: 1
}


.submit__and__edit > div:nth-child(2){
  width: 148px;

}


.submit-btn{
  background: #50B14A;
border-radius: 4px;
color: #ffffff;
width: 115px;
height: 39px;
border: 1px solid #50B14A;
outline: none;
cursor: pointer;
}


.edit-icon-btn{
  background-color: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer;
}


.summary__content{
  margin-top: 20px;
  margin-bottom: 20px;
  
}

.summary_category{
  display: flex;
  gap: 50px;
 padding-bottom: 8px;
}
.sum_category{
flex: 2;
}

.sum__cate__content{
  align-items: flex-start;
  width: 148px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.summary__content__main{
  margin-top: 30px;
  
}

.sum_cat_main{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
/* identical to box height */


/* wire frame */

color: #6C656B;
}

.sum_cat_main_sub{
  font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 130%;
/* identical to box height, or 16px */


/* text */

color: #484848;

}

.forPhoto----{
  /* display: flex;
  justify-content: center; */
  padding-left: '30px';

}

.playerCon{
  margin-bottom: 20px;
  width: 400px;
  height: 250px;
  
}
.react-player{
  border-radius: 20px;
}
.sender_reg_container{
  padding: 20px 0;
  min-height: 100vh;
  width: 100%;
  height: auto;
  position: relative;
}
.topLeftSender{
  position: absolute;
  top: 0;
  left: 0;
}
.topRightSender{
  position: absolute;
  top: 0;
  right: 0;
}
.bottomLeftSender{
  position: absolute;
  bottom: 0;
  left: 0;
}
.bottomLeftSender img{
  transform: rotate(180deg);
}
.bottomRightSender{
  position: absolute;
  bottom: 0;
  right: 0;
}
.centerLogoMobile{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.otpContainer{
  width: 100%;
  height: 600px; 
  align-items: center;
  color: #444343;
  display: flex;
  flex-direction: column; 
  gap: 30px; 
  padding: 20px 
}
.otpImag{
  width: 100%; 
  height: 150px;
}
.otpConfirmBtn{
  width:calc(100% - 60px);
  position:absolute; 
  bottom:30px; 
  right:30px; 
  text-align:center; 
  user-select:none;
  cursor:pointer; 
  padding:10px; 
  background-color:var(--main); 
  color:white; 
  border-radius:3px; 
  border:0
}


@media screen and (max-width : 900px) {
  .otpConfirmBtn{
    width : 100%;
    position:static; 
    text-align:center; 
    user-select:none;
    cursor:pointer; 
    /* padding:10px;  */
    background-color:var(--main); 
    color:white; 
    border-radius:3px; 
    border:0
  }
  .sender_reg_container{
    padding: 50px 0;
  }
  .otpContainer{
    width: 100%;
    height: auto; 
    align-items: center;
    color: #444343;
    display: flex;
    flex-direction: column; 
    gap: 15px; 
    padding: 20px 
  }
  .otpImag{
    width: 100%; 
    height: 45px;
  }
  
  .senderReg{
    padding: 0;
  }
  .playerCon{
    margin-bottom: 20px;
    width: 100%;
    height: 200px;
  }
     .sendLeft{
         display: none;
     }   
     .showimgae{
       display: none;
     }
     .sendRight{
         width: 100%;
     }
     .sendRightt{
      width: 100%;
      padding: 0 30px;
  }
.summary_category{
  flex-direction: column;
  justify-content: center;
  gap: 0px;
}

.summary__content__main{
  display: flex;
  flex-direction: column;
}

.forPhoto----{
  display: flex;
  justify-content: center;
  padding-left: '0px';
  padding-right: '0px';
}

.bottomLeftSender img{
  width: 150px;
}
.topRightSender img{
  width: 150px;
}


}