.sender-dash-grap {
  width: 100%;
  margin-top: 50px;
  padding: 0px 10px;
}
.analysis-div {
  background-color: var(--main);
  color: #fff;
  padding: 10px;
  border: 1px solid #50b14a;
  border-radius: 6px 6px 0px 0px;
}
.sender-graph-con {
  margin-top: 10px;
  border: 1px solid #50b14a;
  box-sizing: border-box;
  border-radius: 6px;
  height: calc(100vh - 450px);
  width: 100%;
}

.personal-image {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  /* border: solid 1px #E0FCE7; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dsktopscreen {
  display: block;
}

.mobilescreen {
  display: none;
}
.api_cards {
  display: flex;
  color: var(--textColor);
  margin-bottom: 20px;
  flex-direction: column;
  gap: 15px;
  background: #ffffff;
  width: 450px;
  padding: 20px;
  box-shadow: 0px 4px 24px rgba(30, 38, 44, 0.25);
  border-radius: 10px;
}

@media only screen and (max-width: 900px) {
  .dsktopscreen {
    display: none;
  }

  .mobilescreen {
    display: block;
  }
  .mobile-dash-body {
    height: 100%;
    width: 100%;
    padding: 10px 20px;
    padding-bottom: 80px;
  }
  .mobile-dash-bodyv2 {
    height: 100vh;
    width: 100%;
    padding: 10px 20px;
    padding-bottom: 80px;
  }
  .sender-dash-grap {
    padding: 0;
    margin-top: 20px;
  }
  .dash-cards {
    flex-direction: column;
    gap: 20px;
  }
  .sender-graph-con {
    margin-top: 3px;
  }
  .sender-dashboard-wrapper {
    padding: 0;
  }
  .api_cards {
    width: 100%;
  }
}
