/************************ update by Esther *************************/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.beforeNav {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.apiBtnv{
  display:flex;
  width:100%;
  justify-content:flex-start; 
  align-items:center;
}

.indhome-nav {
  display: none;
}

.parentcontainer-mobile {
  display: none;
}

.parentcontainer {
  height: auto;
  width: 100%;
  background: white;
}

.home {
  height: auto;
}

.gridcontainer {
  height: calc(100vh * 0.85);
  display: flex;
}

.gridcontainer-mobile {
  display: none;
}

.parentcontainer-mobile {
  display: none;
}

/************************ update by Esther *************************/

/************************ update by Haleemah *************************/
.floater {
  position: fixed;
  right: 10px;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 35px;
  color: white;
  font-size: 25px;
  text-align: center;
  /* border-radius: 20px; */
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 22px;
  border-top-right-radius: 5px;
  background-color: #666666;
  cursor: pointer;
  box-shadow: 0 8px 6px -6px black;
  z-index: 300;
}
.floater:hover {
  background-color: #50b14a;
  transform: scale(1.05);
}
.floatLeftDiv {
  position: fixed;
  z-index: 1000;
  bottom: 50px;
  right: 13px;
  background-color: white;
  border-radius: 10px;
  padding-top: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.par_div {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 400px;
  padding-top: 20px;
  border-radius: 20px;
  color: var(--textColor);
}

.myDiv {
  border-radius: 20px;
  width: 400px;
  height: 500px;

  display: flex;
  flex-direction: column;
}

.test-class {
  width: 80%;
}
.modals {
  border-radius: 20px;

  width: 400px;
  height: 500px;
  /* background: orange; */
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.top-court {
  background: #50b14a;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: absolute;
  top: 0;

  padding: 25px 35px 35px 35px;
}
.top-content {
  background: #50b14a;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: absolute;
  top: 0;
  display: flex;
}

.lefttext {
  text-align: left;
  padding: 25px 35px 35px 35px;
  font-size: 20px;
  color: #f2f2f2;
  font-weight: bolder;
  user-select: none;
  flex: 1;
}

.back-my-icon {
  flex: 1;
  text-align: left;
  padding: 10px -10px;
  position: absolute;
  top: 15px;
  left: 20px;
  color: white;
  cursor: pointer;
}

.back-my-icon button {
  width: 120%;
  border-radius: 5px;
}

.close-icon {
  flex: 1;
  text-align: right;
  padding: 10px 10px;
  position: absolute;
  top: 0;
  right: 10px;
  color: white;
  cursor: pointer;
}

.close-icon:hover {
  color: whitesmoke;
}

.modals .bottom-content {
  /* background: #F5F7F9; */
  width: 100%;
  padding: 40px 20px;
  height: 76%;
  /* overflow: scroll; */
}

.modals .bellsha {
  /* background: #F5F7F9; */
  width: 100%;
  padding: 40px 20px;
  height: 100%;
  overflow: scroll;
}

.searchess {
  height: 40px;
  /* width: 100%; */
  width: calc(100% - 40px);
  position: absolute;
  top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.searchess input {
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 5px;
  border: none;
  padding: 0 15px;
}

.bottom-content .textss {
  background: whitesmoke;
  position: relative;
  top: -40px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  height: 70px;
  padding: 10px 0 5px 10px;
  border: 1px solid #fbfcfc;
  font-weight: 600;
}
.taxisbells {
  position: relative;
  top: -40px;
  background: 10px;

  /* overflow: scroll; */
  padding-top: 20px;
  border-radius: 15px;
  background: whitesmoke;
  margin-bottom: 30px;
  border: 1px solid whitesmoke;
}
.taxisbells h4 {
  padding: 10px 0 10px 20px;
  position: relative;
  font-weight: 600;
  padding-top: 5px;
  text-align: left;
  font-size: 15px;
}
.taxisbellss {
  position: relative;
  top: -40px;
  background: 10px;
  padding-top: 20px;
  height: 460px;
  border-radius: 15px;
  background: whitesmoke;
  margin-bottom: 30px;
  border: 1px solid whitesmoke;
}
.taxisbellss h4 {
  padding: 10px 0 10px 20px;
  position: relative;
  font-weight: 600;
  padding-top: 5px;
  text-align: left;
  font-size: 15px;
}
.uniqueQ {
  padding: 10px 0 10px 20px;
  margin-bottom: 20px;
}
.uniqueQ p {
  margin-bottom: 25px;
  font-size: 15px;
  line-height: 25px;
}
.modals .belis-content {
  width: 100%;
  padding: 40px 20px 10px 20px;
}

.bottom-content .sup {
  position: relative;
  top: -50px;
  background: 10px;
  border: 1px solid #fbfcfc;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
  padding: 20px;
  height: 300px;
  margin-bottom: 30px;
  border: 1px solid whitesmoke;
}

.innerSup {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sup h6 {
  font-size: 12px;

  position: relative;
  font-weight: 200;
  padding-top: 10px;
}

.arto {
  display: block;
  background-color: whitesmoke;
  /* height:  50px; */
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 10px;
  line-height: 20px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.arto p {
  font-size: 10px;
  font-weight: 400;
}
.arto:hover {
  color: #50b14a;
}

.clickLike {
  background-color: whitesmoke;
  height: 50px;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 5px;
  /* line-height: 20px; */
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.btnDiv {
  padding: 20px;
}
.clickLike:hover {
  border: 1px solid #a8a8a8;
}

.clickLike h4 {
  position: relative;
  font-weight: 600;
  padding-top: 5px;
  text-align: left;
  position: relative;
  font-weight: 600;
  padding-top: 5px;
  text-align: left;
  font-size: 15px;
}
.btnin {
  position: relative;
  /* right: 15px; */
  align-items: right;
}
.buttsup {
  padding: 20px;
}
.button1 {
  border: none;
  width: 100%;
  border-radius: 5px;
  /* padding: 10px 25px; */
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 13px;
  /* margin: 4px 2px; */
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #c4c4c4;
  text-transform: none;
}
.button2 {
  border: 1px solid;
  border-radius: 5px;
  width: 100%;
  /* padding: 10px 25px; */
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 13px;
  /* margin: 4px 2px; */
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #c4c4c4;
}

/* .button{
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
} */
.countryNG {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  outline: none;
  border: 1px solid var(--main);
}
.forZindex{
  z-index: -1;
}
/************************ update by Haleemah *************************/

/************************ update by Esther *************************/

/************************ update by Esther *************************/

/* ........MOBILE RESPONSIVENESS...... */
@media screen and (max-width: 900px) {
  /************************ update by Esther *************************/
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .forZindex{
    z-index: 1;
  }
  .apiBtnv{
    justify-content:center; 
  }

  .beforeNav {
    height: auto;
  }

  .indhome-nav {
    display: flex;
    justify-content: space-between;
  }

  /************************ update by Esther *************************/

  /************************ update by Haleemah *************************/

  .floater {
    /* display: none; */
    right: 30px;
    bottom: 10px;

    width: 80px;
    height: 35px;
  }
  .par_div {
    width: 100%;
color: var(--textColor);
    padding: 0px;
  }
  .modals {
    width: 100%;
  }
  .modals .bottom-content {
    width: 100%;
    margin: 10px 0;
  }

  /* .par_div {
  width: 100%;

  
} */
  .modals {
    width: 100%;
  }
  .top-content {
    width: 100%;
  }
  .floatLeftDiv {
    position: fixed;
    height: 100%;
    top: 0;
    right: 13px;
    overflow: scroll;
  }

  /* .modal .bottom-content {
      width: 100%;
      padding: 0px;} */
}
/************************ update by Haleemah *************************/
