.select_mode{
    width: 100%;
    outline: none;
    border: 1px solid #E7E7E7;
border-radius: 4px;
padding: 10px;
height: 45px;
margin-bottom: 40px;
}

.pay_logo{
    width: 80px;
}
.main_sender_email{
    font-size: 12px;
    margin: 0;
    color: #7E787D;
}
.amouttopay{
    font-size: 16px;
    color: #7E787D;
    text-align: right;
}
.amouttopay span{
    color: var(--main);
}
.bank__details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    background: #F4F4F4;
    border-radius: 5px;
    padding: 20px 0
}
.bank__name{
    font-size: 24px;
    color: #1D1C1D
}
.bank__acct{
    font-size: 32px;
    color: #1D1C1D;
    display: flex;
    gap: 2px;
}
.text_informate{
    display: flex;
    justify-content: center;
    align-items: center
}
.line_high{
    line-height: 26px;
    color: #0C1B33;
}
.transfer_amt{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}
.radio_cont{
    width: 100%;
    max-width: 350px;
    padding: 5px 30px;
}
.radio_lbl{
    margin-bottom: 10px;
    margin-top: 5px;
}
.to_ceter{
text-align: center;
padding: 5px 20px;
color: #0C1B33;
    font-size: 13px;
    font-weight: 600;

}
.wallet_balncenc{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.wallet_balnc{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 5px 20px;
    border-radius: 10px;
    background-color: #EBEBEB;
    color: #0C1B33;
    font-size: 12px;
    font-weight: 500;
}

.insuffiv{
    margin: 30px 15px;
    text-align: center;
}

@media screen and (max-width : 700px) {
    .pay_logo{
        width: 60px;
    } 
    .main_sender_email{
        font-size: 10px;
        margin: 0;
    }   
}