 
 .dropdown {position: relative;}
.dropdowncontent {
  display: none;    
  /* min-width: 100px;    */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);   
  z-index: 700;     
  position: absolute;
    top: 35px;
    background-color: white;
    border-radius: 3px;
    padding: 0 16px;
}

.dropdowncontent a{     
    color: black;  
    padding: 9px 0;   
    text-decoration: none;    
    display: flex;
    align-items: center;
    font-size: 15px;
   }    

  .dropdowncontent a:hover{
    color: #50b14a;
  }    
                  
  .dropdown:hover .dropdowncontent{ 
    display: block;
  }  

  .dividar{
    height: 1px;
    background-color: gainsboro;
    width: 100%;
  }

  @media screen and (max-width:900px) {
      .dropdowncontent{
          width: 180px;
          padding: 10px
        
      }
  }