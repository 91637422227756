.WhyDillivry{
    width: 100%;
    background: linear-gradient(124.4deg, #FFFFFF 10.8%, #FFFFFF 87.34%);
}
.WhyDillivryCont{
    display: flex;
    width: 100%;
    padding: 0 150px;
    padding-bottom: 60px;
}
@media screen and (max-width : 900px) {
    .WhyDillivryCont{
        padding: 20px;
    }
}
