@media (min-width: 901px) {


.button-back-to-search{
    /* padding:  0 69px 0 69px !important;
    margin: 20px 0;  */
    margin: 0 auto;
    width: 80%;
    margin-top: 20px;
    margin-bottom: 10px;
    /* padding:  0 69px 0 69px !important; */
}

.back-to-search{
    width: 93px;
height: 28px;
background: #50B14A;
border-radius: 2px;
border: #50B14A;
padding-bottom: 3px;
}

.back-tosearch-content{
    font-family: "Roboto", sans-serif;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 12px;
/* identical to box height */
width: 70px;
height: 12px;

color: #FFFFFF;

}


.fulfilername-body > p{

    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 21px;
/* identical to box height */

margin-top: 3px;
color: #FFFFFF;
padding: 10px;
}

.htnlmk{
    /* padding: 10px 45px ; */
    height: 48px;
    background: #50B14A !important;
border-radius: 8px 8px 0px 0px;
/* margin: 0 69px; */
margin: 0 auto;
width: 80%;

}

.fulfiler-main{
    /* background-color: aqua; */
    
    border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 0px 0px 8px 8px;
/* margin: 0 69px; */
margin: 0 auto;
width: 80%;
}

.image-provider > img{
    width: 116px;
height: 116px;
}

.fulfiler-inner-content{
   /* padding: 30px; */
   display: flex;
}

.checkbox-under-image{
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

}

.checkbox-under-image > div{
    margin-bottom: 5px;
}

.image-feedback-details{
    margin-right: 30px;
    /* margin-right: 10px; */
}

.paragraph-feedback{
    font-family: "Roboto", sans-serif;
    font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 16px;

color: #2E2E2E;
/*
height: 16px; */
}

/* .feedback-percentage > p{
    margin-bottom: 6px;
   width: 137%;
}

.span-content{
   flex-basis: 100;
   flex: 2  ;
} */

.fxflex{
    display: flex;
    flex-direction: column;
}

.fxflex > .iner-flex >div > p{
    width: 137px;
    margin-bottom: 6px;
}

.iner-flex{
    display: flex;
}

.top-flex{
    display: flex;
    justify-content: space-between;
    /* padding: 0px 15px;
    flex-direction: column; */
    padding: 30px;
    
}

.feedback-table{
    /* padding: 30px; */
}

.feedbacks-table> tr > th{
    background: #EAEAEA;
    border-radius: 4px 4px 0px 0px;
}


.feedbacktable-div > .feedbacks-table > tr:nth-child(3)  {
    background: #FFFFFF;
border: 1px solid #EAEAEA;
box-sizing: border-box;
border-radius: 0px 0px 4px 4px;
color: #C54B4B;
font-family: "Roboto", sans-serif;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 16px;
}



.feedbacktable-div > .feedbacks-table > tr:nth-child(2){
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 0px 0px 4px 4px;
    color: #50B14A;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.bottom-search-viewfulfiler{
    margin: 20px 0px;
    /* padding: 0px 50px !important; */
    padding: 0px 30px;
}

/* .flexnavbar{
    display: none !important;
} */


.a-fulfuler{
  /* width: 1440px; */
height: 46px;
/* left: 5px;
top: 1408px; */

background: #EAEAEA;
display: flex;
align-items: center;
justify-content: space-evenly;
/* 

align-self: center;
 */

 /* padding-left: 350px; */
 /* padding-right: 110px; */
 margin-top: 50px;
margin: 0 auto;
width: 80%;
 
   
  }
  
  
  .a2{
    font-family: "Roboto", sans-serif;
    font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  
  color: #000000;
  
  height: 28px;
  /* margin-right: 400px; */
  /* margin-left: 230px; */
  
  }
  
  
  
  
  .a3 > button {
    width: 89px;
  height: 31px;
  
  
  background: #50B14A;
  border-radius: 4px;
  border: #50b14a;
  cursor: pointer;
 margin-right: 28px;
  }


  .a2 > h2{
      /* margin-right: 90px; */
  } 
  
  
  .a3 > button > span{
    font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  
  
  color: #FFFFFF;
  
  }

  .feedback-logo{
      font-family: "Roboto", sans-serif;
      font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
/* identical to box height */

margin-left: 5px;
color: #2E2E2E;

  }

  


     .table-fulfiler > tr > td:nth-child(3){
        font-family: "Roboto", sans-serif;
        font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
/* width: 134px; */
height: 16px;
color: #50B14A;
        
     }

     .table-fulfiler > tr > td:nth-child(2){


        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        
        color: #2E2E2E;
        


     }

     .table-fulfiler > tr > td:nth-child(4){

        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;        
        color: #2E2E2E;
     }

     .table-fulfiler > tr > td:nth-child(5){
font-family: "Roboto", sans-serif;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
/* identical to box height */


color: #2E2E2E;



     }

.span-content{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    
    color: #2E2E2E;
}


.table-tr-th > thead > tr > th{
    /* padding: 10px 0 15px 55px !important; */
}

.table-fulfiler > thead > tr > th{
    padding: 10px 0px 15px 25px !important;
}

.table-tr-th > tr > td {
    padding: 0px;
}

.table-fulfiler > tr > td{
    padding: 10px 0px 0px 25px !important;
}

}