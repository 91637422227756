
.titleText{
    display: none;
}
@media screen and (max-width: 900px) {
    .titleText{
        display:flex;
        flex-direction:column;
        gap:10px;
        color:"#444343"
    }
}