.DownloadHero{
    width: 100%;
}
.DownloadHeroCont{
    display: flex;
    width: 100%;
    padding: 50px;
    align-items: center;
}
.leftDownload{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}
.leftDownload h1{
   font-size: 36px;
   font-weight: 500;
}
.leftDownload h6{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
 }

.downloadImg{
    display: flex;
    gap: 20px;
}
.downloadImg img{
    width: 170px;
    cursor: pointer;
}

.downloadImg img:hover{
    transform: scale(1.01);
}

.rightDownload{
    display: flex;
    width: 50%;
    justify-content: center;
}
.rightDownload img{
    max-height: 350px;
}
@media screen and (max-width: 900px) {
    .DownloadHeroCont{
        padding: 50px 20px;
        flex-direction: column;
    }
    .leftDownload{
        width: 100%;
        flex-direction: column;
        gap: 20px;
    }
    .leftDownload h1{
        font-size: 24px;
     }
     .leftDownload h6{
         font-size: 18px;
      }
     .rightDownload{
        display: none;
     }
     .downloadImg{
        gap: 10px;
     }
     .downloadImg img{
        width: 100%;
        max-width: 150px;
     }
}