.wallet_home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}


.wallet-history {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.wallet_ballance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(88.15deg, rgba(50, 160, 113, 0.6) -13.41%, rgba(21, 207, 116, 0.6) 67.33%, rgba(45, 167, 113, 0.6) 114.58%);
    border-radius: 8px;
    width: 726px;
    height: 118px;
    padding: 30px;
}


.wallet_balance_and_amount {
    display: flex;
    flex-direction: column;
}

.waleet_title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    /* width: 105px; */
    height: 22px;
    margin-bottom: 10px;
}


.wallet_amt {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 37px;
    line-height: 32px;
    display: flex;
    align-items: flex-end;
    color: #FFFFFF;

}

.fwsettings {
    display: flex;
    justify-content: center;
}


.fund-withdraw-settings {
    display: flex;
    padding: 20px;
    padding-bottom: 0;
    width: 726px;
    justify-content: space-between;
}

.addfund>div>img,
.withdraw>div>img,
.settings>div>img {
    background: #E0FCE7;
    border-radius: 8px;
    padding: 10px;
    /* width: 56px;
height: 48px; */
}


.addfund>div>span,
.withdraw>div>span,
.settings>div>span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    /*     
    width: 76px;
height: 22px; */
    /* text */

    color: #484848;

}

.addfund {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.wallet-history>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 726px;

    padding-left: 30px;
    margin-top: 0px !important;
}

.wallet-history>div h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */


    /* text */

    color: #484848;
}


.credits,
.debits {

    display: flex;
    justify-content: center;
    border: 1px solid #E7E7E7;
    border-radius: 8px 0px 0px 8px;
    width: 242px;
    height: 60px;
    padding: 19px 22px;
    cursor: pointer;
    user-select: none;

}


.credits,
.debits {
    /* border-radius: 32px 0px 0px 32px !important; */
}

.alldiv>div:nth-child(3) {
    border-radius: 0px 32px 32px 0px !important;
}


.credits {
    border-radius: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
}

.debits {
    border-radius: 0px 8px 8px 0px !important;

}

.all {
    display: flex;
    justify-content: center;
    /* faded non active */
    cursor: pointer;
    user-select: none;
    border: 1px solid #E7E7E7;
    border-radius: 32px 0px 0px 32px;

    width: 242px;
    height: 60px;
    padding: 19px 22px;
}

.alldiv {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}



.transaction-history {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
}


.transaction-history>div {
    /* display: flex;
    justify-content: space-between; */
    align-items: center;
    width: 726px;
    margin-top: 0px !important;
    margin-bottom: 20px;
    padding: 0px 100px 0px 60px;
}


.transtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */


    color: #001533;

}

.transdate {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */


    /* inactive */

    color: #B0AFB0;

}

.transamt {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Dillivry-Green */



    color: #50B14A;
}

.transamtno {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Dillivry-Green */

   
    margin-bottom: 10px;
    margin-right: 10px;

    color: red;
}


.withdrawtag {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */


    /* text */

    color: #484848;
}


.withdawspan {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    /* hyperlink color */

    color: #004DC1 !important;
}


.withdrwalbody {
    display: flex;
    align-items: center;
}

.cancelandproceed {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    display: flex;
    gap: 30px;
}

.cancel {
    background: #FFFFFF;
    border: 1px solid #C54B4B;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* line-height: 19px; */
    padding: 4px 12px;
    color: #C54B4B;

}

.proceed {
    padding: 4px 12px;
    border-radius: 4px;
    background: #B0AFB0;
    border: 1px solid #B0AFB0;
    color: #FFFFFF;
}

.proceedplus {
    background: #50B14A;
    padding: 4px 12px;
    border-radius: 4px;
    color: white;
    border: 1px solid var(--main);
}

.proceed span {

    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;



    color: #FFFFFF;
}


.form-div input {
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 100%;
    height: 40px;
    text-indent: 10px;
    margin-bottom: 5px;
}

.form-div input::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    /* wire frame */

    color: #6C656B;
}


.withdrawinner {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    /* or 179% */

    display: flex;
    align-items: center;

    color: #484848;

}


.yesandnobtn {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 30px;
}

.nobtn {
    width: 121px;
    height: 47px;
    background: #FFFFFF;
    /* red */

    border: 1px solid #C54B4B;
    border-radius: 4px;
    color: #C54B4B;
}

.yesbtn {
    width: 121px;
    height: 47px;
    background: #50B14A;
    border-radius: 4px;
    color: #FFFFFF;
    border: 1px solid #50B14A;
}


.withdraw {
    cursor: pointer;
}

.transaction-history- {
    margin-top: 5px;
    display: flex;
    justify-content: center;

}

.transdetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 726px;
    /* margin-bottom: 20px; */
    padding: 0px 100px 0px 60px;
    /* margin-bottom: 10px; */
}


/* .credit{
    padding-right: 230px;
}

.credittransinner{
padding-right: 330px;
} */


.user_id {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */


    color: #001533;
}

.transhistory-credit {
    margin-top: 0px;
}


@media screen and (max-width: 600px) {
    .wallet_home{
        margin-top: 20px;
    }
    .wallet_cont{
        padding: 20px;
    }
    .wallet_ballance {
        width: 100%;
        height: auto;
        gap: 40px;
    }

    .transdetails {
        /* width: auto; */
        display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 50px;
}

.transamtno{
    margin-right: 0px;
}
    

    .transaction-history>div, .transaction-history->div {
        width: 100%;
        /* flex-wrap: wrap; */
        /* display: block; */
        padding: 0px 20px;
        /* margin-bottom: 2px; */
    }



    .transaction-history>div{

    }



    .fund-withdraw-settings {
    
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .wallet-history>div {
        width: 100%;
    }

    .credits,
    .debits,
    .all {
        width: 100%;

    } 


    .transaction-history{
        display: flex;
        flex-direction: column;
        align-items: inherit;
    }

    /* .wallet-history>div {
        width: auto;
       
    }

    

    .credits,
    .debits,
    .all {
        width: auto;

    }

    .fund-withdraw-settings {
    
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .wallet-history>div {
        width: auto;
    }



    .wallet_ballance {
        width: auto;
        height: auto;
    }

    .fund-withdraw-settings {
    
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    } */


}


.select-search__value > input{
    width: 100%;
    height: 40px;
    text-indent: 10px;
}



.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
    margin-bottom: 20px;
  }
  
  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  
  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }
  
  .select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  }
  
  .select-search__options {
    list-style: none;
    margin: 0;
    padding-inline-start: 0;
  }
  
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }
  
  .select-search__option,
  .select-search__not-found {
    display: block;
    /* height: 36px; */
    height: auto;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }
  
  .select-search--multiple .select-search__option {
    height: 48px;
  }
  
  .select-search__option.is-selected {
    background: var(--main);
    color: #fff;
  }
  
  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
  }
  
  .changebank{
      margin-top: 20px;
  }

  .bank_img_text_icon{
      display: flex;
      align-items: center;
      border: 1px solid #B0AFB0;
    border-radius: 5px;
  }

  .homeandtext{
      display: flex;
      align-items: center;
      gap: 10px;
  }

  .texttag{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */
    
    
    /* text */
    
    color: #484848;
  }