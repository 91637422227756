.btnNext{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    padding-bottom: 50px;
    /* background-color: violet; */
}
.btnNex{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0;
    width: 50%;
    cursor: pointer;
    font-weight: 600;
}
.btnPrev{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    width: 50%;
    cursor: pointer;
    font-weight: 600;
}
.btnNextPrev{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    font-weight: 600;
}
.shown{display: flex; flex-direction: column; gap: 20px; }
.hidden{display: none;}
.imageInput{
    width: 100%;
    height: 100px;
    padding: 15px 30px;
    border: gainsboro solid 1px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fileImage{
  display: flex; 
  justify-content: space-between;
  align-items: center; 
  gap: 20px;
}
.images{
    width: 120px;
  height: 90px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 10px;
  background-color: var(--msgBorderline);
  border: solid 0.5px var(--msgBorderline);
  
}
.images span {
    display: none;
  }
/* .images::after {
    content: '';
    width: 100%;
    height: 100%;
    transition: opacity .1s ease-in;
    border-radius: 4px;
    opacity: 0;
    position: absolute;
  }
  .images:hover::after {
    display: block;
    background-color: #000;
    opacity: .5;
  } */
  .images:hover span {
    display: block;
    color: #fff;
  }
  .images:hover .initial {
    display: none;
    color: #fff;
  }

.images img{
    width: 70px; height: 70px;
}


.fileBtn{
    display: flex;
    align-items: center;
    justify-content: center;
   padding: 10px 20px;
    /* height: 30px; */
    background-color: var(--main);
    color: white;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
}
.clickMe{
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .5;
}
.fileBtnNew{
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: 600;
}
.initial{
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: 600;
}

.yesInsurance{
    display: flex; 
    flex-direction: column; 
    gap:20px;
}
.noInsurance{
    display: none;
}

@media screen and (max-width : 900px) {
    
    .fileBtn{font-size: 10px;
        padding: 7px 15px;}
}