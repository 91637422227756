.regStages{
    width: 100%;
    height: 85px;
    padding: 20px;
    display: flex;
    user-select: none;
    align-items: center;
    gap: 20px;
    border:2px solid #969696;
    border-radius: 5px;
    margin-bottom: 20px;
}

.regStagesVerified{
    width: 100%;
    height: 85px;
    padding: 20px;
    display: flex;
    user-select: none;
    align-items: center;
    gap: 20px;
    border:2px solid var(--main);
    border-radius: 5px;
    margin-bottom: 20px;
}
.regStagesNoProvided{
    width: 100%;
    cursor: pointer;
    height: 85px;
    padding: 20px;
    display: flex;
    user-select: none;
    align-items: center;
    gap: 20px;
    border:2px solid var(--dark);
    border-radius: 5px;
    margin-bottom: 20px;
}
.stageIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.stageleft{
    display: flex;
    justify-content: space-between;
    width: 100%;

}
.stageText{
    display: flex;
    justify-content: center;
    align-items:flex-start ;
    flex-direction: column;
    gap: 5px;
}
.stageArrow{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
